import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import PeerfiveIcon from "../../../../assets/StarCoreValuesModal.svg";
import iconClose from "../../../../assets/icon_close.svg";
import TagsInputBox from "../../../../components/TagInputBox/TagInputBox";
import styles from "./CoreValuesDialog.module.scss";

const CoreValuesDialog = (props) => {
  const { tags = [], handelSubmit } = props;
  const closeDialog = () => {
    props.onClose();
  };

  return (
    <div className={styles.CoreValuesDialog__Container}>
      <div className={styles.headerContainer}>
        <img className={styles.logoIcon} src={PeerfiveIcon} alt="PeerFives" />
        <div>
          <Typography className={styles.headerTitle}>
            Configure Core Values
          </Typography>
          <Typography className={styles.headerDescription}>
            These core values can be used while rewarding your work mates.
          </Typography>
        </div>
        <div onClick={closeDialog} onKeyDown={closeDialog} role="presentation">
          <img className={styles.closeIcon} src={iconClose} alt="close" />
        </div>
      </div>

      <div className={styles.divider} />
      <div className={styles.bodyContainer}>
        <Grid container>
          <Grid item xs={12}>
            <TagsInputBox
              tagsList={tags}
              handelSubmit={handelSubmit}
              handelSkip={closeDialog}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

CoreValuesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

CoreValuesDialog.defaultProps = {};

export default CoreValuesDialog;
