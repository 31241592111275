import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./CardDetailsDialog.module.scss";
import iconClose from "../../../../assets/icon_close.svg";
import CardDetails from "../../../../components/CardDetails/CardDetails";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import creditCardLeftSideIcon from "../../../../assets/creditCardLeftSideIcon.svg";
import creditCardRightSideBG1 from "../../../../assets/creditCardRightSideBG1.svg";
import creditCardRightSideBG2 from "../../../../assets/creditCardRightSideBG2.svg";

import ImageCarousel from "../ImageCarousel/ImageCarousel";

const CardDetailsDialog = (props) => {
  const useStyles = makeStyles((theme) => ({
    noteFont: {
      display: "inline-block",
      fontSize: "14px",
      fontWeight: "400",
      color: "#7773FF",
    },
    noteBoldFont: {
      display: "inline-block",
      fontSize: "14px",
      fontWeight: "700",
      color: "#7773FF",
    },
  }));
  const classes = useStyles();
  const { setLatestCreditCardDetails } = props;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const closeDialog = () => {
    props.onClose(true);
  };
  const images = [
    {
      src: creditCardRightSideBG1,
      element: (
        <Typography className={classes.noteFont}>
          <Typography className={classes.noteBoldFont}>
            No Card Charge
          </Typography>{" "}
          until points redeemed on marketplaces
        </Typography>
      ),
    },
    {
      src: creditCardRightSideBG2,
      element: (
        <Typography className={classes.noteFont}>
          Card will be charged once the{" "}
          <Typography className={classes.noteBoldFont}>Free Trial</Typography>{" "}
          ends.
        </Typography>
      ),
    },
  ];

  return (
    <div>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContainer}>
          <div>
            <img src={creditCardLeftSideIcon} />
          </div>
          <div style={{}}>
            <Typography className={styles.headerTitle}>
              Credit Card Details
            </Typography>
            <Typography className={styles.headerSubTitle}>
              Your card details will be used for your plan subscription and
              marketplace
            </Typography>
          </div>
        </div>
        <div onClick={closeDialog} onKeyDown={closeDialog} role="presentation">
          <img className={styles.closeIcon} src={iconClose} alt="close" />
        </div>
      </div>

      <div className={styles.bodyContainer}>
        <Grid container spacing={1} xs={12}>
          <Grid item xs={5}>
            <Elements stripe={stripePromise}>
              <div className={styles.StripeFormWrapper}>
                <CardDetails
                  setLatestCreditCardDetails={setLatestCreditCardDetails}
                  handleSkip={closeDialog}
                  prefilledDetails={props.prefilledDetails}
                />
              </div>
            </Elements>
          </Grid>
          <Grid item xs style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                border: "1px solid #DFDFDF",
                height: "100%",
                width: "0px",
              }}
            ></div>
          </Grid>

          <Grid item xs={5}>
            <ImageCarousel images={images} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

CardDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

CardDetailsDialog.defaultProps = {};

export default CardDetailsDialog;
