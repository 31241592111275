import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "../pages/Login/Login";
import Dashboard from "../containers/Dashboard";
import AnalyticalDashboard from "../containers/AnalyticalDashboard";
import Profile from "../containers/Profile";
import PrivateRoute from "../helper/PrivateRoute";
import PublicRoute from "../helper/PublicRoute";
import * as storageServices from "../utils/services/storageServices";
import Header from "../components/Header";
import Onboarding from "../pages/Onboarding/Onboarding";
import OnboardingRoute from "../helper/OnboardingRoute";

import UserContext from "../utils/context/UserContext";
import { useLocation } from "react-router-dom";
import Leaderboard from "../components/LeaderBoard/Leaderboard";
import Settings from "../pages/Profile/components/Settings/Settings";
import { useMediaQuery, useTheme } from "@material-ui/core";
import CurrentSubscription from "../pages/Profile/components/CurrentSubscription/CurrentSubscription";
import SupportCard from "../pages/Profile/components/SupportCard/SupportCard";
import MobileNavSetting from "pages/Profile/components/MobileNavSetting/MobileNavSetting";

const MainRoute = () => {
  const { user } = useContext(UserContext);
  let location = useLocation();
  const search = location.search;
  const token = new URLSearchParams(search).get("token");

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  let isBoarded = user?.team?.isBoarded;
  let isAdmin = user?.members?.peerfiveAdmin;
  // user?.members?.raw_data?.is_admin ||
  // user?.members?.peerfiveAdmin ||
  // user?.members?.raw_data?.is_owner;

  if (token) {
    storageServices.remove(storageServices.TOKEN);
    storageServices.add(storageServices.TOKEN, token);
  }
  return (
    <>
      {storageServices.get(storageServices.TOKEN) &&
        (isBoarded || !isAdmin) && <Header />}

      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return storageServices.get(storageServices.TOKEN) ? (
              <Redirect to="/login" />
            ) : (
              <Redirect to="/dashboard" />
            );
          }}
        />
        <PublicRoute restricted={true} component={Login} path="/login" exact />
        <OnboardingRoute
          restricted={true}
          component={Onboarding}
          path="/onboarding"
          exact
        />

        <PrivateRoute
          component={Profile}
          // data={this.state}
          path="/settings"
          exact
        />

        <PrivateRoute component={Dashboard} path="/dashboard" exact />
        <PrivateRoute component={AnalyticalDashboard} path="/insights" exact />
      </Switch>
    </>
  );
};

export default MainRoute;
