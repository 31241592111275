import React, { useContext, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { InputBase, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { ReactComponent as AddIcon } from "../../assets/icons/addCoreValueIcon.svg";
import styles from "./TagInputBox.module.scss";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import { useToaster } from "../../helper/ToasterContext";
import UserContext from "utils/context/UserContext";

const useStyles = makeStyles({
  button: {
    textTransform: "none",
  },
});

const TagInputBox = (props) => {
  const { user } = useContext(UserContext);
  const { from, handelSubmit, handelSkip, tagsList, handleNextStep } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState(tagsList);
  const [displayInput, setDisplayInput] = useState(false);
  const [suggestionTags, setSuggestionTags] = useState([
    "Honesty",
    "Critic",
    "SmartWork",
    "Creativity",
    "Co-creation",
    "Heartitude",
    "Compassion",
  ]);
  const Toaster = useToaster();

  const classes = useStyles();

  const closeInputBox = () => {
    setDisplayInput(false);
    setInputValue("");
  };

  const addHandler = () => {
    setDisplayInput(!displayInput);
  };

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const removeSuggestionTags = (indexToRemove) => {
    setSuggestionTags([
      ...suggestionTags.filter((_, index) => index !== indexToRemove),
    ]);
    setTags([suggestionTags[indexToRemove], ...tags]);
  };

  const addTags = () => {
    const containsLetters = /[a-zA-Z]/.test(inputValue);
    const containsNumbers = /[0-9]/.test(inputValue);
    const isValid = (containsLetters && containsNumbers) || containsLetters;

    if (isValid) {
      const sortedTags = tags.join("|").toLowerCase().split("|");
      const sortedSuggestionTags = suggestionTags
        .join("|")
        .toLowerCase()
        .split("|");
      if (inputValue.trim() !== "") {
        if (inputValue.length <= 50) {
          if (sortedTags.indexOf(inputValue.toLowerCase()) === -1) {
            if (sortedSuggestionTags.includes(inputValue.toLowerCase())) {
              const removeIndex = sortedSuggestionTags.indexOf(
                inputValue.toLowerCase()
              );
              removeSuggestionTags(removeIndex);
            }
            setTags([inputValue, ...tags]);
            closeInputBox();
          } else {
            Toaster.openSnackbar("Entered Core Value already exists", "error");
            closeInputBox();
          }
        } else
          Toaster.openSnackbar(
            "Core Value should not exceed 50 characters",
            "error"
          );
      }
    } else {
      Toaster.openSnackbar(
        "Entered Core Value can't have only numbers",
        "error"
      );
      closeInputBox();
    }
  };

  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      addTags(event);
    }
  };

  const createSubscription = async (params) => {
    setIsLoading(true);
    try {
      const { data } = await apiServices.post(
        apiConstant.CREATE_SUBSCRIPTION,
        params
      );
      if (data.status) {
        console.log("subscription created");
      } else {
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Toaster.openSnackbar(error.response.data.message, "error");
    }
  };
  const updateBillingInfo = async (params) => {
    setIsLoading(true);
    try {
      const { data } = await apiServices.patch(
        apiConstant.UPDATE_BILLING_DETAILS,
        params
      );
      if (data.status) {
        Toaster.openSnackbar(data.message);
      } else {
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
      setIsLoading(false);
    } catch (error) {
      Toaster.openSnackbar(error.response.data.message, "error");
      setIsLoading(false);
    }
  };

  const customHandler = async () => {
    submit();

    if (from === "onboarding" && tags.length > 0) {
      let requestJson = {
        billing_details: {
          email: user.members.profile.email,
          name: user.members.profile.real_name,
        },
      };
      createSubscription();
      updateBillingInfo(requestJson);
    }
  };

  const submit = async () => {
    if (from === "onboarding" && tags.length < 1) {
      Toaster.openSnackbar("Please add core values", "error");
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await apiServices.patch(apiConstant.CORE_VALUES, {
        coreValues: tags,
      });
      if (data.status) {
        Toaster.openSnackbar(data.message);
        handelSubmit(tags);
      } else {
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    const inputValue = event.target.value;

    const sanitizedValue = inputValue.replace(/[^0-9a-zA-Z\s]/g, "");

    setInputValue(sanitizedValue);
  };

  return (
    <>
      {suggestionTags.length > 0 ? (
        <div className={styles.suggestionBox}>
          <Typography className={styles.suggestionText}>Suggestions</Typography>

          {suggestionTags.map((tag, index) => (
            <div
              className={styles.suggestionChip}
              onClick={() => removeSuggestionTags(index)}
            >
              {tag}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.addMargin} />
      )}
      <div className={styles.coreValueContainer}>
        <AddIcon onClick={addHandler} className={styles.addIcon} />

        {displayInput && (
          <div className={styles.addInput}>
            <InputBase
              value={inputValue}
              onKeyUp={onKeyUp}
              className={styles.inputWidth}
              onChange={handleKeyPress}
            />
            <CloseIcon
              className={styles.closeIcon}
              onClick={() => closeInputBox()}
            />
          </div>
        )}

        {tags.map((tag, index) => (
          <div className={styles.chip}>
            {tag}
            <CloseIcon
              className={styles.closeIcon}
              onClick={() => removeTags(index)}
            />
          </div>
        ))}
      </div>
      <div className={styles.budgetFooter}>
        <div>
          {from === "onboarding" ? (
            <Button
              variant="outlined"
              onClick={() => {
                handleNextStep(true);
              }}
              className={classes.button}
            >
              Prev
            </Button>
          ) : (
            ""
          )}
        </div>

        <div className={styles.footer}>
          <Button
            color="primary"
            className={styles.skipButton}
            onClick={() => handelSkip()}
          >
            {from === "onboarding" ? "" : "Discard"}
          </Button>
          <Button
            variant="contained"
            disabled={isLoading}
            className={styles.saveButton}
            onClick={() => customHandler()}
          >
            {isLoading ? (
              <div className="loader" />
            ) : from === "onboarding" ? (
              "Save & Finish"
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

TagInputBox.propTypes = {
  handelSubmit: PropTypes.func.isRequired,
  handelSkip: PropTypes.func.isRequired,
  from: PropTypes.string,
  tagsList: PropTypes.shape([]),
};

TagInputBox.defaultProps = {
  from: "Add New Item",
  tagsList: [],
};

export default TagInputBox;
