import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Popper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import Leaderboard from "../../components/LeaderBoard/Leaderboard";
import NoDataFound from "../../assets/NoDataFound.svg";
import ActivityFeedCard from "./components/ActivityFeedCard/ActivityFeedCard";
import ActivityFeedCardSkeleton from "./components/ActivityFeedCard/ActivityFeedCardSkeleton";
import { ReactComponent as FilterChecked } from "../../assets/FilterChecked.svg";
import { ReactComponent as FilterSubtract } from "../../assets/FilterSubtract.svg";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import DashboardNavigation from "components/DashboardNavigation/DashboardNavigation";
import Demo from "./components/Demo/Demo";
import UserContext from "../../utils/context/UserContext";

const icon = <FilterSubtract />;
const checkedIcon = <FilterChecked />;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: "24px 24px 24px 0px",
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  select: {
    border: "1px solid #ECECEC",
    backgroundColor: "white",
    textTransform: "capitalize",
    fontSize: "16px",
    width: "158px",
    display: "flex",
    minWidth: "170px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "136px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "123px",
      fontSize: "14px",
      minWidth: "145px",
    },
  },
  memberSelect: {
    border: "1px solid #ECECEC",
    backgroundColor: "white",
    textTransform: "capitalize",
    fontSize: "16px",
    width: "160px",
    display: "flex",
    minWidth: "170px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "136px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "123px",
      fontSize: "14px",
      minWidth: "145px",
    },
  },
  selectDate: {
    border: "1px solid #ECECEC",
    backgroundColor: "white",
    textTransform: "capitalize",
    borderRadius: "4px",
    height: "42px",
    fontSize: "16px",
    width: "124px",
    display: "flex",
    justifyContent: "space-between",
    "&:hover ": {
      background: "rgba(0, 0, 0, 0.04)",
    },
    '&[aria-selected="true"]': {
      backgroundColor: "#fffff",
    },
    '&[data-focus="true"]': {
      backgroundColor: "#ffffff",
      border: "none",
    },
    padding: "0px 4px 0px 12px",
    "& .MuiSvgIcon-root": {
      color: "#000000",
      top: "calc(50% - 10px)",
      fontSize: "larger",
      right: "4px",
    },
    "& .MuiSelect-select:hover": {
      background: "none",
    },
    "& .MuiSelect-select:focus": {
      background: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      fontSize: "14px",
    },
  },
  popper: {
    border: "1px solid #ECECEC",
    boxShadow: "0px 4px 4px 0px #0000001A",
    borderRadius: 3,
    width: 172,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    "& .MuiAutocomplete-listbox": {
      overflowX: "hidden",
    },
    [theme.breakpoints.down("sm")]: {
      width: 145,
    },
  },
  inputBase: {
    borderRadius: 4,
    border: "1px solid #000000",
    backgroundColor: "white",
    paddingLeft: "14.5px",
    //padding: 10,
    width: "100%",
    "& input": {
      padding: "12px 8px",
      fontSize: "16px",
    },
  },
  paperFilter: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
  },
  option: {
    paddingLeft: "10px",
    color: "#000000",
    fontSize: "16px",
    '&[aria-selected="true"]': {
      backgroundColor: "#FFFADF",
    },
    '&[data-focus="true"]': {
      backgroundColor: "#FFFADF",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: "103px",
    maxWidth: "103px",
    padding: "0 5px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "110px",
      padding: "0 7px",
    },
  },
  filterCheckIcon: {
    "& .MuiIconButton-root:hover": {
      background: "none",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "-5px",
      maxWidth: 30,
    },
  },
  noDataDiv: {
    width: "100%",
    height: "60vh",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  date: {
    fontSize: "12px",
    color: "#A0A0A0",
    paddingTop: "14px",
  },
  filterCount: {
    color: "#FFC50D",
    marginBottom: "0px",
    marginLeft: "-8px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "3px",
    },
  },
  paginator: {
    "& .Mui-selected": {
      color: "#FFC50D",
      background: "#FFFADF !important",
      border: "1px solid #FFC50D !important",
    },
    "& .MuiButtonBase-root": {
      background: "#fff",
      border: "1px solid #ECECEC",
    },
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "nowrap",
    },
  },
  noData: {
    fontSize: "1rem",
    lineHeight: "19px",
    marginTop: "1.875rem",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px 45px 20px",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      justifyContent: " space-between",
      width: "83%",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "7%",
      marginRight: "7%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "6%",
      marginRight: "5%",
    },

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
      marginRight: "0%",
      width: "95%",
      padding: "0px 0px 45px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column-reverse",
      padding: "0px 0px 45px 0px",
      width: "100%",
    },
  },
  footerTitle: {
    fontSize: "1rem",
    lineHeight: "19px",
    color: "#000000",
  },
  cultureInsights__Dashboard: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  dropdownsContainer__Dashboard: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-between",
      paddingRight: "2%",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "end",
    },
  },
  commanRespo__Dashboard: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  activityFeedContainer__Dashboard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "7px",
    maxWidth: "1400px",
    [theme.breakpoints.up("xl")]: {
      width: "83%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "83%",
    },
    [theme.breakpoints.down("md")]: {
      width: "85%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      width: "96%",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      width: "100%",
      "& > *:first-child": {
        marginTop: "0px",
      },
    },
  },

  navigationContainer__Dashboard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      padding: "20px 25px 20px 25px",
    },
  },
  navigations__Dashboard: {
    display: "flex",
    width: "83%",
    maxWidth: "1400px",
    [theme.breakpoints.down("md")]: {
      width: "85%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  divider__Dashboard: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  dropDownBtnCont__Dashboard: {
    maxWidth: "fit-content",

    [theme.breakpoints.down("lg")]: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  dropDownBtnContDate__Dashboard: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      margin: "0% 1%",
    },
  },
  dropDownBtnCoreVal__Dashboard: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "15px",
    },
  },
  ActivityFeedText__Dashboard: {
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0px 24px 15px",
      width: "150px",
    },
  },
  endIconActivity__Dashboard: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-2px",
      fontSize: "16px",
    },
  },
  paginationCont__Dashboard: {
    [theme.breakpoints.down("xs")]: {
      margin: "15px 0px ",
      " & nav  ul": {
        display: "flex",
        flexWrap: "wrap",
        "& li button": {
          minWidth: "28px",
          maxHeight: "28px",
          [theme.breakpoints.between(0, 350)]: {
            minWidth: "8px",
            maxHeight: "20px",
            margin: "0px 2px",
          },
        },
      },
    },
  },
  ifAdmin: {
    overflow: "scroll",
    height: "calc(100vh - 104px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  notAnAdmin: {
    overflow: "scroll",
    width: "100%",
    height: "calc(100vh - 130px)",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "3.5rem",
    },
  },
}));
const feedTypeOptions = [
  "All",
  "Today",
  "This Week",
  "This Month",
  "Last Month",
  "This Year",
];

export default () => {
  const classes = useStyles();
  const itemsPerPage = 10;
  var currentYear = moment().year();
  const { user, setUser } = useContext(UserContext);
  const isAdmin = user?.members?.peerfiveAdmin;
  const [page, setPage] = React.useState(1);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [activityFeedData, setActivityFeedData] = useState([]);
  const [feedLoading, setFeedLoading] = useState(false);
  const [feedTypeFilter, setFeedTypeFilter] = useState("All");
  const [selectedCoreValue, setSelectedCoreValue] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [memberValue, setMemberValue] = useState([]);
  const [pendingMemberValue, setPendingMemberValue] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [anchorElCV, setAnchorElCV] = useState(null);
  const [coreValueOptions, setCoreValueOptions] = useState([]);
  const [pendingCoreValue, setPendingCoreValue] = useState([]);
  const [teamBudget, setTeamBudget] = useState({});
  const selectedMember = [];

  /* helper function for getting to Date and from date from filter*/
  const getDateRange = (filter) => {
    let toDate = new Date(),
      fromDate;

    if (filter === "Today") {
      fromDate = moment().startOf("day");
    }
    if (filter === "This Month") {
      fromDate = moment().startOf("month");
    }
    if (filter === "This Week") {
      fromDate = moment().startOf("week");
    }
    if (filter === "Last Month") {
      toDate = moment().subtract(1, "months").endOf("month");
      fromDate = moment().subtract(1, "months").startOf("month");
    }
    if (filter === "This Year") {
      fromDate = moment().startOf("year");
    }

    toDate = moment(toDate).format("YYYY-MM-DD");
    fromDate = moment(fromDate).format("YYYY-MM-DD");

    return { fromDate, toDate };
  };

  const getUser = async () => {
    memberValue.map((item) => selectedMember.push(item.id));
    setFeedLoading(true);
    try {
      const requestJson = {
        skip: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
      };
      let previousMonth = getDateRange(feedTypeFilter);

      if (feedTypeFilter === "All" || feedTypeFilter === undefined) {
        const { data } = await apiServices.get(apiConstant.GET_ALL_ACTIVITY, {
          skip: requestJson.skip,
          limit: requestJson.limit,
          coreValue: selectedCoreValue,
          memberId: selectedMember,
        });
        if (data) {
          setNoOfPages(Math.ceil(data[0]?.totalCount[0]?.count / itemsPerPage));
          setActivityFeedData(data[0].transaction);
          setFeedLoading(false);
        }
        setFeedLoading(false);
      } else {
        const { data } = await apiServices.get(apiConstant.GET_ALL_ACTIVITY, {
          skip: requestJson.skip,
          limit: requestJson.limit,
          coreValue: selectedCoreValue,
          memberId: selectedMember,
          toDate: previousMonth.toDate,
          fromDate: previousMonth.fromDate,
        });
        if (data) {
          setNoOfPages(Math.ceil(data[0]?.totalCount[0]?.count / itemsPerPage));
          setActivityFeedData(data[0].transaction);
          setFeedLoading(false);
        }
        setFeedLoading(false);
      }
    } catch {
      setFeedLoading(false);
    }
  };

  /* member filter handler*/
  const handleMemberClick = (event) => {
    //setPendingMemberValue(memberValue);
    setAnchorEl(event.currentTarget);
  };

  const handleMemberClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    // setMemberValue(pendingMemberValue);

    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };
  const openMemberFilter = Boolean(anchorEl);

  /* core value filter handler*/
  const handleCoreValueClick = (event) => {
    // setPendingCoreValue(selectedCoreValue);
    setAnchorElCV(event.currentTarget);
  };

  const handleCoreValueClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }

    // setSelectedCoreValue(pendingCoreValue);

    if (anchorElCV) {
      anchorElCV.focus();
    }
    setAnchorElCV(null);
  };
  const openCoreValueFilter = Boolean(anchorElCV);

  const selectFeedTypeFilter = (event) => {
    setFeedTypeFilter(event.target.value);
  };
  const getCoreValues = async () => {
    try {
      const { data } = await apiServices.get(apiConstant.CORE_VALUES);
      if (data) {
        data.coreValues.sort((a, b) => (a.name > b.name ? 1 : -1));
        // eslint-disable-next-line
        data.coreValues.map((item) => {
          setCoreValueOptions((previousChipData) => [
            ...previousChipData,
            item.name,
          ]);
        });
      }
    } catch {
      console.log("error");
    }
  };
  const getMembersList = async () => {
    try {
      const { data } = await apiServices.get(apiConstant.MEMBERS_ALL);
      if (data) {
        data.sort((a, b) => (a.name > b.name ? 1 : -1));
        setMemberOptions(data);
      }
    } catch {
      console.log("error");
    }
  };

  const getTeamBudget = async () => {
    try {
      const { data } = await apiServices.get(apiConstant.PROFILE);
      setTeamBudget(data);
    } catch {
      console.log("budget error");
    }
  };
  useEffect(() => {
    getTeamBudget();
  }, []);
  useEffect(() => {
    getCoreValues();
  }, []);
  useEffect(() => {
    getMembersList();
  }, []);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [page]);
  useEffect(() => {
    if (page === 1) getUser();
    else setPage(1);
    // eslint-disable-next-line
  }, [memberValue, selectedCoreValue, feedTypeFilter]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div
      style={{
        background: "white",
        overflow: "hidden",
        maxWidth: "1400px",
        width: "100%",
      }}
    >
      <Grid container direction="row">
        <Grid
          item
          md={9}
          sm={9}
          xs={12}
          lg={9}
          className={`leftPanel ${classes.navigationContainer__Dashboard}`}
        >
          <Grid container className={classes.navigations__Dashboard}>
            <Grid item xs={6} sm={3} md={3} lg={4}>
              <div
                className={`${classes.paper} ${classes.ActivityFeedText__Dashboard}`}
              >
                <Typography color="inherit" noWrap>
                  <Box
                    component="span"
                    fontWeight="fontWeightBold"
                    fontSize={18}
                  >
                    Activity Feeds
                  </Box>
                </Typography>
              </div>
            </Grid>

            <Grid
              container
              item
              xs={6}
              sm={9}
              md={9}
              lg={8}
              direction="row"
              className={classes.dropdownsContainer__Dashboard}
            >
              <Grid
                item
                xs={4}
                className={`${classes.dropDownBtnCont__Dashboard} ${classes.dropDownBtnCoreVal__Dashboard}`}
              >
                <Button
                  className={`${classes.select} ${classes.dropdownBtn__Dashboard}`}
                  endIcon={
                    <ExpandMoreIcon
                      className={classes.endIconActivity__Dashboard}
                    />
                  }
                  onClick={handleCoreValueClick}
                >
                  Core Values
                  {selectedCoreValue.length > 0 ? (
                    <p className={classes.filterCount}>
                      ({selectedCoreValue.length})
                    </p>
                  ) : (
                    " "
                  )}
                </Button>
                <Popper
                  open={openCoreValueFilter}
                  anchorEl={anchorElCV}
                  placement="bottom-start"
                  className={classes.popper}
                >
                  <Autocomplete
                    open
                    onClose={handleCoreValueClose}
                    multiple
                    classes={{
                      paper: classes.paperFilter,
                      option: classes.option,
                      popperDisablePortal: classes.popperDisablePortal,
                    }}
                    value={pendingCoreValue}
                    onChange={(event, newCoreValue) => {
                      setPendingCoreValue(newCoreValue);
                      setSelectedCoreValue(newCoreValue);
                    }}
                    disableCloseOnSelect
                    disablePortal
                    renderTags={() => null}
                    noOptionsText="Corevalue not found"
                    renderOption={(option, { selected }) => (
                      <>
                        <div className={classes.filterCheckIcon}>
                          <Checkbox
                            icon={icon}
                            color="default"
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                        </div>
                        <div className={classes.text}>{option}</div>
                      </>
                    )}
                    options={coreValueOptions}
                    renderInput={(params) => (
                      <InputBase
                        startAdornment={<SearchIcon />}
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        autoFocus
                        className={classes.inputBase}
                      />
                    )}
                  />
                </Popper>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.dropDownBtnContDate__Dashboard}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={feedTypeFilter}
                  onChange={selectFeedTypeFilter}
                  label="Select"
                  className={classes.selectDate}
                  IconComponent={ExpandMoreIcon}
                  disableUnderline
                >
                  {feedTypeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={4} className={classes.dropDownBtnCont__Dashboard}>
                <Button
                  className={classes.memberSelect}
                  endIcon={
                    <ExpandMoreIcon
                      className={classes.endIconActivity__Dashboard}
                    />
                  }
                  onClick={handleMemberClick}
                >
                  All Members
                  {memberValue.length > 0 ? (
                    <p className={classes.filterCount}>
                      ({memberValue.length})
                    </p>
                  ) : (
                    " "
                  )}
                </Button>
                <Popper
                  open={openMemberFilter}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  className={classes.popper}
                >
                  <Autocomplete
                    open
                    onClose={handleMemberClose}
                    multiple
                    classes={{
                      paper: classes.paperFilter,
                      option: classes.option,
                      popperDisablePortal: classes.popperDisablePortal,
                    }}
                    value={pendingMemberValue}
                    onChange={(event, newMemberValue) => {
                      setPendingMemberValue(newMemberValue);
                      setMemberValue(newMemberValue);
                    }}
                    disableCloseOnSelect
                    disablePortal
                    renderTags={() => null}
                    noOptionsText="No Members"
                    renderOption={(option, { selected }) => (
                      <>
                        <div className={classes.filterCheckIcon}>
                          <Checkbox
                            icon={icon}
                            color="default"
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                        </div>
                        <div className={classes.text}>
                          {option.profile
                            ? option.profile.real_name
                            : option.name}
                        </div>
                      </>
                    )}
                    options={memberOptions}
                    getOptionLabel={(option) =>
                      option.profile ? option.profile.real_name : option.name
                    }
                    renderInput={(params) => (
                      <InputBase
                        startAdornment={<SearchIcon />}
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        autoFocus
                        className={classes.inputBase}
                      />
                    )}
                  />
                </Popper>
              </Grid>
            </Grid>
          </Grid>

          <Divider className={`mt-n1  ${classes.divider__Dashboard}`} />
          {isAdmin === true && <DashboardNavigation />}
          {/* {!feedLoading && activityFeedData.length < 1 ? (
            <>
              {teamBudget?.team?.teamBudget > 1 ? (
                <div className={classes.noDataDiv}>
                  <img src={NoDataFound} alt="No Data Found" />
                  <Typography className={classes.noData}>
                    No PeerFives feeds found.
                  </Typography>
                </div>
              ) : (
                <Demo
                  budget={teamBudget?.team?.teamBudget}
                  userName={teamBudget?.members?.raw_data?.real_name}
                />
              )}
            </> */}

          {!feedLoading && activityFeedData.length === 0 ? (
            <>
              {teamBudget?.members?.raw_data?.real_name && (
                <Demo
                  filterSelected={memberValue.length}
                  userName={teamBudget?.members?.raw_data?.real_name}
                />
              )}
            </>
          ) : (
            <Grid
              className={
                isAdmin === true ? classes.ifAdmin : classes.notAnAdmin
              }
            >
              <List className={classes.activityFeedContainer__Dashboard}>
                {feedLoading
                  ? Array.from({ length: itemsPerPage }, () => (
                      <ActivityFeedCardSkeleton></ActivityFeedCardSkeleton>
                    ))
                  : activityFeedData.map((activity, index) => {
                      return (
                        <ActivityFeedCard
                          key={`activity-feed-data-${index}`}
                          activity={activity}
                          index={activity}
                        ></ActivityFeedCard>
                      );
                    })}
              </List>
              <div
                className={classes.footer}
                style={
                  isAdmin === false
                    ? { padding: "0px 0px 20px 0px" }
                    : { padding: "0px 0px 45px 0px" }
                }
              >
                <Typography className={classes.footerTitle}>
                  © {currentYear} PeerFives, LLC.
                </Typography>
                <Box
                  component="span"
                  className={classes.paginationCont__Dashboard}
                >
                  {noOfPages !== 1 && (
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={noOfPages}
                      page={page}
                      onChange={handlePageChange}
                      defaultPage={1}
                      classes={{
                        ul: classes.paginator,
                        root: classes.paginationButton,
                      }}
                    />
                  )}
                </Box>
              </div>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          md={3}
          xs={0}
          lg={3}
          sm={3}
          style={{
            overflow: "scroll",
            height: "calc(100vh - 40px)",
          }}
          className={classes.commanRespo__Dashboard}
        >
          <Leaderboard />
        </Grid>
      </Grid>
    </div>
  );
};
