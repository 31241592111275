import React, { useState } from "react";
import {
  Box,
  Card,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./AnalyticalDashboard.module.scss";
import NoUserFound from "../../assets/NoUserFound.svg";
import iconClose from "../../assets/icon_close.svg";
import DialogModel from "../DialogModel/DialogModel";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import ActivityFeedCardSkeleton from "../../pages/Dashboard/components/ActivityFeedCard/ActivityFeedCardSkeleton";
import ActivityFeedCard from "../../pages/Dashboard/components/ActivityFeedCard/ActivityFeedCard";
import NoDataFound from "../../assets/NoDataFound.svg";
const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  listItem: {
    "& .MuiListItem-root": {
      padding: "7px 24px",
    },
    "& .MuiListItemAvatar-root": {
      minWidth: "36px",
    },
    "& .MuiListItemText-root": {
      color: "#a0a0a0",
      margin: "0",
    },
  },
}));

const TopEarners = (props) => {
  const { topMembers, fromDate, toDate } = props;
  const classes = useStyles();
  const [seeAll, setSeeAll] = useState(false);
  const [activityFeedData, setActivityFeedData] = useState([]);
  const [feedLoading, setFeedLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [activityDialog, setActivityDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });
  const seeAllOnClick = () => {
    setSeeAll(!seeAll);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  // const dataPoints = [];
  const openActivityModal = (memberId) => {
    getUser(memberId);
    setActivityDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };
  const closeActivityDialog = () => {
    setActivityDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };
  const getUser = async (selectedMember) => {
    setFeedLoading(true);
    try {
      const requestJson = {
        skip: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
      };

      const { data } = await apiServices.get(apiConstant.GET_ALL_ACTIVITY, {
        skip: requestJson.skip,
        limit: requestJson.limit,
        memberId: selectedMember,
        toDate: toDate,
        fromDate: fromDate,
        activityType: "earned",
      });
      if (data) {
        setNoOfPages(Math.ceil(data[0]?.totalCount[0]?.count / itemsPerPage));
        setActivityFeedData(data[0].transaction);
      }
      setFeedLoading(false);
    } catch {
      setFeedLoading(false);
    }
  };
  return (
    <Card {...props} className={styles.commanSummeryContainer}>
      <div className={styles.headerContainer}>
        <Typography className={styles.heading}>Top Earners</Typography>
        <Typography className={styles.seeAll} onClick={seeAllOnClick}>
          See All
        </Typography>
      </div>
      {topMembers.topEarners.length <= 0 ? (
        <div className={styles.noDataDiv}>
          <img src={NoUserFound} alt="No Data Found" />
          <Typography className={styles.noData}>No User Found</Typography>
        </div>
      ) : (
        <Box>
          <List disableGutters={false} className={classes.listItem}>
            {seeAll
              ? topMembers.topEarners.map((member, i) => (
                  <ListItem key={i}>
                    <ListItemAvatar>
                      <Avatar
                        alt={member?._id?.profile[0]?.display_name}
                        src={member?._id?.profile[0]?.image_32}
                        className={classes.small}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={member?._id?.profile[0]?.real_name}
                      style={{ cursor: "pointer" }}
                      onClick={(event) => {
                        setSelectedMember(member?._id?.profile[0]?.real_name);
                        openActivityModal(member?._id?.id);
                      }}
                    />
                    <Typography className={styles.points} edge="end">
                      {member?.points}
                    </Typography>
                  </ListItem>
                ))
              : topMembers.topEarners.slice(0, 3).map((member, i) => (
                  <ListItem key={i}>
                    <ListItemAvatar>
                      <Avatar
                        alt={member?._id?.profile[0]?.display_name}
                        src={member?._id?.profile[0]?.image_32}
                        className={classes.small}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={member?._id?.profile[0]?.real_name}
                      style={{ cursor: "pointer" }}
                      onClick={(event) => {
                        setSelectedMember(member?._id?.profile[0]?.real_name);
                        openActivityModal(member?._id?.id);
                      }}
                    />
                    <Typography className={styles.points} edge="end">
                      {member?.points}
                    </Typography>
                  </ListItem>
                ))}
          </List>
          <DialogModel
            {...activityDialog.props}
            title="Activity Feed"
            maxWidth="md"
            onClose={closeActivityDialog}
            disbleHeader
          >
            <div>
              <div className={styles.headerContainer}>
                <div>
                  <Typography className={styles.headerTitle}>
                    Activity Feed of <b>{selectedMember}</b>
                  </Typography>
                </div>
                <div
                  onClick={closeActivityDialog}
                  onKeyDown={closeActivityDialog}
                  role="presentation"
                >
                  <img
                    className={styles.closeIcon}
                    src={iconClose}
                    alt="close"
                  />
                </div>
              </div>
              {!feedLoading && activityFeedData.length < 1 ? (
                <div className={classes.noDataDiv}>
                  <img src={NoDataFound} alt="No Data Found" />
                  <Typography className={classes.noData}>
                    No PeerFives feeds found.
                  </Typography>
                </div>
              ) : (
                <List>
                  {feedLoading
                    ? Array.from({ length: itemsPerPage }, () => (
                        <ActivityFeedCardSkeleton></ActivityFeedCardSkeleton>
                      ))
                    : activityFeedData.map((activity, index) => {
                        return (
                          <ActivityFeedCard
                            key={`activity-feed-data-${index}`}
                            activity={activity}
                            index={activity}
                          ></ActivityFeedCard>
                        );
                      })}
                </List>
              )}
              <div className={classes.footer}>
                <Box component="span">
                  {noOfPages !== 1 && (
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={noOfPages}
                      page={page}
                      onChange={handlePageChange}
                      defaultPage={1}
                      // color="amber"
                      classes={{
                        ul: classes.paginator,
                        root: classes.paginationButton,
                      }}
                    />
                  )}
                </Box>
              </div>
            </div>
          </DialogModel>
        </Box>
      )}
    </Card>
  );
};

export default TopEarners;
