import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputBase,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "./ChannelConsent.module.scss";
import YellowBG from "../../assets/icons/YellowBG.svg";
import PeerFiveBuddy from "../../assets/icons/PeerFiveBuddy.svg";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import { useToaster } from "helper/ToasterContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "contents",
    marginRight: "32px",
    "& .MuiOutlinedInput-root:hover": {
      border: "none",
    },
  },
  selectChannel: {
    width: "100%",
    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
      borderRaduis: "4px",
      borderColor: "#d7d7d7",
    },
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRaduis: "4px solid #d7d7d7",
    position: "relative",
    border: "1px solid #d7d7d7",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#d7d7d7",
    },
  },
}))(InputBase);

const ChannelConsent = (props) => {
  const Toaster = useToaster();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [channelList, setChannelList] = useState();
  const [selectChannel, setSelectChannel] = useState({});
  const [platform, setPlatform] = useState();
  const { handleNextStep, subscriptionPlans } = props;

  const customHandler = async () => {
    if (selectChannel.channelId !== 1) {
      handleNextStep(false, selectChannel);
    } else Toaster.openSnackbar("Please select a channel.", "error");
  };

  const getChannelsList = async () => {
    setLoading(true);
    try {
      const { data } = await apiServices.get(apiConstant.CHANNEL_LIST);

      if (data.status) {
        setChannelList(data.publicChannels);
        setPlatform(data.platform);

        const clickedOption = data.publicChannels.find(
          (item) => item.name.toLowerCase() === "general"
        );
        setSelectChannel({
          channelName: clickedOption.name,
          channelId: clickedOption.id,
          // onBoardingStage: "ChannelConsent",
          // onBoardingStatus: false,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChannelsList();
  }, []);

  if (loading) {
    return (
      <div className="flash-loader">
        <img src={YellowBG} className="logoSquare spinLoader" alt="PeerFives" />
        <img
          src={PeerFiveBuddy}
          style={{ position: "absolute" }}
          alt="PeerFives"
        />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.titleContainer}>
        <Typography className={styles.CardDetailsTitle}>
          Please Select {platform === "discord" ? "Discord" : (platform === "teams" ? "Teams" : "Slack")} Channel
        </Typography>

        <Typography className={styles.teamSize}>
          Total Member: {subscriptionPlans?.members}
        </Typography>
      </div>
      <Typography className={styles.suggestionText}>Channels</Typography>

      <form className={styles.BillingForm}>
        <div className={styles.InlineLabel}>
          <label>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectChannel.channelId}
                input={<BootstrapInput />}
                onChange={(e) => {
                  const clickedOption = channelList.find(
                    (item) => item.id === e.target.value
                  );

                  setSelectChannel({
                    channelName: clickedOption.name,
                    channelId: clickedOption.id,
                    // onBoardingStage: "ChannelConsent",
                    // onBoardingStatus: false,
                  });
                }}
                className={classes.selectChannel}
              >
                {channelList &&
                  channelList.map((e, key) => {
                    return (
                      <MenuItem key={e.id} value={e.id}>
                        #{e?.name?.charAt(0).toUpperCase() + e?.name?.slice(1)}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </label>
        </div>
      </form>

      <Typography className={styles.consentText}>
        We propose choosing a shared channel where all team members are present,
        so that PeerFives Recognition messages can be seen by everyone.
      </Typography>
      {/* </Accordion> */}

      <div className={styles.footer}>
        <Button
          type="submit"
          variant="contained"
          className={styles.saveButton}
          onClick={customHandler}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ChannelConsent;
