import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Avatar } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import ReplyIcon from "@material-ui/icons/Reply";
import GiftIcon from "../../../../assets/giftbox.svg";
import PropTypes from "prop-types";
import ReplyThread from "../ReplyThread/ReplyThread";
import EmojiConvertor from "emoji-js";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { AvatarGroup } from "@material-ui/lab";
import customEmojis from "assets/Custom Emoji/customEmojis";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "69%",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    maxWidth: "1400px",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
      marginRight: "0%",
      width: "100%",
      position: "relative",
    },
  },
  cardRoot: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexWrap: "wrap",
    },
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "12px",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
  expand: {
    display: "flex",
    alignItems: "center",
    transform: "rotate(0deg)",
    marginLeft: "auto",
    cursor: "pointer",
    marginBottom: "5px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: "400",
    },
  },
  yellow: {
    color: "white",
    backgroundColor: "#FFC50D",
  },
  giftIcon: {
    marginRight: -10,
    zIndex: 1,
    width: 15,
    [theme.breakpoints.down("xs")]: {
      width: "16px",
    },
  },
  userImage: {
    display: "flex",
    position: "relative",
  },
  date: {
    // position: "absolute",
    // top: "6%",
    // right: "3%",
    fontSize: "12px",
    color: "#A0A0A0",
    // paddingTop: "14px",
    lineHeight: "14.4px",
  },
  userName__ActivityFeedCard: {
    fontSize: "16px",
    fontWeight: "900",
    lineHeight: "19px",
    letterSpacing: "0em",
    margin: "3px 8px 0px 0px",
  },
  commanRewardText__ActivityFeed: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "19px",
    letterSpacing: "0em",
  },
  activityValue__ActivityFeedCard: {
    width: "fit-content",
    height: "30px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: " 900",
    padding: "0px 5px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: " 8px",
    },
  },
  description__ActivityFeedCard: {
    fontSize: "16px",
    fontStyle: "initial",
    fontWeight: "400",
    lineHeight: "25px",
    letterSpacing: "0em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontStyle: "initial",
      fontWeight: "400",
      lineHeight: "17px",
      letterSpacing: "0em",
    },
  },
  reactionContainer__ActivityFeedCard: {
    marginTop: "16px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  allReactions: {
    display: "flex",
    maxWidth: "66%",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "fit-content",
    },
  },
  replyIconContainer__ActivityFeedCard: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      fontSize: "14px",
      margin: "8px 0px",
    },
  },
  emoji__ActivityFeedCard: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "21.6px",
    },
  },
  emojiCount__ActivityFeedCard: {
    marginLeft: "7px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "14px",
      letterSpacing: "0em",
    },
  },
  emojiCountAdditional__ActivityFeedCard: {
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "14px",
      letterSpacing: "0em",
      padding: "4px 0px",
    },
  },
  ListItem__ActivityFeedCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      margin: "15px 0px",
    },
  },
  profileCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: ".6rem",
  },
  profiles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  profileSubcont: {
    display: "flex",
    alignItems: "center",
  },
  userNameText: {
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  leftArrow: {
    margin: "0px 10px",
  },
  pointsDetailsCont: {
    display: "flex",
    margin: "0.6rem",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  avatarGroup: {
    marginRight: "10px",
    "& > .MuiAvatarGroup-avatar": {
      width: "30px",
      height: "30px",
      fontSize: "12px",
    },
  },
  replyText: {
    fontSize: "13px",
    color: "rgb(0, 124, 239)",
    whiteSpace: "nowrap",
  },
  singleReaction: {
    padding: "3px 8px",
    background: "#f3f2f4",
    margin: "0px 6px 5px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
  },
  tooltipForSource: {
    position: "absolute",
    background: "#000000cc",
    color: "#fff",
    top: "3rem",
    padding: " 5px 10px",
    borderRadius: "4px",
    zIndex: "100",
    fontSize: "10px",
    left: "0px",
    fontWeight: "900",
  },
  tooltipForDest: {
    position: "absolute",
    background: "#000000cc",
    color: "#fff",
    top: "3rem",
    padding: " 5px 10px",
    borderRadius: "4px",
    zIndex: "100",
    fontSize: "10px",
    left: "4.5rem",
    fontWeight: "900",
  },
  seeLessCont: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
  },
  seeLess: {
    color: "#a0a0a0",
    borderBottom: "1px solid #a0a0a0 ",
    height: "19px",
  },
}));

const ActivityFeedCard = ({ activity, index }) => {
  const [reactions, setReactions] = useState([]);
  const classes = useStyles();
  const emoji = new EmojiConvertor();
  // changing css mode to unified
  emoji.replace_mode = "unified";

  // adding aliases for custom emoji
  emoji.addAliases({});

  useEffect(() => {
    if (activity && activity.reactions) {
      let array = [];
      array = Object.entries(activity?.reactions).filter((entry) => {
        return entry[1].length > 0;
      });

      setReactions(array);
    }
  }, []);

  // const customEmoji = {
  //     's-s':'https://emoji.slack-edge.com/T260V2K3P/s-s/1ef2c0db672866c4.gif',
  //     's-o':'https://emoji.slack-edge.com/T260V2K3P/s-o/d751f022cc4ca697.gif',
  //     's-l':'https://emoji.slack-edge.com/T260V2K3P/s-l/4b1694893cc5e4a3.gif',
  //     's-v':'https://emoji.slack-edge.com/T260V2K3P/s-v/531d7675751a3298.gif',
  //     's-e' : 'https://emoji.slack-edge.com/T260V2K3P/s-e/8c947e3a0ffa453c.gif',
  //     's-r':'https://emoji.slack-edge.com/T260V2K3P/s-r/a61dce487f8c7dbd.gif',
  //     '100+':'https://emoji.slack-edge.com/T260V2K3P/100%252B/2718380196f8c339.gif',
  //     'party-parrot' : 'https://emoji.slack-edge.com/T260V2K3P/party-parrot/f4193114d5b51396.gif',
  //     'keanu-thanks' : 'https://emoji.slack-edge.com/T260V2K3P/keanu-thanks/3b95a5708aedcc43.gif',
  //     'gratitude-asl' : 'https://emoji.slack-edge.com/T260V2K3P/gratitude-asl/1d2abb7d6ebb8392.gif',
  //     'aaw-yeah' : 'https://emoji.slack-edge.com/T260V2K3P/aaw_yeah/9319a5e09a021a37.gif',
  //     'baburao' : 'https://emoji.slack-edge.com/T260V2K3P/baburao/7712b8caeecc7a0f.png',
  //     'plus1' : 'https://emoji.slack-edge.com/T260V2K3P/plus1/0221d12b14573d82.png',
  //     'solvative' : 'https://emoji.slack-edge.com/T260V2K3P/solvative/79abc4ca612541c0.png'
  // }

  const handleReplyThread = (id) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  };

  const [expanded, setExpanded] = React.useState({});
  const [tooltipSource, setTooltipSource] = React.useState(false);
  const [tooltipDest, setTooltipDest] = React.useState(false);
  const [showAllEmoji, setShowAllEmoji] = React.useState(5);

  const handleTooltipSource = () => {
    setTooltipSource(true);
  };

  const handleTooltipDest = () => {
    setTooltipDest(!tooltipSource);
  };

  const handleShowAllEmoji = () => {
    let objectLength = reactions.length;
    setShowAllEmoji(objectLength);
  };

  const handleSeeLess = () => {
    setShowAllEmoji(5);
  };

  useEffect(() => {
    document.body.addEventListener("click", () => {
      setTooltipDest(false);
    });
    document.body.addEventListener("click", () => {
      setTooltipSource(false);
    });
  }, []);

  const capitalizeFirstWord = (forWhat) => {
    return forWhat.charAt(0).toUpperCase() + forWhat.substring(1);
  };

  return (
    <React.Fragment key={index}>
      <ListItem className={classes.ListItem__ActivityFeedCard}>
        <Card className={classes.root}>
          <CardContent style={{ paddingBottom: "16px" }}>
            <Box className={classes.profileCont}>
              <Box className={classes.profiles}>
                <Box className={classes.profileSubcont}>
                  <Typography
                    className={classes.tooltipForSource}
                    style={
                      tooltipSource ? { display: "flex" } : { display: "none" }
                    }
                  >
                    {activity?.destinationMember?.profile?.real_name}
                  </Typography>

                  <Avatar
                    className={classes.small}
                    src={activity?.destinationMember?.profile?.image_32}
                    display="inline"
                    onMouseOver={() => setTooltipSource(true)}
                    onMouseLeave={() => setTooltipSource(false)}
                    onClick={() => handleTooltipSource()}
                  />
                </Box>
                <KeyboardBackspaceIcon className={classes.leftArrow} />
                <Box className={classes.profileSubcont}>
                  <Typography
                    className={classes.tooltipForDest}
                    style={
                      tooltipDest ? { display: "flex" } : { display: "none" }
                    }
                  >
                    {activity?.sourceMember?.profile?.real_name}
                  </Typography>

                  <Avatar
                    className={classes.small}
                    src={activity?.sourceMember?.profile?.image_32}
                    display="inline"
                    onMouseOver={() => setTooltipDest(true)}
                    onMouseLeave={() => setTooltipDest(false)}
                    onClick={() => handleTooltipDest()}
                  />
                </Box>
              </Box>
              <Typography className={classes.date}>
                {window.innerWidth < 350 ? (
                  <>
                    {activity?.createdAt
                      ? moment(activity.createdAt).format("MMM Do YY, h:mm a")
                      : null}
                  </>
                ) : (
                  <>
                    {activity?.createdAt
                      ? moment(activity.createdAt).format(
                          "MMMM Do YYYY, h:mm a"
                        )
                      : null}
                  </>
                )}
              </Typography>
            </Box>
            <Box className={classes.pointsDetailsCont}>
              <Typography
                className={classes.userName__ActivityFeedCard}
                color="inherit"
                display="inline"
              >
                <Box fontWeight="fontWeightBold">
                  {activity?.destinationMember?.profile?.real_name}
                </Box>
              </Typography>
              <Typography
                className={classes.commanRewardText__ActivityFeed}
                style={{ margin: "3px 8px 0px 0px" }}
              >
                <Box>was rewarded</Box>
              </Typography>
              <Avatar
                className={`${clsx(classes.small, classes.yellow)} ${
                  classes.activityValue__ActivityFeedCard
                }`}
                style={{ margin: "3px 8px 0px 0px", minWidth: "30px" }}
              >
                {activity.value}
              </Avatar>
              <Typography
                className={classes.commanRewardText__ActivityFeed}
                style={{ margin: "3px 8px 0px 0px" }}
              >
                <Box>PeerFives by</Box>
              </Typography>
              <Typography
                className={classes.userName__ActivityFeedCard}
                color="inherit"
                display="inline"
              >
                <Box fontWeight="fontWeightBold">
                  {activity?.sourceMember?.profile?.real_name}
                </Box>
              </Typography>
            </Box>
            <Typography
              className={`mt-2 ${classes.description__ActivityFeedCard}`}
            >
              <Box
                textAlign="justify"
                fontStyle="initial"
                className="p-2"
                bgcolor="#FCF8F1"
                width="fit-content"
              >
                <div
                  className="mx-1 my-0"
                  style={{ wordBreak: "break-word", textAlign: "left" }}
                >
                  {/* {capitalizeFirstWord(activity.forWhat)} */}
                  {capitalizeFirstWord(activity.forWhat.split(":")[0])}
                  {activity.forWhat
                    .split(":")
                    .splice(1)
                    .map((reaction) => {
                      return Object.keys(emoji.map.colons).includes(
                        reaction
                      ) ? (
                        <>{emoji.replace_colons(":" + reaction + ":")}</>
                      ) : (
                        " "
                      );
                    })}
                  {activity?.coreValues?.map((value) => (
                    <span
                      className="mx-1 my-0"
                      style={{
                        color: "rgb(0, 124, 239)",
                        display: "inline-block",
                        marginRight: 0,
                      }}
                    >
                      #{value}
                    </span>
                  ))}
                </div>
              </Box>
            </Typography>

            <Grid
              container
              className={classes.reactionContainer__ActivityFeedCard}
            >
              <Grid
                item
                sm={8}
                xs={
                  activity.threadMessages && activity.threadMessages.length > 0
                    ? 10
                    : 12
                }
                className={classes.allReactions}
              >
                {activity?.reactions === undefined ||
                activity?.reactions === null ? (
                  " "
                ) : (
                  <>
                    {activity?.reactions && reactions.length <= showAllEmoji ? (
                      <>
                        {Object.keys(activity?.reactions).map((reaction) => {
                          return activity?.reactions[reaction].length > 0 ? (
                            <Box className={classes.singleReaction}>
                              {/* <span className={classes.emoji__ActivityFeedCard}>
                                {emoji.replace_colons(":" + reaction + ":")}
                              </span> */}
                              <span className={classes.emoji__ActivityFeedCard}>
                                {Object.keys(emoji.map.colons).includes(
                                  reaction.split(":")[0]
                                ) ? (
                                  <>
                                    {emoji.replace_colons(":" + reaction + ":")}
                                  </>
                                ) : (
                                  <img
                                    style={{ width: "20px" }}
                                    src={customEmojis[reaction]}
                                  />
                                )}
                              </span>
                              <span
                                className={classes.emojiCount__ActivityFeedCard}
                              >
                                {activity?.reactions[reaction].length}
                              </span>
                            </Box>
                          ) : (
                            ""
                          );
                        })}
                        {showAllEmoji > 5 && (
                          <Box
                            className={classes.seeLessCont}
                            onClick={() => handleSeeLess()}
                          >
                            <Typography className={classes.seeLess}>
                              see less
                            </Typography>
                          </Box>
                        )}
                      </>
                    ) : (
                      <>
                        {Object.keys(activity?.reactions)
                          .slice(0, 5)
                          .map((reaction) => {
                            return activity?.reactions[reaction].length > 0 ? (
                              <Box className={classes.singleReaction}>
                                {/* <span
                                  className={classes.emoji__ActivityFeedCard}
                                >
                                  {emoji.replace_colons(":" + reaction + ":")}
                                </span> */}
                                <span
                                  className={classes.emoji__ActivityFeedCard}
                                >
                                  {Object.keys(emoji.map.colons).includes(
                                    reaction.split(":")[0]
                                  ) ? (
                                    <>
                                      {emoji.replace_colons(
                                        ":" + reaction + ":"
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      style={{ width: "20px" }}
                                      src={customEmojis[reaction]}
                                    />
                                  )}
                                </span>

                                <span
                                  className={
                                    classes.emojiCount__ActivityFeedCard
                                  }
                                >
                                  {activity?.reactions[reaction].length}
                                </span>
                              </Box>
                            ) : (
                              ""
                            );
                          })}
                        <Box
                          className={classes.singleReaction}
                          onClick={handleShowAllEmoji}
                        >
                          <span
                            className={
                              classes.emojiCountAdditional__ActivityFeedCard
                            }
                          >
                            +{Object.keys(activity?.reactions).length - 5} more
                          </span>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Grid>
              {activity.threadMessages && activity.threadMessages.length > 0 ? (
                <Grid
                  item
                  sm={4}
                  xs={2}
                  className={classes.replyIconContainer__ActivityFeedCard}
                >
                  <Box
                    textAlign="Right"
                    color="text.disabled"
                    onClick={() => {
                      handleReplyThread(index);
                    }}
                    className={classes.expand}
                  >
                    <AvatarGroup max={4} className={classes.avatarGroup}>
                      {activity?.threadMessages?.map((thread, childIndex) => {
                        return (
                          <Avatar
                            className={classes.small}
                            src={
                              activity?.messageSender[childIndex]?.profile
                                ?.image_32
                            }
                            display="inline"
                          />
                        );
                      })}
                    </AvatarGroup>
                    <Typography className={classes.replyText}>
                      {activity.threadMessages.length}
                      {activity.threadMessages.length === 1
                        ? " reply"
                        : " replies"}
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                ""
              )}
              {/* {activity.threadMessages && activity.threadMessages.length > 0 ? (
                <Grid
                  item
                  xs={2}
                  className={classes.replyIconContainer__ActivityFeedCard}
                >
                  <Box
                    textAlign="Right"
                    color="text.disabled"
                    onClick={() => {
                      handleReplyThread(index);
                    }}
                    className={classes.expand}
                  >
                    <ReplyIcon className={classes.replyIcon} />
                    {activity.threadMessages.length}
                    {activity.threadMessages.length === 1
                      ? " reply"
                      : " replies"}
                  </Box>
                </Grid>
              ) : (
                ""
              )} */}
            </Grid>
            {activity.threadMessages ? (
              <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                <Divider className="mt-2" />
                <CardContent className="p-1">
                  {activity.threadMessages.map((thread, childIndex) => {
                    return (
                      <ReplyThread
                        activity={activity}
                        thread={thread}
                        childIndex={childIndex}
                      ></ReplyThread>
                    );
                  })}
                </CardContent>
                <Box
                  textAlign="Right"
                  color="text.disabled"
                  onClick={() => {
                    handleReplyThread(index);
                  }}
                  className={clsx(classes.expand, "mt-n1")}
                  style={{
                    textDecoration: "underline",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  See Less Replies
                </Box>
              </Collapse>
            ) : (
              ""
            )}
          </CardContent>
        </Card>
      </ListItem>
    </React.Fragment>
  );
};

ActivityFeedCard.propTypes = {
  activity: PropTypes.object,
  index: PropTypes.number,
};

ActivityFeedCard.defaultProps = {
  activity: {},
  index: 0,
};

export default ActivityFeedCard;
