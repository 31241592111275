import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import * as storageServices from "../utils/services/storageServices";
import UserContext from "../utils/context/UserContext";

const OnboardingRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);
  let isBoarded = user?.team?.isBoarded;
  let isAdmin = user?.members?.peerfiveAdmin;
  // user?.members?.raw_data?.is_admin ||
  // user?.members?.peerfiveAdmin ||
  // user?.members?.raw_data?.is_owner;

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        storageServices.get(storageServices.TOKEN) && !isBoarded && isAdmin ? (
          <Component {...props} />
        ) : storageServices.get(storageServices.TOKEN) ? (
          <Redirect to="/dashboard" />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default OnboardingRoute;
