import React from "react";
import styles from "./PointAllocationSummary.module.scss";
import { Typography } from "@material-ui/core";

const PointAllocationSummary = ({ totalMembers = 0, p5PerMember = 0 }) => {
  const sections = [
    {
      text: "Assigned PeerFives/Member",
      value: p5PerMember,
      bgColor: "yellow",
    },
    {
      text: "Total Team Members",
      value: totalMembers ?? "0",
      bgColor: "yellow",
    },
    {
      text: "Company's Total PeerFives",
      value: totalMembers * p5PerMember,
      bgColor: "green",
    },
  ];

  return (
    <>
      <div className={styles.onBoardingText}>
        <Typography className={styles.CardDetailsTitle}>
          Monthly Point Allocation
        </Typography>
      </div>
      {sections.map((section) => {
        return (
          <div
            className={`${styles.sectionContainer} ${
              section.bgColor === "yellow"
                ? styles.yellowBackground
                : styles.greenBackground
            }`}
          >
            <span>{section.text}</span>
            <span className={styles.sectionValues}>{section.value}</span>
          </div>
        );
      })}
      <div className={styles.information}>
        This can be changed later by going to settings.
      </div>
    </>
  );
};

export default PointAllocationSummary;
