import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./CoreValue.module.scss";
import TagsInputBox from "../../../../components/TagInputBox/TagInputBox";

const CoreValue = (props) => {
  const { handleNextStep } = props;

  const handelSubmit = () => {
    handleNextStep();
  };

  return (
		<div className={styles.card}>
			<Typography className={styles.title}>Configure Core Values</Typography>
			<Typography className={styles.placeholder}>
				PeerFives ensures that the reward messages are aligned with company's
				<br />
				core values. Each reward message can be tagged with core values.
			</Typography>

			<TagsInputBox tagsList={[]} from="onboarding" handelSubmit={handelSubmit} handelSkip={handelSubmit} handleNextStep={handleNextStep} />

			<Typography className={styles.bottomText}>
    Clicking on Save and Finish will redirect you to PeerFives activity feed and admin panel.</Typography>
		</div>
	);
};

export default CoreValue;
