import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useToaster } from "helper/ToasterContext";
import Leaderboard from "../../components/LeaderBoard/Leaderboard";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import UserContext from "../../utils/context/UserContext";
import DialogModel from "../../components/DialogModel/DialogModel";
import BudgetDialog from "./components/BudgetDialog/BudgetDialog";
import AdminDialog from "./components/MakeAdminDialog/MakeAdminDialog";
import CoreValuesDialog from "./components/CoreValuesDialog/CoreValuesDialog";
import CardDetailsDialog from "./components/CardDetailsDialog/CardDetailsDialog";
import BillingDetailsDialog from "./components/BillingDetailsDialog/BillingDetailsDialog";
import CancelSubscriptionDialog from "./components/CancelSubscriptionDialog/CancelSubscriptionDialog";
import ActivateSubscriptionDialog from "./components/ActivateSubscriptionDialog/ActivateSubscriptionDialog";
import ChannelDialog from "./components/ChannelDialog/ChannelDialog";
import MainCard from "./components/MainCard/MainCard";
import Company from "./components/Company/Company";
import ProfileCard from "./components/ProfileCard/ProfileCard";
import SupportCard from "./components/SupportCard/SupportCard";
import CurrentSubscription from "./components/CurrentSubscription/CurrentSubscription";
import Settings from "./components/Settings/Settings";
import getPendingItemsObject from "../../helper/functions";
import PeerFiveBuddy from "../../assets/icons/PeerFiveBuddy.svg";
import YellowBG from "../../assets/icons/YellowBG.svg";
import MobileNavSetting from "./components/MobileNavSetting/MobileNavSetting";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  CardContent: {
    paddingBottom: "0px !important",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "12px",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  modal: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid white",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: "none",
    position: "relative",
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "8px",
    backgroundColor: "#F5F5F5",
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  margin: {
    margin: theme.spacing(2),
  },
  company: {
    marginTop: "-8px",
    marginBottom: "-5px",
  },
  profile: {
    marginTop: "0.8rem",
    minWidth: 100,
  },
  boxContainer: {
    background: "#FFFADF",
    borderRadius: "4px",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "0.75rem",
  },
  boxTitle: {
    fontSize: "1rem",
    lineHeight: "19px",
  },
  boxBudgetTitle: {
    fontSize: "0.75rem",
    lineHeight: "14px",
    marginTop: "0.75rem",
    fontWeight: 500,
    marginBottom: "4px",
    color: "#A0A0A0",
  },
  boxAmount: {
    fontWeight: 900,
  },
  yellowBox: {
    background: "#FFFADF",
  },
  lightGreenBox: {
    background: "#DBF3EB",
  },
  gridContainer: {
    paddingTop: "1.5rem",
  },
  boxInputContainer: {
    marginBottom: "1.5rem",
  },
  budgetButton: {
    backgroundColor: "#000000",
    color: "white",
    textTransform: "capitalize",
    marginBottom: "1.5rem",
  },
  greyText: {
    color: "#A0A0A0",
  },
  closeIcon: {
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: "9",
    cursor: "pointer",
  },
  mainProfileCont: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "2.5rem",
    },
  },
  profileContainer: {
    maxWidth: "80%",
    margin: "1rem auto",

    [theme.breakpoints.up("xl")]: {
      maxWidth: "83%",
    },
    [theme.breakpoints.down("xl")]: {
      maxWidth: "83%",
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "83%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "85%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "91%",
    },
  },
  leaderBoardPath: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      overflow: "hidden",
      marginTop: "3rem",
    },
  },
  activePath: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "3.5rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  nonActivePath: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  topProfileCont: {
    maxWidth: "1400px",
  },
}));

export default () => {
  const Toaster = useToaster();
  const classes = useStyles();
  const [url, setUrl] = useState("");
  const [isLoaderMarketPlace, setIsLoaderMarketPlace] = useState(false);
  const [profileData, setProfileData] = useState();
  const [subscriptionData, setSubscriptionData] = useState();
  const { user, setUser } = useContext(UserContext);
  const [isAdmin, setIsAdmin] = useState();
  const [subscriptionPlan, setSubscriptionPlan] = useState();
  const [subscriptionAmount, setSubscriptionAmount] = useState();
  const [channelList, setChannelList] = useState();
  const [selectChannel, setSelectChannel] = useState({});
  const [subscriptionGST, setSubscriptionGST] = useState();
  const [loader, setLoader] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [pendingItems, setPendingItems] = useState({});
  const [latestCreditCardDetails, setLatestCreditCardDetails] = useState();
  const [budgetDialog, setBudgetDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });
  const [coreValuesDialog, setCoreValuesDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });
  const [adminDialog, setAdminDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });
  const [cardDetailsDialog, setCardDetailsDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });
  const [billingDetailsDialog, setBillingDetailsDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });
  const [cancelSubscriptionDialog, setCancelSubscriptionDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });
  const [activateSubscriptionDialog, setActivateSubscriptionDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });
  const [channelDialog, setChannelDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });

  const admin = user?.members?.peerfiveAdmin;
  // user?.members?.raw_data?.is_admin ||
  //   user?.members?.peerfiveAdmin ||
  //   user?.members?.raw_data?.is_owner;
  const getSubscription = async () => {
    try {
      const { data } = await apiServices.get(apiConstant.SUBSCRIPTION);
      if (data.status) {
        setSubscriptionData(data);
      }
    } catch {
      console.log("error");
    }
  };

  const getPendingItems = async () => {
    if (user.members.peerfiveAdmin) {
      const PendingItemsObject = getPendingItemsObject(user);
      setPendingItems(PendingItemsObject);
    }
  };

  const getMembersList = async () => {
    setLoader(true);
    try {
      const { data } = await apiServices.get(apiConstant.MEMBERS_ALL);
      if (data) {
        setMembersList(data);
      }
      setLoader(false);
    } catch {
      console.log("error");
      setLoader(false);
    }
  };
  const getChannelsList = async () => {
    setLoader(true);
    try {
      const { data } = await apiServices.get(apiConstant.CHANNEL_LIST);
      // console.log(data.publicChannels);
      if (data.status) {
        setChannelList(data.publicChannels);
        // console.log(channelList);
        const clickedOption = data.publicChannels.find(
          (item) => item.name === "general"
        );
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (user) {
      setProfileData(user);
      setIsAdmin(admin);
      setSelectChannel(user.team.notificationChannel);
      getPendingItems();
    }
    // eslint-disable-next-line
  }, [user]);

  const [chipData, setChipData] = React.useState([]);
  const openAdminModal = () => {
    setAdminDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };
  const openBudgetModal = () => {
    setBudgetDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };
  const openCoreValuesModal = () => {
    setCoreValuesDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };
  const openCardDetailsModal = () => {
    setCardDetailsDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };
  const openBillingDetailsModal = () => {
    setBillingDetailsDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };
  const openCancelSubscriptionModal = () => {
    setCancelSubscriptionDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };
  const openActivateSubscriptionModal = () => {
    setActivateSubscriptionDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };
  const openChannelModal = () => {
    setChannelDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };

  const getCoreValues = async () => {
    try {
      const { data } = await apiServices.get(apiConstant.CORE_VALUES);
      if (data) {
        // eslint-disable-next-line
        data.coreValues.map((item) => {
          setChipData((previousChipData) => [...previousChipData, item.name]);
        });
      }
    } catch {
      console.log("error");
    }
  };

  const setMarketPlace = async (value) => {
    setIsLoaderMarketPlace(true);
    try {
      const { data } = await apiServices.patch(apiConstant.TEAMS_MARKETPLACE, {
        isEnableMarketplace: value,
      });
      if (data.status) {
        Toaster.openSnackbar(data.message);
        const dataProfile = await apiServices.get(apiConstant.PROFILE);
        if (dataProfile.data) {
          setUser(dataProfile.data);
        }
        setIsLoaderMarketPlace(false);
      } else {
        setIsLoaderMarketPlace(false);
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
    } catch {
      setIsLoaderMarketPlace(false);
    }
  };

  const getProfileData = async () => {
    try {
      const { data } = await apiServices.get(apiConstant.PROFILE);
      if (data) {
        setUser(data);
      }
    } catch {
      console.log("error");
    }
  };

  const getSubscriptionPlan = async () => {
    setLoader(true);
    try {
      const { data } = await apiServices.get(apiConstant.SUBSCRIPTION_PLAN);
      if (data) {
        setSubscriptionPlan(data.data);
      }
      setLoader(false);
    } catch {
      console.log("error");
      setLoader(false);
    }
  };
  const convertPricePlan = async (from) => {
    try {
      const requestURL = `https://api.exchangerate.host/convert?from=${from}&to=INR`;
      const request = new XMLHttpRequest();
      request.open("GET", requestURL);
      request.responseType = "json";
      request.send();
      request.onload = function () {
        let conversionRate = request.response.info.rate;
        conversionRate *= subscriptionPlan.subscriptionPlan.amount;
        setSubscriptionAmount(conversionRate);
        setSubscriptionGST(
          (conversionRate * subscriptionPlan.taxRates.taxPercentage) / 100
        );
      };
    } catch {
      console.log("error");
    }
  };
  useEffect(() => {
    if (subscriptionPlan) {
      if (subscriptionPlan.subscriptionPlan.currency.toLowerCase() !== "inr") {
        convertPricePlan(subscriptionPlan.subscriptionPlan.currency);
      }
    }
    // eslint-disable-next-line
  }, [subscriptionPlan]);
  useEffect(() => {
    if (isAdmin) {
      getSubscription();
      getSubscriptionPlan();
      getChannelsList();
    }
    //  eslint-disable-next-line
  }, [isAdmin]);

  const getSelectedPath = (url) => {
    setUrl(url);
  };

  useEffect(() => {
    getCoreValues();
    getMembersList();
    getSelectedPath();
  }, []);

  const closeAdminDialog = (params) => {
    if (params) {
      getMembersList();
    }
    setAdminDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };
  const closeBudgetDialog = (params) => {
    if (params) {
      getProfileData();
    }
    setBudgetDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };
  const closeCoreValuesDialog = () => {
    setCoreValuesDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };
  const closeCardDetailsDialog = (params) => {
    if (params) {
      getProfileData();
      // window.location.reload();
    }
    setCardDetailsDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };

  const closeBillingDetailsDialog = (params) => {
    if (params) {
      getProfileData();
    }
    setBillingDetailsDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };
  const closeCancelSubscriptionDialog = (params) => {
    if (params) {
      // getSubscription();
      getProfileData();
      setCancelSubscriptionDialog({
        type: "new",
        props: {
          open: false,
        },
        data: null,
      });
    }
    setCancelSubscriptionDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };

  const closeActivateSubscriptionDialog = (params) => {
    if (params) {
      getSubscription();
      getProfileData();
      setActivateSubscriptionDialog({
        type: "new",
        props: {
          open: false,
        },
        data: null,
      });
    }
    setActivateSubscriptionDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };
  const closeChannelDialog = (params) => {
    if (params) {
      getProfileData();
    }
    setChannelDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };
  const refreshActivateScreen = (params) => {
    if (params) {
      getProfileData();
    }
  };
  const submitCoreValuesDialog = (tags) => {
    setChipData(tags);
    closeCoreValuesDialog();
  };
  if (loader) {
    return (
      <div className="flash-loader">
        <img src={YellowBG} className="logoSquare spinLoader" alt="PeerFives" />
        <img
          src={PeerFiveBuddy}
          style={{ position: "absolute" }}
          alt="PeerFives"
        />
      </div>
    );
  }
  return (
    <>
      <MobileNavSetting getPath={getSelectedPath} />

      <div className={classes.ProfileContainer__Profile}>
        <Grid container direction="row" className={classes.topProfileCont}>
          <Grid
            item
            md={9}
            sm={12}
            lg={9}
            xs={url === "/settings/#leaderboard" ? 0 : 12}
            className="leftPanel"
          >
            <Grid
              style={{
                overflowY: "scroll",
                height: "calc(100vh - 40px)",
                paddingBottom: "1.5rem",
              }}
              className={classes.mainProfileCont}
            >
              <div className={classes.profileContainer}>
                {profileData && (
                  <section
                    id="profile"
                    className={
                      url === "/settings/#profile"
                        ? `${classes.activePath}`
                        : `${classes.nonActivePath}`
                    }
                  >
                    <MainCard
                      title="Company"
                      plateformType={profileData?.members?.platform}
                    >
                      <Company
                        profileData={profileData}
                        plateformType={profileData?.members?.platform}
                      />
                    </MainCard>

                    <MainCard title="My Profile">
                      <ProfileCard profileData={profileData} />
                    </MainCard>
                  </section>
                )}
                <div className={classes.isAdminCont__Profile}>
                  {isAdmin && (
                    <>
                      <section
                        id="settings"
                        className={
                          url === "/settings/#settings"
                            ? `${classes.activePath}`
                            : `${classes.nonActivePath}`
                        }
                      >
                        <MainCard title="Settings">
                          <Settings
                            tags={chipData}
                            membersData={membersList}
                            budgetData={profileData.team}
                            handleCoreValue={openCoreValuesModal}
                            handleAdminModel={openAdminModal}
                            handelBudgetModel={openBudgetModal}
                            handleChannelModal={openChannelModal}
                            setMarketPlace={setMarketPlace}
                            isLoaderMarketPlace={isLoaderMarketPlace}
                            plateformType={profileData?.members?.platform}
                            pendingItems={pendingItems}
                            tooltipStatus={budgetDialog.props.open}
                            subscriptionData={subscriptionData}
                          />
                        </MainCard>
                      </section>
                      <section
                        id="subscription"
                        className={
                          url === "/settings/#subscription"
                            ? `${classes.activePath}`
                            : `${classes.nonActivePath}`
                        }
                      >
                        <MainCard title="Current Subscription">
                          <CurrentSubscription
                            latestCreditCardDetails={latestCreditCardDetails}
                            currentSubscriptionData={subscriptionData}
                            isSubscriptionActive={
                              !profileData.team.cancelAtSubscriptionEnd
                            }
                            paymentCard={profileData.team.paymentInfo}
                            handleCardDetails={openCardDetailsModal}
                            handleBillingDetails={openBillingDetailsModal}
                            handleCancelSubscription={
                              openCancelSubscriptionModal
                            }
                            handleActivateSubscription={
                              openActivateSubscriptionModal
                            }
                            pendingItems={pendingItems}
                            tooltipStatus={cardDetailsDialog.props.open}
                          />
                        </MainCard>
                      </section>
                      <section
                        id="help"
                        className={
                          url === "/settings/#help"
                            ? `${classes.activePath}`
                            : `${classes.nonActivePath}`
                        }
                      >
                        <MainCard title="Need Help?">
                          <SupportCard />
                        </MainCard>
                      </section>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            md={3}
            sm={0}
            lg={3}
            xs={url === "/settings/#leaderboard" ? 12 : 0}
            style={{
              overflow: "scroll",
              height: "calc(100vh - 40px)",
            }}
            id="leaderboard"
            className={
              url === "/settings/#leaderboard"
                ? `${classes.leaderBoardPath}`
                : `${classes.nonActivePath}`
            }
          >
            <Leaderboard marketPlace />
          </Grid>
        </Grid>

        <DialogModel
          {...adminDialog.props}
          title="Admin Dialog"
          disbleHeader
          maxWidth="sm"
          disableBackdropClick
          onClose={closeAdminDialog}
          className={classes.coreValuesDialogCont__Profile}
        >
          <AdminDialog
            onClose={closeAdminDialog}
            profileData={getProfileData}
          />
        </DialogModel>
        <DialogModel
          {...budgetDialog.props}
          title="Budget Dialog"
          disbleHeader
          maxWidth="md"
          disableBackdropClick
          onClose={closeBudgetDialog}
        >
          <BudgetDialog
            onClose={closeBudgetDialog}
            profileData={getProfileData}
          />
        </DialogModel>
        <DialogModel
          {...coreValuesDialog.props}
          title="Core Value Dialog"
          disbleHeader
          maxWidth="md"
          onClose={closeCoreValuesDialog}
        >
          <CoreValuesDialog
            tags={chipData}
            handelSubmit={submitCoreValuesDialog}
            onClose={closeCoreValuesDialog}
          />
        </DialogModel>
        <DialogModel
          {...cardDetailsDialog.props}
          title="Card Details Dialog"
          disbleHeader
          maxWidth="md"
          onClose={closeCardDetailsDialog}
        >
          <CardDetailsDialog
            setLatestCreditCardDetails={setLatestCreditCardDetails}
            onClose={closeCardDetailsDialog}
            prefilledDetails={profileData?.team?.billingInfo}
          />
        </DialogModel>
        <DialogModel
          {...billingDetailsDialog.props}
          title="Billing Details Dialog"
          disbleHeader
          maxWidth="md"
          onClose={closeBillingDetailsDialog}
        >
          <BillingDetailsDialog
            onClose={closeBillingDetailsDialog}
            prefilledDetails={profileData?.team?.billingInfo}
          />
        </DialogModel>
        <DialogModel
          {...cancelSubscriptionDialog.props}
          title="Cancel Subscription Dialog"
          disbleHeader
          maxWidth="md"
          onClose={closeCancelSubscriptionDialog}
        >
          <CancelSubscriptionDialog
            currentSubscriptionData={subscriptionData}
            onClose={closeCancelSubscriptionDialog}
          />
        </DialogModel>
        <DialogModel
          {...activateSubscriptionDialog.props}
          title="Activate Subscription Dialog"
          disbleHeader
          maxWidth="md"
          onClose={closeActivateSubscriptionDialog}
        >
          <ActivateSubscriptionDialog
            subscriptionData={subscriptionData}
            subscriptionPlans={subscriptionPlan}
            subscriptionAmount={subscriptionAmount}
            subscriptionGST={subscriptionGST}
            paymentCard={profileData?.team?.paymentInfo}
            refreshActivateScreen={refreshActivateScreen}
            onClose={closeActivateSubscriptionDialog}
            prefilledDetails={profileData?.team?.billingInfo}
          />
        </DialogModel>
        <DialogModel
          {...channelDialog.props}
          title="Channel Dialog"
          disbleHeader
          maxWidth="sm"
          onClose={closeChannelDialog}
        >
          <ChannelDialog
            channelList={channelList}
            selectChannel={selectChannel}
            onClose={closeChannelDialog}
            plateformType={profileData?.members?.platform}
          />
        </DialogModel>
      </div>
    </>
  );
};
