/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, { useState, useContext, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import SearchBox from "../SearchBox/SearchBox";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import UserContext from "../../utils/context/UserContext";
import { generateFakeArray } from "../../utils/services/utility";
import LeaderUserCard from "./components/LeaderUserCard/LeaderUserCard";
import LeaderUserCardSkeleton from "./components/LeaderUserCard/LeaderUserCardSkeleton";
import LeaderUserInfo from "./components/LeaderUserInfo/LeaderUserInfo";
import styles from "./Leaderboard.module.scss";
import NoUserFound from "../../assets/NoUserFound.svg";

const Leaderboard = (props) => {
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [filterleaderBoardData, setFilterLeaderBoardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [profileData, setProfileData] = useState();
  const [fakemembersList] = useState(generateFakeArray);

  const getProfileData = async () => {
    try {
      let { data } = await apiServices.get(apiConstant.PROFILE);
      if (data) {
        setUser(data);
      }
    } catch {
      console.log("error");
    }
  };
  useEffect(() => {
    getProfileData();
    if (props.marketPlace) {
      getProfileData();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (user) {
      setProfileData(user);
    }
  }, [user]);

  const getLeaderBoard = async () => {
    setLoading(true);
    try {
      const { data } = await apiServices.get(apiConstant.LEADER_BOARD);
      if (data) {
        let leaderBoardData = [];
        data.forEach((element, index) => {
          leaderBoardData.push({
            ...element,
            rank: index + 1,
          });
        });
        setLeaderBoardData(leaderBoardData);
        setFilterLeaderBoardData(leaderBoardData);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeaderBoard();
  }, []);

  const doFilter = (query) => {
    setFilterLeaderBoardData(
      leaderBoardData.filter((data) =>
        data?.profile?.real_name.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  return (
    <>
      <div className={styles.leaderBoardDataCont__Leaderboard}>
        {profileData && (
          <LeaderUserInfo
            profileData={profileData}
            className={styles.leaderBoardList}
          />
        )}
        <Divider className="mt-n1" />
        <div className={styles.leaderBoardSection}>
          <Typography className={styles.headerTitle}>Leaderboard</Typography>
          <div className={styles.searchBox}>
            <SearchBox
              placeholder="Search Members"
              onChange={(e) => doFilter(e.target.value)}
              className={styles.SearchInp}
            />
          </div>
          <div className={styles.allUsers_Leaderboard}>
            {loading
              ? fakemembersList.map((fakeData) => (
                  <LeaderUserCardSkeleton key={fakeData} />
                ))
              : filterleaderBoardData.map((leaderData, index) => {
                  return (
                    <div>
                      <LeaderUserCard
                        leaderData={leaderData}
                        index={index}
                        key={leaderData?._id}
                      />
                    </div>
                  );
                })}

            {!loading && filterleaderBoardData.length < 1 && (
              <div className={styles.noDataDiv}>
                <img src={NoUserFound} alt="No Data Found" />
                <Typography className={styles.noData}>No User Found</Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Leaderboard;
