import { Button, Divider, InputBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import CircularCheck from "components/CircularCheck/CircularCheck";
import UserContext from "utils/context/UserContext";
import styles from "./PointAllocationForm.module.scss";
// import DialogModel from "../../../../components/DialogModel/DialogModel";
// import AssistanceDialog from "./AssistanceDialog/AssistanceDialog";
import ArrowDown from "../../../../assets/icons/ArrowDown.svg";
import ArrowUp from "../../../../assets/icons/ArrowUp.svg";
import { useToaster } from "../../../../helper/ToasterContext";
import PointAllocationSummary from "../PointAllocationSummary/PointAllocationSummary";
import * as apiServices from "../../../../utils/services/apiServices";
import { MEMBER_SPENDING_LIMIT } from "utils/constant/apiConstant";

const useStyles = makeStyles({
  button: {
    textTransform: "none",
  },
});

const PointAllocationForm = (props) => {
  const { handleNextStep, subscriptionPlans } = props;

  const options = [
    {
      title: "Default",
      titleTip: "(100 PeerFives to each member)",
      value: 100,
      optionDescription:
        "The ideal choice for organizations looking to build a culture of gratitude further and enhance employee engagement.",
    },
    {
      title: "Starter",
      titleTip: "(50 PeerFives to each member)",
      value: 50,
      optionDescription:
        "The perfect starting point for organizations looking to get familiar with the concept of Peer Recognition & Rewards.",
    },
    {
      title: "High",
      titleTip: "(200)",
      value: 200,
      optionDescription:
        "The PRO MAX solution for organizations with looking to unlock the full potential of their team with ultimate rewards.",
    },
    {
      title: "Custom Points Assignment",
    },
  ];

  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [selectedOptionIdx, setSelectedOptionIdx] = useState(0);
  const [openOptions, setOpenOptions] = useState(false);
  const [customPointsPerEmp, setCustomPointsPerEmp] = useState(100);
  const [pointsInputVal, setPointsInputVal] = useState();
  const [showSummary, setShowSummary] = useState(false);

  const Toaster = useToaster();

  const { user } = useContext(UserContext);

  const changeSelection = (index) => {
    setSelectedOptionIdx(index);
    if (options[index].value) setCustomPointsPerEmp(options[index].value);
  };

  const nextStep = async () => {
    if (selectedOptionIdx === 3 && isNaN(customPointsPerEmp)) {
      // TODO :: Proper error msg
      Toaster.openSnackbar(
        "Please enter the custom point allocation peerfives value.",
        "error"
      );
    } else {
      await assignPointsToMembers();
      handleNextStep();
    }
    setIsBtnLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const assignPointsToMembers = async () => {
    const { data } = await apiServices.patch(MEMBER_SPENDING_LIMIT, {
      teamId: user?.members?.team_id,
      pointsToAllocate: customPointsPerEmp,
    });

    if (!data?.status) {
      Toaster.openSnackbar(
        data?.message ? data.message : "Something went wrong!",
        "error"
      );
    }
  };

  const classes = useStyles();

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.innerWrapper}>
        {showSummary ? (
          <PointAllocationSummary
            totalMembers={subscriptionPlans?.members}
            p5PerMember={customPointsPerEmp}
          />
        ) : (
          <div>
            <div className={styles.onBoardingText}>
              <Typography className={styles.CardDetailsTitle}>
                Assign Reward Points To Your Team Members.
              </Typography>
              <Typography className={styles.boxTitle}>
                With PeerFives, team members can give points to each other to
                recognize their contributions, using their assigned points.
              </Typography>
            </div>
            <Divider />
            <div>
              <Typography className={styles.detailsTitle}>
                Monthly Point Allocation
              </Typography>
              <div>
                <div
                  className={styles.defaultSelection}
                  onClick={() => {
                    changeSelection(0);
                  }}
                >
                  <div className={styles.optionTitle}>
                    <CircularCheck checked={selectedOptionIdx === 0} />
                    <Typography>
                      <b>{options[0].title} </b>
                      {options[0].titleTip}
                    </Typography>
                  </div>

                  {!openOptions && (
                    <Typography className={styles.optionDescription}>
                      {options[0].optionDescription}
                    </Typography>
                  )}
                </div>
                {openOptions && (
                  <Typography className={styles.calculationTip}>
                    Total assigned PeerFives Points 100x (10)Number of members =
                    1000 PeerFives
                  </Typography>
                )}
                <div className={styles.otherOptions}>
                  <Typography className={styles.formItemLabel}>
                    Other Options
                  </Typography>
                  <div className={styles.otherSelection}>
                    {openOptions ? (
                      options.map((option, i) => {
                        if (i === 0) {
                          return;
                        }

                        return (
                          <div
                            className={`${styles.optionContainer} ${
                              i % 2 == 0 ? styles.yellowBackground : ""
                            }`}
                            key={`pa-option-${i}`}
                            onClick={() => {
                              changeSelection(i);
                              setOpenOptions(false);
                            }}
                          >
                            <div className={styles.optionTitle}>
                              <CircularCheck
                                checked={selectedOptionIdx === i}
                              />
                              <Typography>
                                <b>{`${option.title} `}</b>
                                {option.titleTip ?? <></>}
                              </Typography>
                            </div>

                            {option.optionDescription && (
                              <Typography className={styles.optionDescription}>
                                {option.optionDescription}
                              </Typography>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className={styles.optionContainer}
                        onClick={() => {
                          setOpenOptions(true);
                        }}
                      >
                        <div className={styles.optionTitle}>
                          <CircularCheck checked={selectedOptionIdx != 0} />
                          <Typography>
                            <b>{`${
                              options[
                                selectedOptionIdx > 0 ? selectedOptionIdx : 1
                              ].title
                            } `}</b>
                            {options[
                              selectedOptionIdx > 0 ? selectedOptionIdx : 1
                            ].titleTip ?? <></>}
                          </Typography>
                        </div>
                      </div>
                    )}
                    <span
                      className={styles.optionsArrow}
                      onClick={() => {
                        setOpenOptions(!openOptions);
                      }}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        alt="Peerfive"
                        src={openOptions ? ArrowDown : ArrowUp}
                      />
                    </span>
                  </div>
                </div>
                {selectedOptionIdx === 3 && (
                  <div className={styles.customPointContainer}>
                    <Typography className={styles.formItemLabel}>
                      How many PeerFives you want to assign?
                    </Typography>
                    <InputBase
                      inputProps={{ min: 1, onKeyPress: handleKeyPress }}
                      id="p5-per-emp"
                      value={pointsInputVal}
                      type="number"
                      onChange={(event) => {
                        setPointsInputVal(parseInt(event.target.value));
                        setCustomPointsPerEmp(parseInt(event.target.value));
                      }}
                      className={`${styles.inputBox}`}
                    />
                    <span className={styles.customPointsPostLabel}>
                      / Member
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className={styles.budgetFooter}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => {
              if (showSummary) {
                setShowSummary(false);
                return;
              }
              handleNextStep(true);
            }}
            disabled={isBtnLoading}
          >
            Prev
          </Button>
          <div className={styles.footer}>
            {/* <Typography
              className={styles.needAssistance}
              onClick={openAssistanceModal}
            >
              Need Assistance?
            </Typography> */}
            <Button
              variant="contained"
              className={styles.saveButton}
              onClick={() => {
                if (!showSummary) {
                  setShowSummary(true);
                  return;
                }
                setIsBtnLoading(true);
                nextStep();
              }}
            >
              {isBtnLoading ? (
                <div className="loader" />
              ) : showSummary ? (
                "Assign Points"
              ) : (
                "Continue"
              )}
            </Button>
          </div>
        </div>
        {/* <DialogModel
        {...assistanceDialog}
        title="Assistance Dialog"
        disbleHeader
        maxWidth="md"
        onClose={closeAssistanceDialog}
      >
        <AssistanceDialog onClose={closeAssistanceDialog} />
      </DialogModel> */}
      </div>
    </div>
  );
};

export default PointAllocationForm;
