import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Grid,
  ClickAwayListener,
  Grow,
  MenuList,
  Popper,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import BillingDetails from "../../../../components/BillingDetails/BillingDetails";
import CardDetails from "../../../../components/CardDetails/CardDetails";
import iconClose from "../../../../assets/icon_close.svg";
import styles from "./ActivateSubscriptionDialog.module.scss";
import * as apiServices from "../../../../utils/services/apiServices";
import * as apiConstant from "../../../../utils/constant/apiConstant";
import { useToaster } from "../../../../helper/ToasterContext";
import { ReactComponent as DropDownIcon } from "../../../../assets/dropDownIcon.svg";
import { ReactComponent as DropDownUpIcon } from "../../../../assets/DropDownUpIcon.svg";
import { currencyFormatter } from "../../../../utils/services/utility";
import { ReactComponent as EditButton } from "../../../../assets/editButton.svg";
import noCard from "../../../../assets/noCard.svg";
import billingDetailsIcon from "../../../../assets/BillingDetails.svg";
import CB from "../../../../assets/Cards/CB.svg";
import AmericanExpress from "../../../../assets/Cards/AmericanExpress.svg";
import DinersClub from "../../../../assets/Cards/DinersClub.svg";
import Discover from "../../../../assets/Cards/Discover.svg";
import Interac from "../../../../assets/Cards/Interac.svg";
import JCB from "../../../../assets/Cards/JCB.svg";
import MasterCard from "../../../../assets/Cards/MasterCard.svg";
import Rupay from "../../../../assets/Cards/Rupay.svg";
import UnionPay from "../../../../assets/Cards/UnionPay.svg";
import VISA from "../../../../assets/Cards/VISA.svg";

const ActivateSubscriptionDialog = (props) => {
  const {
    subscriptionData,
    subscriptionPlans,
    subscriptionAmount,
    subscriptionGST,
    prefilledDetails,
    paymentCard,
    onClose,
    refreshActivateScreen,
  } = props;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  let cardIcon = noCard;
  if (paymentCard?.cardType === "visa") cardIcon = VISA;
  else if (paymentCard?.cardType === "mastercard") cardIcon = MasterCard;
  else if (paymentCard?.cardType === "discover") cardIcon = Discover;
  else if (paymentCard?.cardType === "diners") cardIcon = DinersClub;
  else if (paymentCard?.cardType === "amex") cardIcon = AmericanExpress;
  else if (paymentCard?.cardType === "jcb") cardIcon = JCB;
  else if (paymentCard?.cardType === "interac") cardIcon = Interac;
  else if (paymentCard?.cardType === "unionpay") cardIcon = UnionPay;
  else if (paymentCard?.cardType === "rupay") cardIcon = Rupay;
  else if (paymentCard?.cardType === "cb") cardIcon = CB;
  const closeDialog = () => {
    onClose(true);
  };
  const anchorRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const Toaster = useToaster();
  const [open, setOpen] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("PricePlanScreen");
  const prevOpen = useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const activateSubscription = async (params) => {
    setIsLoading(true);
    try {
      const { data } = await apiServices.patch(
        apiConstant.ACTIVATE_SUBSCRIPTION
      );

      if (data.status) {
        Toaster.openSnackbar(
          data?.message ? data.message : "Subscription Activated Again"
        );
      } else {
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
      setIsLoading(false);
      closeDialog(true);
    } catch {
      setIsLoading(false);
    }
  };
  const handleAgreeClose = () => {
    activateSubscription();
  };
  const handleCardScreen = () => {
    setCurrentScreen("CardDetailScreen");
  };
  const handleBillingScreen = () => {
    setCurrentScreen("BillingDetailScreen");
  };
  const handleActivateScreen = () => {
    setCurrentScreen("PricePlanScreen");
    refreshActivateScreen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef) anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  // console.log(moment.unix(new Date()).utc().format("Do"));
  return (
    <div>
      <div className={styles.headerContainer}>
        <div>
          <Typography className={styles.headerTitle}>
            Activate Subscription
          </Typography>
        </div>
        <div onClick={closeDialog} onKeyDown={closeDialog} role="presentation">
          <img className={styles.closeIcon} src={iconClose} alt="close" />
        </div>
      </div>

      <div className={styles.bodyContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Elements stripe={stripePromise}>
              <div className={styles.StripeFormWrapper}>
                <Slide
                  direction="left"
                  in={currentScreen === "PricePlanScreen"}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    {currentScreen === "PricePlanScreen" && (
                      <div>
                        <div className={styles.teamSize}>
                          <Typography className={styles.teamSizeText}>
                            {subscriptionPlans?.members}
                          </Typography>
                        </div>
                        <div className={styles.onBoardingText}>
                          <Typography className="CardDetailsTitle">
                            Awesome people working with you
                          </Typography>
                          <Typography className={styles.boxTitle}>
                            Let’s start building a cheerful environment for them
                          </Typography>
                        </div>
                        <div className={styles.avatar}>
                          <AvatarGroup spacing="25">
                            {subscriptionPlans?.randomMembers ? (
                              subscriptionPlans?.randomMembers.map((member) => (
                                <Avatar
                                  src={member.raw_data.profile.image_32}
                                />
                              ))
                            ) : (
                              <Avatar alt="R" />
                            )}
                          </AvatarGroup>
                        </div>

                        <div className={styles.boxContainer} ref={anchorRef}>
                          <Typography className={styles.boxTitle}>
                            Total subscription charge
                          </Typography>
                          <Typography className={styles.boxAmount}>
                            $
                            {subscriptionPlans?.members > 100
                              ? subscriptionPlans?.subscriptionPlan?.amount *
                                  subscriptionPlans?.members +
                                subscriptionPlans?.taxRates?.taxAmount
                              : subscriptionPlans?.totalPlanPrice}
                            {/* {currencyFormatter(
                              subscriptionAmount + subscriptionGST,
                              subscriptionPlans?.subscriptionPlans?.currency
                            )} */}
                            /{subscriptionPlans?.subscriptionPlan?.interval}{" "}
                            <span className={styles.dropDownIcon}>
                              {open ? (
                                <DropDownUpIcon onClick={handleToggle} />
                              ) : (
                                <DropDownIcon onClick={handleToggle} />
                              )}{" "}
                            </span>
                          </Typography>
                        </div>
                        {anchorRef && (
                          <Popper
                            // style={{ width: "10rem", zIndex: "1" }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            placement="bottom-start"
                            className={
                              styles.dropDown__ActiveSubscriptionDialog
                            }
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: "center top" }}
                              >
                                <Paper className={styles.menuPrice}>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      autoFocusItem={open}
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                    >
                                      <div className={styles.menuItems}>
                                        <MenuItem
                                          className={
                                            styles.menuItem__ActivitySubscriptionDialog
                                          }
                                        >
                                          <Typography
                                            className={styles.boxTitleGrey}
                                          >
                                            Monthly Package for{" "}
                                            {subscriptionPlans?.members} People
                                            (
                                            {
                                              subscriptionPlans
                                                ?.subscriptionPlan?.minLimit
                                            }
                                            -
                                            {
                                              subscriptionPlans
                                                ?.subscriptionPlan?.maxLimit
                                            }
                                            )
                                          </Typography>
                                          <Typography
                                            className={styles.planPrice}
                                            align="left"
                                          >
                                            $
                                            {subscriptionPlans?.members > 100
                                              ? subscriptionPlans
                                                  ?.subscriptionPlan?.amount *
                                                subscriptionPlans?.members
                                              : subscriptionPlans
                                                  ?.subscriptionPlan?.amount}
                                            {/* {currencyFormatter(
                                              subscriptionAmount,
                                              subscriptionPlans
                                                ?.subscriptionPlans?.currency
                                            )} */}
                                            /
                                            {
                                              subscriptionPlans
                                                ?.subscriptionPlan?.interval
                                            }
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          className={
                                            styles.menuItem__ActivitySubscriptionDialog
                                          }
                                        >
                                          <Typography
                                            className={styles.boxTitleGrey}
                                          >
                                            Taxes (
                                            {
                                              subscriptionPlans?.taxRates
                                                ?.taxPercentage
                                            }
                                            %{"  "}
                                            {subscriptionPlans?.taxRates?.name})
                                          </Typography>

                                          <Typography
                                            className={styles.planPrice}
                                            align="left"
                                          >
                                            $
                                            {
                                              subscriptionPlans?.taxRates
                                                ?.taxAmount
                                            }
                                            {/* {currencyFormatter(
                                              subscriptionGST,
                                              subscriptionPlans
                                                ?.subscriptionPlans?.currency
                                            )} */}
                                          </Typography>
                                        </MenuItem>
                                      </div>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        )}

                        <div className={styles.contentBox}>
                          <Typography>
                            Your card will be charged the above amount (
                            {subscriptionData?.subscriptionData
                              ?.subscriptionData[0]?.current_period_end
                              ? moment
                                  .unix(
                                    subscriptionData?.subscriptionList[0]
                                      ?.current_period_end
                                  )
                                  .utc()
                                  .format("Do")
                              : moment().utc().format("Do")}
                            ) of every month, once your
                            <b> trial period ends</b>, until you cancel it.
                          </Typography>
                        </div>

                        <Divider />
                        <div className={styles.creditCardContent}>
                          <div className={styles.viewDetail}>
                            <img
                              src={
                                paymentCard?.cardImage
                                  ? paymentCard?.cardImage
                                  : cardIcon
                              }
                              className={styles.creditCardImage}
                              alt="Credit Card"
                            />
                            {paymentCard?.cardLast4Digit ? (
                              <Typography className={styles.description}>
                                Card Ending with {paymentCard?.cardLast4Digit}
                              </Typography>
                            ) : (
                              <Typography className={styles.lightDescription}>
                                No credit card added
                              </Typography>
                            )}
                          </div>
                          <EditButton
                            className={styles.cursorPointer}
                            onClick={handleCardScreen}
                          />
                        </div>
                        <Divider />
                        <div className={styles.creditCardContent}>
                          <div className={styles.viewDetail}>
                            <img
                              src={billingDetailsIcon}
                              className={styles.creditCardImage}
                              alt="Credit Card"
                            />

                            <Typography className={styles.description}>
                              Your Details
                            </Typography>
                          </div>
                          <EditButton
                            className={styles.cursorPointer}
                            onClick={handleBillingScreen}
                          />
                        </div>
                        <Divider />
                        <div className={styles.footer}>
                          <Button
                            color="primary"
                            className={styles.skipButton}
                            onClick={closeDialog}
                          >
                            No, I changed my mind
                          </Button>
                          <Button
                            variant="contained"
                            className={styles.saveButton}
                            disabled={!paymentCard?.cardLast4Digit}
                            onClick={handleAgreeClose}
                          >
                            {isLoading ? (
                              <div className="loader" />
                            ) : (
                              "Activate Subscription"
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </Slide>

                <Slide
                  direction="left"
                  in={currentScreen === "CardDetailScreen"}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    {currentScreen === "CardDetailScreen" && (
                      <CardDetails
                        from="activateDialog"
                        handleActivateScreen={handleActivateScreen}
                        handleSkip={closeDialog}
                        prefilledDetails={prefilledDetails}
                      />
                    )}
                  </div>
                </Slide>
                <Slide
                  direction="left"
                  in={currentScreen === "BillingDetailScreen"}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    {currentScreen === "BillingDetailScreen" && (
                      <BillingDetails
                        from="activateDialog"
                        handleActivateScreen={handleActivateScreen}
                        handleSkip={closeDialog}
                        prefilledDetails={prefilledDetails}
                      />
                    )}
                  </div>
                </Slide>
              </div>
            </Elements>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

ActivateSubscriptionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ActivateSubscriptionDialog.defaultProps = {};

export default ActivateSubscriptionDialog;
