import React, { useContext, useEffect, useRef, useState } from "react";
import { Divider, Typography } from "@material-ui/core";
import styles from "./OnBoardingOne.module.scss";
import ChannelConsent from "components/ChannelConsent/ChannelConsent";
import CelebrateCapIcon from "../../../../assets/icons/CelebrateCap.svg";
import UserContext from "utils/context/UserContext";

const OnBoardingOneForm = (props) => {
  const { handleNextStep, subscriptionPlans } = props;
  const anchorRef = useRef();
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef) anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={styles.OnBoardingOneForm__detailsContainer}>
      <div className={styles.logoContainer}>
        <img src={user?.team?.image} className={styles.logo} alt="Logo" />
      </div>
      <div className={styles.onBoardingText}>
        <Typography className={styles.CardDetailsTitle}>
          Welcome, {user?.team?.name}
          <img
            style={{ cursor: "pointer" }}
            alt="Peerfive"
            src={CelebrateCapIcon}
            className={styles.celebrateCap}
          />
        </Typography>
        <Typography className={styles.boxTitle}>
          Let’s start recognizing and rewarding great work of your team
        </Typography>
      </div>

      <Divider />

      <ChannelConsent
        handleNextStep={handleNextStep}
        subscriptionPlans={subscriptionPlans}
      />
    </div>
  );
};

export default OnBoardingOneForm;
