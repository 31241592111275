import React from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import styles from "./AnalyticalDashboard.module.scss";
import NoUserFound from "../../assets/NoUserFound.svg";
import moment from "moment";

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  barThickness: 12,

  hoverBackgroundColor: "#4966FF",
  borderRadius: 4,
  tooltips: { enabled: true },
  scales: {
    x: {
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "(PeerFives) x (Time)",
        padding: 10,
      },
      ticks: {
        color: "#A0A0A0",
      },
    },
    y: {
      ticks: {
        color: "#A0A0A0",
      },
    },
  },
};

const RewardedPeerfives = (props) => {
  const { budgetReport } = props;
  const dataPoints = [];
  const months = [];
  budgetReport.report.spendingReport.map((item, i) => {
    dataPoints.push(item.points);
    months.push(moment(item._id.month, "MM").format("MMM"));
  });
  const data = {
    labels: months,
    datasets: [
      {
        data: dataPoints,
        backgroundColor: "#E3FFF7",
        borderColor: "#24B88B",
        borderWidth: 1,
      },
    ],
  };
  return (
    <Card
      {...props}
      // style={{ height: "100%" }}
      className={styles.commanSummeryContainer}
    >
      <div className={styles.headerContainer}>
        <Typography className={styles.heading}>Redeemed PeerFives</Typography>
      </div>
      {budgetReport.report.spendingReport.length <= 0 ? (
        <div className={styles.noDataDiv}>
          <img src={NoUserFound} alt="No Data Found" />
          <Typography className={styles.noData}>
            No Redeem Peerfives Found
          </Typography>
        </div>
      ) : (
        <Box className={styles.graphContainer}>
          <Bar data={data} options={options} />
        </Box>
      )}
    </Card>
  );
};

export default RewardedPeerfives;
