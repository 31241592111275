import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, useLocation } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { Tooltip, Typography } from "@material-ui/core";
import * as storageServices from "../../utils/services/storageServices";
import UserContext from "../../utils/context/UserContext";
import { ReactComponent as DropDownIcon } from "../../assets/DropDownUpIcon.svg";
import getPendingItemsObject from "../../helper/functions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  configerWidth: {
    width: "10rem",
    [theme.breakpoints.down("xs")]: {
      width: "141px",
      height: "auto",
      li: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "19.2px",
      },
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    margin: " 0px 10px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 0px",
    },
  },
  icon: {
    margin: "0 0 0 4px",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  button: {
    textTransform: "capitalize",
    padding: "12px",
  },
  active: {
    background: "#fffadf",
    textTransform: "capitalize",
    padding: "12px",
    borderRadius: "0px",
  },
  newMenuItem__menu: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  commanRespo__menu: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  activityFeed__menu: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  dropDownCon__menu: {
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      top: "6px",
      right: "-20px",
    },
  },
  profileBtn__menu: {
    [theme.breakpoints.down("xs")]: {
      width: "7px",
      minWidth: "35px",
      maxHeight: "35px",
      borderRadius: "24px",
    },
  },
  small_text: {
    marginRight: "4px",
    color: "#A0A0A0",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14px",
  },
  notification_dot: {
    height: "10.65px",
    width: "10.65px",
    borderRadius: "50%",
    backgroundColor: "#FFAD0D",
    marginLeft: "9px",
  },
  small_notification_dot: {
    display: "inline-block",
    height: "6.73px",
    width: "6.73px",
    borderRadius: "50%",
    backgroundColor: "#FFAD0D",
    marginLeft: "8px",
  },
  customTooltip: {
    top: "-20px",
    left: "12px",
    width: "117px",
    height: "33px",
    backgroundColor: "#E9E6DC",
    color: "black",
    fontSize: "14px",
    textAlign: "center",
    padding: "7px 10px 9px 10px",
    animation: "$bounce 3s infinite",
  },
  "@keyframes bounce": {
    "0%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(10px)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
  arrow: {
    color: "#E9E6DC",
  },
}));

const MenuComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const locationURL = location.pathname;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { user } = useContext(UserContext);
  const [profileData, setProfileData] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [pendingItems, setPendingItems] = useState({});
  const admin = user?.members?.peerfiveAdmin;
  // user?.members?.raw_data?.is_admin ||
  // user?.members?.peerfiveAdmin ||
  // user?.members?.raw_data?.is_owner;

  const getPendingItems = async () => {
    if (user.members.peerfiveAdmin) {
      const PendingItemsObject = getPendingItemsObject(user);
      setPendingItems(PendingItemsObject);
    }
  };

  useEffect(() => {
    if (user) {
      setProfileData(user);
      setIsAdmin(admin);
      getPendingItems();
    }
    // eslint-disable-next-line
  }, [user]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      {isAdmin ? (
        <Button
          className={
            locationURL === "/insights"
              ? `${classes.active} ${classes.commanRespo__menu} `
              : `${classes.button}  ${classes.commanRespo__menu} `
          }
          onClick={() => {
            history.push({
              pathname: "/insights",
            });
          }}
        >
          Culture Insights
        </Button>
      ) : null}
      <Tooltip
        id="menuTooltip"
        classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}
        title={pendingItems.isPendingItems && !open ? "Pending Items" : ""}
        arrow
        open
      >
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={
            locationURL === "/settings"
              ? `${classes.active} ${classes.profileBtn__menu} `
              : `${classes.button} ${classes.profileBtn__menu}`
          }
        >
          <Avatar
            alt="Remy Sharp"
            src={profileData?.members?.profile?.image_72}
            className={classes.small}
          />
          <span className={classes.icon}>My Profile </span>
          {pendingItems.isPendingItems && !open ? (
            <div className="notification_dot">
              <span />
              <span />
              <span />
            </div>
          ) : (
            <DropDownIcon
              className={`${classes.icon} ${classes.commanRespo__menu}`}
            />
          )}
        </Button>
      </Tooltip>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
            //
          >
            <Paper className={classes.dropDownCon__menu}>
              <ClickAwayListener onClickAway={handleClose}>
                {/* dropdown menu */}
                <MenuList
                  className={classes.configerWidth}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={(event) => {
                      handleClose(event);
                      history.push({
                        pathname: "/dashboard",
                      });
                    }}
                    className={classes.activityFeed__menu}
                  >
                    Activity Feeds
                  </MenuItem>
                  <Divider
                    variant="middle"
                    className={classes.activityFeed__menu}
                  />
                  <MenuItem
                    onClick={(event) => {
                      handleClose(event);
                      history.push({
                        pathname: "/settings",
                      });
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div>
                        Settings
                        {pendingItems.isPendingItems && (
                          <>
                            {" "}
                            <div className="small_notification_dot">
                              <span />
                              <span />
                              <span />
                            </div>
                            <Typography className={classes.small_text}>
                              Pending Items
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>
                  </MenuItem>
                  <Divider variant="middle" />
                  <MenuItem
                    onClick={(event) => {
                      handleClose(event);
                      storageServices.clearAll();
                      history.push({
                        pathname: "/login",
                      });
                    }}
                  >
                    Logout
                  </MenuItem>
                  <Divider variant="middle" />
                  <MenuItem>
                    <Typography className={classes.small_text}>
                      Version:
                    </Typography>
                    <Typography className={classes.small_text}>
                      2.0.4
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
export default MenuComponent;
