export const GET_ALL_ACTIVITY = "/members/activity";
export const LEADER_BOARD = "/members/leaderboard";
export const MEMBERS_ALL = "/members/all";
export const PROFILE = "/members/me";
export const MAKEADMIN = "/members/assignadminrole";
export const MEMBER_SPENDING_LIMIT = "/members/spendinglimit";
export const CHANNEL_LIST = "/teams/fetchChannels";
export const CHANNEL_UPDATE = "/teams/changeChannel";
export const CORE_VALUES = "/teams/coreValues";
export const SUBSCRIPTION_PLAN = "/subscription/plans";
export const SUBSCRIPTION = "/subscription";
export const TEAM_BUDGET = "/teams/budget";
export const SET_ONBOARDING_STATUS = "/teams/onBoardingState";
export const CREATE_SUBSCRIPTION = "/subscription/createSubscription";
export const CONFIRM_PAYMENT = "/subscription/confirmpaymentinfo";
export const UPDATE_GST = "/subscription/updategstinfo";
export const UPDATE_BILLING_DETAILS = "/subscription/billingdetails";
export const CANCEL_SUBSCRIPTION = "/subscription/cancelSubscription";
export const ACTIVATE_SUBSCRIPTION = "/subscription/renewsubscription";
export const UPDATE_CARD = "/subscription/updatecard";
export const TEAMS_MARKETPLACE = "/teams/marketplace";
export const TOP_EARNERS = "/teams/gettopcharts";
export const ENGAGEMENT_REPORT = "/teams/getengagementreport";
export const BUDGET_REPORT = "/teams/getbudgetreport";
export const CORE_VALUES_ANALYSIS = "/teams/getcorevalueanalysis";
export const TEAM_OVERVIEW = "/teams/teamOverview";
