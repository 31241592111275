import React, { Component } from "react";
import { withRouter } from "react-router";
import AdminProfile from "../pages/Profile/Profile";

export const MainContext = React.createContext({
  user: {},
});

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      user: {},
    };
  }

  componentDidMount() {}

  // componentWillMount() {
  //   this.getUser();
  // }

  // getUser = async () => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   let { data } = await ApiCall.getUser(user.id);
  //   if (data) this.setState({ user: data });
  // };

  render() {
    const { user } = this.state;
    return (
      <MainContext.Provider value={{ user }}>
        <AdminProfile />
      </MainContext.Provider>
    );
  }
}

export default withRouter(Profile);
