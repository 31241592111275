import React from "react";
import { Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./UserCard.module.scss";
import { useToaster } from "../../../../../../helper/ToasterContext";

const UserCard = (props) => {
  const Toaster = useToaster();
  const { data, isLimitLoading, profileId } = props;

  const handleSpendingLimit = (adminValue) => {
    props.setAdmin(data?.id, adminValue);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.content}>
        <img
          className={styles.userImage}
          src={data?.profile?.image_72}
          alt="User"
        />
        <Typography className={styles.userName}>
          {data?.profile?.real_name}
        </Typography>
      </div>

      <div className={styles.content}>
        {data?.id === profileId ? (
          data?.raw_data?.is_owner ? (
            <Typography className={styles.adminText}>Owner</Typography>
          ) : (
            <Typography className={styles.adminText}>Admin</Typography>
          )
        ) : data?.raw_data?.is_owner ? (
          <Typography className={styles.adminText}>Owner</Typography>
        ) : data?.peerfiveAdmin ? (
          isLimitLoading?.loading && isLimitLoading?.id === data.id ? (
            <div className="loader" style={{ marginLeft: "-30px" }}></div>
          ) : (
            <Button
              color="primary"
              className={styles.skipButton}
              onClick={() => {
                if (data?.raw_data?.is_owner)
                  Toaster.openSnackbar(
                    "You cannot remove admin privileges of workspace owner.",
                    "error"
                  );
                else handleSpendingLimit(false);
              }}
            >
              Remove Admin
            </Button>
          )
        ) : isLimitLoading.loading && isLimitLoading.id === data.id ? (
          <div className="loader" style={{ marginLeft: "-30px" }}></div>
        ) : (
          <Button
            variant="contained"
            className={styles.saveButton}
            onClick={() => {
              handleSpendingLimit(true);
            }}
          >
            Make Admin
          </Button>
        )}
      </div>
    </div>
  );
};

UserCard.propTypes = {
  data: PropTypes.shape({
    redeemablePoints: PropTypes.number,
    profile: PropTypes.shape({
      image_72: PropTypes.string,
      real_name: PropTypes.string,
    }),
  }),
};

UserCard.defaultProps = {
  data: {},
};

export default UserCard;
