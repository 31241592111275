import React, { useState, useEffect } from "react";
import { Typography, Button, Divider, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import { currencyFormatter } from "utils/services/utility";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./CurrentSubscription.module.scss";
import { ReactComponent as EditButton } from "../../../../assets/editButton.svg";
import NoSubscriptionLogo from "../../../../assets/subscription.svg";
import currentSubscription from "../../../../assets/currentSubscription.svg";
import noCard from "../../../../assets/noCard.svg";
import CB from "../../../../assets/Cards/CB.svg";
import AmericanExpress from "../../../../assets/Cards/AmericanExpress.svg";
import DinersClub from "../../../../assets/Cards/DinersClub.svg";
import Discover from "../../../../assets/Cards/Discover.svg";
import Interac from "../../../../assets/Cards/Interac.svg";
import JCB from "../../../../assets/Cards/JCB.svg";
import MasterCard from "../../../../assets/Cards/MasterCard.svg";
import Rupay from "../../../../assets/Cards/Rupay.svg";
import UnionPay from "../../../../assets/Cards/UnionPay.svg";
import VISA from "../../../../assets/Cards/VISA.svg";
import BillingDetails from "../../../../assets/BillingDetails.svg";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    width: "236px",
    height: "58px",
    backgroundColor: "#E9E6DC",
    color: "black",
    fontSize: "14px",
    textAlign: "center",
    padding: "12px 21px",
    top: "5px",
    left: "-50px",

    "@media (max-width: 959px)": {
      position: "absolute",
      top: "-80px",
      left: "-168px",
    },
    "@media (max-width: 599px)": {
      position: "absolute",
      top: "-90px",
      left: "-168px",
    },
  },

  arrow: {
    color: "#E9E6DC",
    left: "156px !important",
  },
}));

const CurrentSubscription = (props) => {
  const classes = useStyles();
  const {
    currentSubscriptionData,
    isSubscriptionActive,
    paymentCard,
    handleCardDetails,
    handleBillingDetails,
    handleCancelSubscription,
    handleActivateSubscription,
    pendingItems,
    tooltipStatus,
    latestCreditCardDetails,
  } = props;

  const getRemainingDays = (trialEnd) => {
    const endDate = moment(trialEnd);
    const todayDate = moment();
    return endDate.diff(todayDate, "days");
  };
  const [remaingDays, setRemaingDays] = useState(0);
  let cardIcon = noCard;
  if (latestCreditCardDetails?.card.brand === "visa") cardIcon = VISA;
  else if (latestCreditCardDetails?.card.brand === "mastercard")
    cardIcon = MasterCard;
  else if (latestCreditCardDetails?.card.brand === "discover")
    cardIcon = Discover;
  else if (latestCreditCardDetails?.card.brand === "diners")
    cardIcon = DinersClub;
  else if (latestCreditCardDetails?.card.brand === "amex")
    cardIcon = AmericanExpress;
  else if (latestCreditCardDetails?.card.brand === "jcb") cardIcon = JCB;
  else if (latestCreditCardDetails?.card.brand === "interac")
    cardIcon = Interac;
  else if (latestCreditCardDetails?.card.brand === "unionpay")
    cardIcon = UnionPay;
  else if (latestCreditCardDetails?.card.brand === "rupay") cardIcon = Rupay;
  else if (latestCreditCardDetails?.card.brand === "cb") cardIcon = CB;
  else if (paymentCard?.cardType === "visa") cardIcon = VISA;
  else if (paymentCard?.cardType === "mastercard") cardIcon = MasterCard;
  else if (paymentCard?.cardType === "discover") cardIcon = Discover;
  else if (paymentCard?.cardType === "diners") cardIcon = DinersClub;
  else if (paymentCard?.cardType === "amex") cardIcon = AmericanExpress;
  else if (paymentCard?.cardType === "jcb") cardIcon = JCB;
  else if (paymentCard?.cardType === "interac") cardIcon = Interac;
  else if (paymentCard?.cardType === "unionpay") cardIcon = UnionPay;
  else if (paymentCard?.cardType === "rupay") cardIcon = Rupay;
  else if (paymentCard?.cardType === "cb") cardIcon = CB;

  useEffect(() => {
    if (currentSubscriptionData?.planInfo) {
      setRemaingDays(
        getRemainingDays(
          moment
            .unix(
              currentSubscriptionData?.subscriptionList[0]?.current_period_end
            )
            .utc()
        )
      );
    }
  }, [currentSubscriptionData]);

  return (
    <>
      <div>
        <div className={styles.card}>
          <img
            src={
              currentSubscriptionData?.planInfo
                ? currentSubscription
                : NoSubscriptionLogo
            }
            className={styles.logo}
            alt="User"
          />
          <div>
            {currentSubscriptionData?.planInfo ? (
              <>
                <Typography className={styles.name}>
                  {currentSubscriptionData?.planInfo?.name} -{" "}
                  {currencyFormatter(
                    currentSubscriptionData?.planInfo?.amount,
                    currentSubscriptionData?.planInfo?.currency
                  )}
                  +GST
                  <span className={styles.normal}>
                    {currentSubscriptionData?.subscriptionList[0]?.status ===
                    "trialing"
                      ? "(Trial Period)"
                      : "canceled"
                      ? "(Subscription Cancelled)"
                      : "(Subscription Period)"}
                  </span>
                </Typography>
                <Typography className={styles.description}>
                  {currentSubscriptionData?.planInfo?.description}
                </Typography>
                <Typography
                  className={`${styles.infoBox} ${
                    remaingDays <= 5 &&
                    currentSubscriptionData?.subscriptionList[0]?.status !==
                      "canceled"
                      ? styles.alert
                      : currentSubscriptionData?.subscriptionList[0]?.status !==
                        "canceled"
                      ? styles.warning
                      : styles.canceled
                  }`}
                >
                  {currentSubscriptionData?.subscriptionList[0]?.status ===
                    "trialing" && isSubscriptionActive
                    ? "Trial "
                    : currentSubscriptionData?.subscriptionList[0]?.status ===
                        "trialing" && !isSubscriptionActive
                    ? "Subscription Cancelled & Trial "
                    : currentSubscriptionData?.subscriptionList[0]?.status ===
                        "canceled" && !isSubscriptionActive
                    ? "Subscription Cancelled"
                    : "Subscription "}
                  {remaingDays > 1 &&
                  currentSubscriptionData?.subscriptionList[0]?.status !==
                    "canceled"
                    ? `expires in next ${remaingDays} days`
                    : remaingDays === 1 &&
                      currentSubscriptionData?.subscriptionList[0]?.status !==
                        "canceled"
                    ? `expires in next ${remaingDays} day`
                    : remaingDays === 0 &&
                      currentSubscriptionData?.subscriptionList[0]?.status !==
                        "canceled"
                    ? "expires today"
                    : currentSubscriptionData?.subscriptionList[0]?.status !==
                      "canceled"
                    ? "expired"
                    : ""}
                </Typography>
                {isSubscriptionActive ? (
                  <Typography className={`${styles.description}`}>
                    Your card will be charged{" "}
                    <span className={styles.bold}>
                      {currencyFormatter(
                        currentSubscriptionData?.planInfo?.amount,
                        currentSubscriptionData?.planInfo?.currency
                      )}
                      +GST
                    </span>{" "}
                    from{" "}
                    {moment
                      .unix(
                        currentSubscriptionData?.subscriptionList[0]
                          ?.current_period_end
                      )
                      .utc()
                      .format("Do")}{" "}
                    of every month, until you cancel it.
                  </Typography>
                ) : null}
              </>
            ) : (
              <Typography className={styles.name}>
                Contact support..!!
              </Typography>
            )}
            {currentSubscriptionData?.planInfo ? (
              isSubscriptionActive &&
              currentSubscriptionData?.subscriptionList[0]?.status !==
                "canceled" ? (
                <Button
                  variant="outlined"
                  className={styles.viewPlan}
                  onClick={handleCancelSubscription}
                >
                  Cancel Subscription
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={styles.viewPlan}
                  onClick={handleActivateSubscription}
                >
                  Activate Subscription
                </Button>
              )
            ) : (
              <Button
                variant="outlined"
                className={styles.viewPlan}
                onClick={handleActivateSubscription}
              >
                Activate Subscription
              </Button>
            )}
          </div>
        </div>
        {/* } */}
        <Divider />
        <div className={styles.creditCardContent}>
          <div className={styles.viewDetail}>
            <img
              src={paymentCard?.cardImage ? paymentCard?.cardImage : cardIcon}
              className={styles.creditCardImage}
              alt="Credit Card"
            />
            {pendingItems.isPendingItems && pendingItems.paymentDetails ? (
              <div style={{ display: "inline-block" }}>
                <Typography className={styles.lightDescription}>
                  No credit card added{" "}
                  <div className="notification_dot">
                    <span />
                    <span />
                    <span />
                  </div>
                </Typography>
              </div>
            ) : (
              <Typography className={styles.description}>
                Card Ending with{" "}
                {latestCreditCardDetails
                  ? latestCreditCardDetails.card.last4
                  : paymentCard?.cardLast4Digit}
              </Typography>
            )}
          </div>
          <Tooltip
            id="subscriptionTooltip"
            classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}
            title={
              pendingItems.isPendingItems &&
              pendingItems.paymentDetails &&
              !tooltipStatus
                ? "Add Credit Card to Redeem points at the Marketplace"
                : ""
            }
            placement="top"
            open
            arrow
          >
            <EditButton
              className={styles.cursorPointer}
              onClick={handleCardDetails}
            />
          </Tooltip>
        </div>

        <Divider />
        <div className={styles.creditCardContent}>
          <div className={styles.viewDetail}>
            <img src={BillingDetails} className={styles.billingIcon} />
            <Typography className={styles.description}>Your Details</Typography>
          </div>
          <EditButton
            className={styles.cursorPointer}
            onClick={handleBillingDetails}
          />
        </div>
      </div>
    </>
  );
};

CurrentSubscription.propTypes = {
  currentSubscriptionData: PropTypes.node,
  paymentCard: PropTypes.node,
  handleCardDetails: PropTypes.func.isRequired,
  handleCancelSubscription: PropTypes.func.isRequired,
  handleActivateSubscription: PropTypes.func.isRequired,
};

CurrentSubscription.defaultProps = {
  currentSubscriptionData: {},
};

export default CurrentSubscription;
