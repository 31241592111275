export default {
    "100+": "https://emoji.slack-edge.com/T260V2K3P/100%252B/2718380196f8c339.gif",
    "1000": "https://emoji.slack-edge.com/T260V2K3P/1000/e83964a9875717f5.png",
    "aaw_yeah": "https://emoji.slack-edge.com/T260V2K3P/aaw_yeah/9319a5e09a021a37.gif",
    "afk": "https://emoji.slack-edge.com/T260V2K3P/afk/3236e6484517f29b.png",
    "ahhhhhhhhh": "https://emoji.slack-edge.com/T260V2K3P/ahhhhhhhhh/d1624431f51e7179.gif",
    "allo-happy": "https://emoji.slack-edge.com/T260V2K3P/allo-happy/9c8e88392ee68cd3.gif",
    "alphabet-white-a": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-a/f939af98d61a5106.png",
    "alphabet-white-at": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-at/f939af98d61a5106.png",
    "alphabet-white-b": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-b/f939af98d61a5106.png",
    "alphabet-white-c": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-c/f939af98d61a5106.png",
    "alphabet-white-d": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-d/f939af98d61a5106.png",
    "alphabet-white-e": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-e/f939af98d61a5106.png",
    "alphabet-white-exclamation": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-exclamation/f939af98d61a5106.png",
    "alphabet-white-f": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-f/f939af98d61a5106.png",
    "alphabet-white-g": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-g/f939af98d61a5106.png",
    "alphabet-white-h": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-h/f939af98d61a5106.png",
    "alphabet-white-hash": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-hash/f939af98d61a5106.png",
    "alphabet-white-i": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-i/f939af98d61a5106.png",
    "alphabet-white-j": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-j/f939af98d61a5106.png",
    "alphabet-white-k": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-k/f939af98d61a5106.png",
    "alphabet-white-l": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-l/f939af98d61a5106.png",
    "alphabet-white-m": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-m/f939af98d61a5106.png",
    "alphabet-white-n": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-n/f939af98d61a5106.png",
    "alphabet-white-o": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-o/f939af98d61a5106.png",
    "alphabet-white-p": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-p/f939af98d61a5106.png",
    "alphabet-white-q": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-q/f939af98d61a5106.png",
    "alphabet-white-question": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-question/f939af98d61a5106.png",
    "alphabet-white-r": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-r/f939af98d61a5106.png",
    "alphabet-white-s": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-s/f939af98d61a5106.png",
    "alphabet-white-t": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-t/f939af98d61a5106.png",
    "alphabet-white-u": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-u/f939af98d61a5106.png",
    "alphabet-white-v": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-v/f939af98d61a5106.png",
    "alphabet-white-w": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-w/f939af98d61a5106.png",
    "alphabet-white-x": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-x/f939af98d61a5106.png",
    "alphabet-white-y": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-y/f939af98d61a5106.png",
    "alphabet-white-z": "https://emoji.slack-edge.com/T260V2K3P/alphabet-white-z/f939af98d61a5106.png",
    "alphabet-yellow-a": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-a/f939af98d61a5106.png",
    "alphabet-yellow-at": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-at/f939af98d61a5106.png",
    "alphabet-yellow-b": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-b/f939af98d61a5106.png",
    "alphabet-yellow-c": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-c/f939af98d61a5106.png",
    "alphabet-yellow-d": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-d/f939af98d61a5106.png",
    "alphabet-yellow-e": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-e/f939af98d61a5106.png",
    "alphabet-yellow-exclamation": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-exclamation/f939af98d61a5106.png",
    "alphabet-yellow-f": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-f/f939af98d61a5106.png",
    "alphabet-yellow-g": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-g/f939af98d61a5106.png",
    "alphabet-yellow-h": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-h/f939af98d61a5106.png",
    "alphabet-yellow-hash": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-hash/f939af98d61a5106.png",
    "alphabet-yellow-i": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-i/f939af98d61a5106.png",
    "alphabet-yellow-j": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-j/f939af98d61a5106.png",
    "alphabet-yellow-k": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-k/f939af98d61a5106.png",
    "alphabet-yellow-l": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-l/f939af98d61a5106.png",
    "alphabet-yellow-m": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-m/f939af98d61a5106.png",
    "alphabet-yellow-n": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-n/f939af98d61a5106.png",
    "alphabet-yellow-o": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-o/f939af98d61a5106.png",
    "alphabet-yellow-p": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-p/f939af98d61a5106.png",
    "alphabet-yellow-q": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-q/f939af98d61a5106.png",
    "alphabet-yellow-question": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-question/f939af98d61a5106.png",
    "alphabet-yellow-r": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-r/f939af98d61a5106.png",
    "alphabet-yellow-s": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-s/f939af98d61a5106.png",
    "alphabet-yellow-t": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-t/f939af98d61a5106.png",
    "alphabet-yellow-u": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-u/f939af98d61a5106.png",
    "alphabet-yellow-v": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-v/f939af98d61a5106.png",
    "alphabet-yellow-w": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-w/f939af98d61a5106.png",
    "alphabet-yellow-x": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-x/f939af98d61a5106.png",
    "alphabet-yellow-y": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-y/f939af98d61a5106.png",
    "alphabet-yellow-z": "https://emoji.slack-edge.com/T260V2K3P/alphabet-yellow-z/f939af98d61a5106.png",
    "android": "https://emoji.slack-edge.com/T260V2K3P/android/171323421c301634.png",
    "angery": "https://emoji.slack-edge.com/T260V2K3P/angery/f7531eca383e75d5.gif",
    "avatar": "https://emoji.slack-edge.com/T260V2K3P/avatar/1fa3ed7f654bdfb8.png",
    "away": "https://emoji.slack-edge.com/T260V2K3P/away/5b12411c96c9b0f0.png",
    "awkward-monkey-look": "https://emoji.slack-edge.com/T260V2K3P/awkward-monkey-look/65b14c1e17b87b00.gif",
    "awsomeface": "https://emoji.slack-edge.com/T260V2K3P/awsomeface/06a3aa9de72a8b3a.gif",
    "baburao": "https://emoji.slack-edge.com/T260V2K3P/baburao/7712b8caeecc7a0f.png",
    "babyyoda": "https://emoji.slack-edge.com/T260V2K3P/babyyoda/d0140ed0cd559a72.png",
    "balance": "https://emoji.slack-edge.com/T260V2K3P/balance/5b12411c96c9b0f0.png",
    "bananadance_double": "https://emoji.slack-edge.com/T260V2K3P/bananadance_double/da629877f7fbbe8c.gif",
    "bananas": "https://emoji.slack-edge.com/T260V2K3P/bananas/dee56292bccf1cd0.gif",
    "beryl": "https://emoji.slack-edge.com/T260V2K3P/beryl/073473763f48e488.png",
    "black_panther": "https://emoji.slack-edge.com/T260V2K3P/black_panther/9b543b0b4f198712.png",
    "blah": "https://emoji.slack-edge.com/T260V2K3P/blah/458b9f95aaec84ea.gif",
    "blob-hearts": "https://emoji.slack-edge.com/T260V2K3P/blob-hearts/472f4ea7693e8a29.gif",
    "blush_face": "https://emoji.slack-edge.com/T260V2K3P/blush_face/61674115b9289f5b.gif",
    "brb": "https://emoji.slack-edge.com/T260V2K3P/brb/5b12411c96c9b0f0.png",
    "buff-doge": "https://emoji.slack-edge.com/T260V2K3P/buff-doge/fc620ebd6bbb844b.png",
    "bunny-slippers": "https://emoji.slack-edge.com/T260V2K3P/bunny-slippers/3236e6484517f29b.png",
    "captainamerica": "https://emoji.slack-edge.com/T260V2K3P/captainamerica/649c89295c4dabf7.png",
    "caption's_shield": "https://emoji.slack-edge.com/T260V2K3P/caption%2527s_shield/bfc1c549bfdf0167.png",
    "cat-on-keyboard": "https://emoji.slack-edge.com/T260V2K3P/cat-on-keyboard/5b12411c96c9b0f0.png",
    "chef-brb": "https://emoji.slack-edge.com/T260V2K3P/chef-brb/5b12411c96c9b0f0.png",
    "coming-soon": "https://emoji.slack-edge.com/T260V2K3P/coming-soon/b02f7bae8d1dd58f.gif",
    "commuting": "https://emoji.slack-edge.com/T260V2K3P/commuting/3236e6484517f29b.png",
    "confused-what": "https://emoji.slack-edge.com/T260V2K3P/confused-what/21397b91bc2a937f.jpg",
    "confused-whatt": "https://emoji.slack-edge.com/T260V2K3P/confused-whatt/96116bd16c0697db.png",
    "cool-doge": "https://emoji.slack-edge.com/T260V2K3P/cool-doge/45056981bee7e10c.gif",
    "dabbing": "https://emoji.slack-edge.com/T260V2K3P/dabbing/684ecc5031310a50.gif",
    "depressed": "https://emoji.slack-edge.com/T260V2K3P/depressed/e5a5f83df53f1b22.gif",
    "diu_sunrise_l_island": "https://emoji.slack-edge.com/T260V2K3P/diu_sunrise_l_island/008763b45e0eaa66.jpg",
    "dnd": "https://emoji.slack-edge.com/T260V2K3P/dnd/0d9f173658e1e931.png",
    "double-tick": "https://emoji.slack-edge.com/T260V2K3P/double-tick/3c51c9ae43ae2b85.png",
    "excuseme": "https://emoji.slack-edge.com/T260V2K3P/excuseme/f0944347af6131a6.gif",
    "facepalm": "https://emoji.slack-edge.com/T260V2K3P/facepalm/1940e070be9dee07.png",
    "fb-wow": "https://emoji.slack-edge.com/T260V2K3P/fb-wow/d318e50adfe83efd.gif",
    "fire_waving": "https://emoji.slack-edge.com/T260V2K3P/fire_waving/eea2f529f3b94e51.gif",
    "fixed": "https://emoji.slack-edge.com/T260V2K3P/fixed/ab402439417030f9.jpg",
    "flash": "https://emoji.slack-edge.com/T260V2K3P/flash/f637c4f11cb6dd95.png",
    "goal": "https://emoji.slack-edge.com/T260V2K3P/goal/8c3532d8b066d17d.png",
    "goodjob": "https://emoji.slack-edge.com/T260V2K3P/goodjob/c1a915c89ea71995.png",
    "gratitude-arigatou-gozaimasu": "https://emoji.slack-edge.com/T260V2K3P/gratitude-arigatou-gozaimasu/1d2abb7d6ebb8392.png",
    "gratitude-asl": "https://emoji.slack-edge.com/T260V2K3P/gratitude-asl/1d2abb7d6ebb8392.gif",
    "gratitude-danke": "https://emoji.slack-edge.com/T260V2K3P/gratitude-danke/1d2abb7d6ebb8392.png",
    "gratitude-gamsahamnida": "https://emoji.slack-edge.com/T260V2K3P/gratitude-gamsahamnida/1d2abb7d6ebb8392.png",
    "gratitude-gracias": "https://emoji.slack-edge.com/T260V2K3P/gratitude-gracias/1d2abb7d6ebb8392.png",
    "gratitude-grazie": "https://emoji.slack-edge.com/T260V2K3P/gratitude-grazie/1d2abb7d6ebb8392.png",
    "gratitude-merci": "https://emoji.slack-edge.com/T260V2K3P/gratitude-merci/1d2abb7d6ebb8392.png",
    "gratitude-obrigada": "https://emoji.slack-edge.com/T260V2K3P/gratitude-obrigada/1d2abb7d6ebb8392.png",
    "gratitude-obrigado": "https://emoji.slack-edge.com/T260V2K3P/gratitude-obrigado/1d2abb7d6ebb8392.png",
    "gratitude-spasibo": "https://emoji.slack-edge.com/T260V2K3P/gratitude-spasibo/1d2abb7d6ebb8392.png",
    "gratitude-thank-you": "https://emoji.slack-edge.com/T260V2K3P/gratitude-thank-you/1d2abb7d6ebb8392.png",
    "gratitude-xiexie": "https://emoji.slack-edge.com/T260V2K3P/gratitude-xiexie/1d2abb7d6ebb8392.png",
    "gratitude-xiexieni": "https://emoji.slack-edge.com/T260V2K3P/gratitude-xiexieni/1d2abb7d6ebb8392.png",
    "groot": "https://emoji.slack-edge.com/T260V2K3P/groot/e63bb2909bec06cf.png",
    "headbang": "https://emoji.slack-edge.com/T260V2K3P/headbang/e1c8fa1458ad3980.gif",
    "headbanging_parrot": "https://emoji.slack-edge.com/T260V2K3P/headbanging_parrot/bd842c04ddcf9ebc.gif",
    "heads-down": "https://emoji.slack-edge.com/T260V2K3P/heads-down/5b12411c96c9b0f0.png",
    "headslam": "https://emoji.slack-edge.com/T260V2K3P/headslam/3eb6986f35dd8c06.gif",
    "hello": "https://emoji.slack-edge.com/T260V2K3P/hello/fb6432509d07586a.png",
    "here": "https://emoji.slack-edge.com/T260V2K3P/here/5b12411c96c9b0f0.png",
    "holi": "https://emoji.slack-edge.com/T260V2K3P/holi/0ce3a90309c23f86.png",
    "homer-disappear": "https://emoji.slack-edge.com/T260V2K3P/homer-disappear/42aeac08f53f85f0.gif",
    "hot-desking": "https://emoji.slack-edge.com/T260V2K3P/hot-desking/3236e6484517f29b.png",
    "image": "https://emoji.slack-edge.com/T260V2K3P/image/025bdc5b09be5a82.png",
    "internet-problems": "https://emoji.slack-edge.com/T260V2K3P/internet-problems/5b12411c96c9b0f0.png",
    "ironman": "https://emoji.slack-edge.com/T260V2K3P/ironman/0af90756658fecbd.png",
    "i_like_you": "https://emoji.slack-edge.com/T260V2K3P/i_like_you/3eba4a2d82f37a83.gif",
    "keanu-thanks": "https://emoji.slack-edge.com/T260V2K3P/keanu-thanks/3b95a5708aedcc43.gif",
    "khatri": "https://emoji.slack-edge.com/T260V2K3P/khatri/cb7a07349a47ab20.png",
    "kid-drop-off": "https://emoji.slack-edge.com/T260V2K3P/kid-drop-off/3236e6484517f29b.png",
    "kids": "https://emoji.slack-edge.com/T260V2K3P/kids/5b12411c96c9b0f0.png",
    "ks": "https://emoji.slack-edge.com/T260V2K3P/ks/e3666b20428691c1.png",
    "maggi": "https://emoji.slack-edge.com/T260V2K3P/maggi/c7ac17d0977a7595.png",
    "matrix": "https://emoji.slack-edge.com/T260V2K3P/matrix/6272e5069e1e560b.gif",
    "matrix-1": "https://emoji.slack-edge.com/T260V2K3P/matrix-1/5acdc75c323b2855.gif",
    "merge": "https://emoji.slack-edge.com/T260V2K3P/merge/589df55a3c8fda30.png",
    "metal": "https://emoji.slack-edge.com/T260V2K3P/metal/073473763f48e488.png",
    "mobilize": "https://emoji.slack-edge.com/T260V2K3P/mobilize/c43f816727c8fa8a.png",
    "nailed_it": "https://emoji.slack-edge.com/T260V2K3P/nailed_it/ab6a2718a3d5c892.gif",
    "nice-cat": "https://emoji.slack-edge.com/T260V2K3P/nice-cat/fd3f892fdc093f88.jpg",
    "no-way": "https://emoji.slack-edge.com/T260V2K3P/no-way/abd4e1322dde7a75.png",
    "noice": "https://emoji.slack-edge.com/T260V2K3P/noice/7461172d79896798.gif",
    "notinterested": "https://emoji.slack-edge.com/T260V2K3P/notinterested/23f074c4cb1cec65.gif",
    "on-my-way": "https://emoji.slack-edge.com/T260V2K3P/on-my-way/2f90e94a820d839d.gif",
    "one-sec-cooking": "https://emoji.slack-edge.com/T260V2K3P/one-sec-cooking/5b12411c96c9b0f0.png",
    "ooo": "https://emoji.slack-edge.com/T260V2K3P/ooo/3236e6484517f29b.png",
    "party-blob": "https://emoji.slack-edge.com/T260V2K3P/party-blob/b95229a722a16185.gif",
    "party-parrot": "https://emoji.slack-edge.com/T260V2K3P/party-parrot/f4193114d5b51396.gif",
    "peerfives": "https://emoji.slack-edge.com/T260V2K3P/peerfives/da4db30c4259d52a.png",
    "perfect": "https://emoji.slack-edge.com/T260V2K3P/perfect/f7551827b34a06a5.gif",
    "pig-hello-door": "https://emoji.slack-edge.com/T260V2K3P/pig-hello-door/004407e967ec962d.gif",
    "plus-one": "https://emoji.slack-edge.com/T260V2K3P/plus-one/5b0418c87ce45f68.png",
    "plus1": "https://emoji.slack-edge.com/T260V2K3P/plus1/0221d12b14573d82.png",
    "pto": "https://emoji.slack-edge.com/T260V2K3P/pto/3236e6484517f29b.png",
    "r2d2": "https://emoji.slack-edge.com/T260V2K3P/r2d2/3ca57fe5a6dbf7dc.png",
    "raja": "https://emoji.slack-edge.com/T260V2K3P/raja/3f08dac9d047b851.png",
    "reactjs": "https://emoji.slack-edge.com/T260V2K3P/reactjs/3986ead544b94ab7.png",
    "remote": "https://emoji.slack-edge.com/T260V2K3P/remote/12f00227334479dd.jpg",
    "rickroll": "https://emoji.slack-edge.com/T260V2K3P/rickroll/98d4d0010cd7f976.gif",
    "rushang": "https://emoji.slack-edge.com/T260V2K3P/rushang/f5563bcc6d7afaf6.png",
    "s-a": "https://emoji.slack-edge.com/T260V2K3P/s-a/9d445ab98b5039f5.gif",
    "s-e": "https://emoji.slack-edge.com/T260V2K3P/s-e/8c947e3a0ffa453c.gif",
    "s-i": "https://emoji.slack-edge.com/T260V2K3P/s-i/7332198419180f35.gif",
    "s-l": "https://emoji.slack-edge.com/T260V2K3P/s-l/4b1694893cc5e4a3.gif",
    "s-o": "https://emoji.slack-edge.com/T260V2K3P/s-o/d751f022cc4ca697.gif",
    "s-r": "https://emoji.slack-edge.com/T260V2K3P/s-r/a61dce487f8c7dbd.gif",
    "s-s": "https://emoji.slack-edge.com/T260V2K3P/s-s/1ef2c0db672866c4.gif",
    "s-t": "https://emoji.slack-edge.com/T260V2K3P/s-t/c6f5bc3223b4260d.gif",
    "s-v": "https://emoji.slack-edge.com/T260V2K3P/s-v/531d7675751a3298.gif",
    "self-care": "https://emoji.slack-edge.com/T260V2K3P/self-care/05de51c2579adbf1.png",
    "shhh": "https://emoji.slack-edge.com/T260V2K3P/shhh/d4350f5317bee956.gif",
    "shiv": "https://emoji.slack-edge.com/T260V2K3P/shiv/04ecbc62b45de285.jpg",
    "shree": "https://emoji.slack-edge.com/T260V2K3P/shree/970dd9d7f4f12820.png",
    "smart-move": "https://emoji.slack-edge.com/T260V2K3P/smart-move/fabc2cfa8ca3b0a4.gif",
    "solvative": "https://emoji.slack-edge.com/T260V2K3P/solvative/79abc4ca612541c0.png",
    "space-float": "https://emoji.slack-edge.com/T260V2K3P/space-float/caa9c59e133433ce.gif",
    "stonks": "https://emoji.slack-edge.com/T260V2K3P/stonks/83e149bc08a804a0.png",
    "stop-sign": "https://emoji.slack-edge.com/T260V2K3P/stop-sign/04eb3851272660d9.png",
    "success": "https://emoji.slack-edge.com/T260V2K3P/success/46eb7f1b3e0aa92b.png",
    "surprised-pikachu": "https://emoji.slack-edge.com/T260V2K3P/surprised-pikachu/b934feae4280599e.png",
    "surprised_face": "https://emoji.slack-edge.com/T260V2K3P/surprised_face/d088c8259f0d0b38.gif",
    "thinking": "https://emoji.slack-edge.com/T260V2K3P/thinking/32302ba7475808c1.gif",
    "thinking-hard": "https://emoji.slack-edge.com/T260V2K3P/thinking-hard/fcd10ec8affb9b9c.gif",
    "thor": "https://emoji.slack-edge.com/T260V2K3P/thor/7bddebf0a3dc137e.png",
    "thumps-down": "https://emoji.slack-edge.com/T260V2K3P/thumps-down/a5ee3335e73e6869.gif",
    "timapple": "https://emoji.slack-edge.com/T260V2K3P/timapple/bf2855bc7fd8cc23.png",
    "time-zone-fyi": "https://emoji.slack-edge.com/T260V2K3P/time-zone-fyi/05de51c2579adbf1.png",
    "tongue-out": "https://emoji.slack-edge.com/T260V2K3P/tongue-out/66edf84bc471cd3e.gif",
    "typing-cat": "https://emoji.slack-edge.com/T260V2K3P/typing-cat/85001e209673fd61.gif",
    "typing-jim-carrey": "https://emoji.slack-edge.com/T260V2K3P/typing-jim-carrey/54119589548fb92b.gif",
    "uno-reverse": "https://emoji.slack-edge.com/T260V2K3P/uno-reverse/348a5aecb9e8d1c7.png",
    "ur-the-best": "https://emoji.slack-edge.com/T260V2K3P/ur-the-best/da419b44686cc5a0.gif",
    "vaccines": "https://emoji.slack-edge.com/T260V2K3P/vaccines/cb9dd11b6026c11c.jpg",
    "venting": "https://emoji.slack-edge.com/T260V2K3P/venting/9542a3027c2ecfa4.gif",
    "virtual-meeting": "https://emoji.slack-edge.com/T260V2K3P/virtual-meeting/5b12411c96c9b0f0.png",
    "walking-the-dog": "https://emoji.slack-edge.com/T260V2K3P/walking-the-dog/5b12411c96c9b0f0.png",
    "watching-you": "https://emoji.slack-edge.com/T260V2K3P/watching-you/3d92d0ac21ebfed4.gif",
    "wave-pikachu": "https://emoji.slack-edge.com/T260V2K3P/wave-pikachu/28426991c31288a7.gif",
    "waving-from-afar-left": "https://emoji.slack-edge.com/T260V2K3P/waving-from-afar-left/5b12411c96c9b0f0.png",
    "waving-from-afar-right": "https://emoji.slack-edge.com/T260V2K3P/waving-from-afar-right/5b12411c96c9b0f0.png",
    "why-why-why": "https://emoji.slack-edge.com/T260V2K3P/why-why-why/cf72bf3003fa45df.gif",
    "wordpress": "https://emoji.slack-edge.com/T260V2K3P/wordpress/79e07d0296852e2a.gif",
    "working-from-coffee-shop": "https://emoji.slack-edge.com/T260V2K3P/working-from-coffee-shop/05de51c2579adbf1.png",
    "working-from-home": "https://emoji.slack-edge.com/T260V2K3P/working-from-home/05de51c2579adbf1.png",
    "working-in-office": "https://emoji.slack-edge.com/T260V2K3P/working-in-office/05de51c2579adbf1.png",
    "yeaaaa": "https://emoji.slack-edge.com/T260V2K3P/yeaaaa/eb43f5cada38ac2a.jpg",
    "yogi": "https://emoji.slack-edge.com/T260V2K3P/yogi/24c4cc816e83dab1.png",
    "you-know": "https://emoji.slack-edge.com/T260V2K3P/you-know/ee174959a8fb43df.png",
    "youre_the_best": "https://emoji.slack-edge.com/T260V2K3P/youre_the_best/5d3a7504c1338858.gif",
    "yuck": "https://emoji.slack-edge.com/T260V2K3P/yuck/07fcb134c76808c8.gif",
}