import { Divider, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import slackImage from "../../../../assets/slack.svg";
import MSTeamsIcon from "../../../../assets/MSTeamsIcon.svg";
import styles from "./MainCard.module.scss";

const MainCard = (props) => {
  const { title, children, plateformType } = props;

  return (
    <div className={styles.card}>
      <div className={styles.header__card}>
        {title && (
          <Typography className={styles.headerTitle}>{title}</Typography>
        )}
        {title === "Company" && plateformType === "slack" && (
          <div className={styles.integrationCont__card}>
            <img src={slackImage} alt="slack image" />
            <Typography className={styles.integrationText__card}>
              Integrated with Slack
            </Typography>
          </div>
        )}
        {title === "Company" && plateformType === "teams" && (
          <div className={styles.integrationCont__card}>
            <img src={MSTeamsIcon} alt="slack image" />
            <Typography className={styles.integrationText__card}>
              Integrated with MS Teams
            </Typography>
          </div>
        )}
      </div>
      <Divider className={styles.cardDivider__MainCard} />
      <div className={styles.cardContent}>{children}</div>
    </div>
  );
};

MainCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

MainCard.defaultProps = {
  children: {},
  title: "",
};

export default MainCard;
