import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OnBoardingOneForm from "./OnBoardingOneForm";
import styles from "./OnBoardingOne.module.scss";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const OnBoardingOne = (props) => {
  const { subscriptionPlans, handleNextStep } = props;

  return (
    <Elements stripe={stripePromise}>
      <div className={styles.StripeFormWrapper}>
        <OnBoardingOneForm
          subscriptionPlans={subscriptionPlans}
          handleNextStep={handleNextStep}
        />
      </div>
    </Elements>
  );
};

export default OnBoardingOne;
