import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from './UserCard.module.scss';

const UserCardSkeleton = () => {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.content}>
                <Skeleton animation="wave" variant="circle" className={styles.userImage} />
                <Skeleton className={styles.userName} width={150} animation="wave" />
            </div>

            <div className={styles.content}>
                <Skeleton className={`${styles.iconBox}`} width={40} animation="wave" />
                <Skeleton className={styles.iconBox} width={40} animation="wave" />
            </div>
        </div>
    )

}

export default UserCardSkeleton
