import React, { useContext, useState, useEffect } from "react";
import styles from "./TourVideo.module.scss";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import UserContext from "utils/context/UserContext";
import PeerFiveBuddy from "../../../../assets/icons/PeerFiveBuddy.svg";
import YellowBG from "../../../../assets/icons/YellowBG.svg";
import YouTube from "react-youtube";

const TourVideo = () => {
  const useStyles = makeStyles(() => ({
    slackButton: {
      padding: "10px",
      background: "#000",
      color: "#FFF",

      "&:hover": {
        background: "#FFF",
        color: "#000",
      },
    },
  }));
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [showButton, setShowButton] = useState(false);

  const handleClick = () => {
    if (user?.members?.platform === "slack") {
      window.location.href = `slack://${user.members.team_id}`;
    } else if (user?.members?.platform === "teams") {
      window.location.href = `msteams://teams.microsoft.com`;
    }
  };
  function getYouTubeVideoId(url) {
    const videoIdRegex = /[?&]v=([^&]+)/;
    const match = url.match(videoIdRegex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  const videoId = getYouTubeVideoId(
    user?.members?.platform === "slack"
      ? "https://www.youtube.com/watch?v=eqq4SulKN_w"
      : "https://www.youtube.com/watch?v=dPonR7zD8qo"
  );

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: { rel: 0, showinfo: 0, ecver: 2 },
  };

  const handleEnd = () => {
    setShowButton(true);
  };

  const handleReady = () => {
    setLoading(false);
  };
  console.log(loading);
  return (
    <>
      {loading && (
        <div className="flash-loader">
          <img
            src={YellowBG}
            className="logoSquare spinLoader"
            alt="PeerFives"
          />
          <img
            src={PeerFiveBuddy}
            style={{ position: "absolute" }}
            alt="PeerFives"
          />
        </div>
      )}

      <div style={{ height: "100%", width: "100%", marginRight: "1rem" }}>
        <div className={styles.playerWrapper}>
          <YouTube
            videoId={videoId}
            opts={opts}
            onEnd={handleEnd}
            onReady={handleReady}
            style={{ marginBottom: "1rem", marginRight: "1rem" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {showButton && (
              <Button
                className={`${classes.slackButton}
             ${showButton} && ${styles.fadeIn}`}
                variant="outlined"
                onClick={handleClick}
              >
                {user.members.platform === "slack"
                  ? "Take Me To Slack"
                  : user.members.platform === "teams"
                  ? "Take Me To Teams"
                  : ""}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TourVideo;
