import React, { useState, useMemo } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { Button, Divider, Typography } from "@material-ui/core";
import { ReactComponent as StripeLogo } from "../../assets/stripeLogo.svg";
import { ReactComponent as WarningLogo } from "../../assets/warningLogo.svg";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import { useToaster } from "helper/ToasterContext";
import styles from "./CardDetails.module.scss";
import "./StripeField.scss";

const useOptions = () => {
  const options = useMemo(
    () => ({
      base: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#000000",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#ffffff",
        },
      },
      style: {
        invalid: {
          color: "#E93A3A",
        },
      },
    }),
    []
  );
  return options;
};

const CardDetails = (props) => {
  const {
    from,
    setOnboardingStatus,
    handleSkip,
    handleActivateScreen,
    setLatestCreditCardDetails,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSkip, setIsLoadingSkip] = useState(false);
  const Toaster = useToaster();
  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    expiryDate: false,
    cvv: false,
  });
  const skipSaveCard = (params) => {
    setOnboardingStatus({ step: params ? "save" : "" }, undefined);
  };

  const confirmPaymentInfo = async (params) => {
    setIsLoading(true);
    try {
      const { data } = await apiServices.patch(
        apiConstant.CONFIRM_PAYMENT,
        params
      );
      if (data.status) {
        Toaster.openSnackbar(data.message);
        if (from === "onboarding")
          setOnboardingStatus({ step: params ? "save" : "" }, undefined);
        else if (from === "activateDialog") handleActivateScreen();
        else handleSkip();
        if (data.next_action) {
          if (data.next_action.redirect_to_url)
            window.location.href = data?.next_action?.redirect_to_url?.url;
        }
      } else {
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      Toaster.openSnackbar(
        error?.response?.data?.message
          ? error.response.data.message
          : "Something went wrong!",
        "error"
      );
      setIsLoading(false);
    }
  };

  const customHandler = async () => {
    if (!stripe || !elements) {
      return;
    }
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (payload.error) {
      setError(payload.error.message);
      Toaster.openSnackbar(
        payload?.error?.message ? payload.error.message : "Card Not supported!",
        "error"
      );
      if (from === "activateDialog") handleActivateScreen();
      else handleSkip();
    } else {
      if (payload.paymentMethod.card.funding === "credit") {
        confirmPaymentInfo({
          paymentInfo: payload?.paymentMethod,
        });
        setLatestCreditCardDetails(payload?.paymentMethod);
        if (from === "onboarding") {
          setIsLoadingSkip(true);
        }
      } else Toaster.openSnackbar("Please enter credit card!", "error");
    }
  };

  const ErrorMessage = ({ children }) => (
    <div className={styles.errorMessage} role="alert">
      <svg width="16" height="16" viewBox="0 0 17 17">
        <path
          fill="#ffffff"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        />
        <path
          fill="#A0A0A0"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        />
      </svg>
      {children}
    </div>
  );

  return (
    <div>
      <form className={styles.StripeForm}>
        <div className={styles.InlineLabel}>
          <label>
            Card number
            <CardNumberElement
              options={options}
              onChange={(event) => {
                setError(event?.error?.message);
                setCardComplete({
                  ...cardComplete,
                  cardNumber: event.complete,
                });
              }}
            />
          </label>
        </div>
        <div className={styles.StripeFormFields}>
          <label style={{ marginRight: "24px" }}>
            Expiry date(MM/YY)
            <CardExpiryElement
              options={options}
              onChange={(event) => {
                setError(event?.error?.message);
                setCardComplete({
                  ...cardComplete,
                  expiryDate: event.complete,
                });
              }}
            />
          </label>

          <label>
            CVC
            <CardCvcElement
              options={options}
              onChange={(event) => {
                setError(event?.error?.message);
                setCardComplete({ ...cardComplete, cvv: event.complete });
              }}
            />
          </label>
        </div>
      </form>
      {error && <ErrorMessage>{error}</ErrorMessage>}

      <div className={styles.footer}>
        {from === "onboarding" ? (
          <>
            <Button
              color="primary"
              disabled={isLoading}
              className={styles.skipButton}
              onClick={() => skipSaveCard()}
            >
              {isLoadingSkip ? <div className="loader" /> : "Skip For Now"}
            </Button>
            <Button
              type="submit"
              disabled={
                !(
                  cardComplete.cardNumber &&
                  cardComplete.expiryDate &&
                  cardComplete.cvv
                )
              }
              variant="contained"
              className={styles.saveButton}
              onClick={customHandler}
            >
              {isLoading ? <div className="loader" /> : "Save & Next"}
            </Button>
          </>
        ) : from === "activateDialog" ? (
          <>
            <Button
              color="primary"
              className={styles.skipButton}
              onClick={handleActivateScreen}
            >
              Go Back
            </Button>
            <Button
              type="submit"
              disabled={
                !(
                  cardComplete.cardNumber &&
                  cardComplete.expiryDate &&
                  cardComplete.cvv
                )
              }
              variant="contained"
              className={styles.saveButton}
              onClick={customHandler}
            >
              {isLoading ? <div className="loader" /> : "Save"}
            </Button>
          </>
        ) : (
          <Button
            type="submit"
            disabled={
              !(
                cardComplete.cardNumber &&
                cardComplete.expiryDate &&
                cardComplete.cvv
              )
            }
            variant="contained"
            className={styles.saveButton}
            onClick={customHandler}
          >
            {isLoading ? <div className="loader" /> : "Save "}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CardDetails;
