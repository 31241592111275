import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import styles from "./BudgetDialog.module.scss";
import PeerfiveIcon from "../../../../assets/PeerFiveAdminScreen.svg";
import iconClose from "../../../../assets/icon_close.svg";
import NoUserFound from "../../../../assets/NoUserFound.svg";
import CustomizedSelects from "../../../../components/CustomizedSelects/CustomizedSelects";
import UserCard from "./components/UserCard/UserCard";
import UserCardSkeleton from "./components/UserCard/UserCardSkeleton";
import SearchBox from "../../../../components/SearchBox/SearchBox";
import UserContext from "../../../../utils/context/UserContext";
import * as apiServices from "../../../../utils/services/apiServices";
import * as apiConstant from "../../../../utils/constant/apiConstant";
import { generateFakeArray } from "../../../../utils/services/utility";
import { useToaster } from "../../../../helper/ToasterContext";
import { KeyboardArrowDown } from "@material-ui/icons";
import storeShirt from "../../../../assets/storeShirt.svg";
import smallPeerFivesLogo from "../../../../assets/smallPeerFivesLogo.svg";
import DialogModel from "components/DialogModel/DialogModel";
import CardDetailsDialog from "../CardDetailsDialog/CardDetailsDialog";

const ToggleSwitch = withStyles({
  switchBase: {
    color: "#EDE9D1",
    "&$checked": {
      color: "#FFC50D",
    },
    "&$checked + $track": {
      backgroundColor: "#EDE9D1",
    },
  },
  checked: {},
  track: {},
})(Switch);
const BudgetDialog = (props) => {
  const Toaster = useToaster();
  const { user, setUser } = useContext(UserContext);
  const [isLimitLoading, setIsLimitLoading] = useState({ loading: false });
  const [isBudgetLoading, setIsBudgetLoading] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [filterMembersList, setFilterMembersList] = useState([]);
  const [fakemembersList] = useState(generateFakeArray);
  const [membersLoading, setMembersLoading] = useState(false);
  const [currency, setCurrency] = useState("INR");
  const [recommendedPointToggle, setRecommendedPointToggle] = useState(
    user.team.isEqualPointsDistributed
  );
  const [pointWeightage, setPointWeightage] = useState("");
  const [monthlyBudget, setMonthlyBudget] = useState("");
  const [totalEmployees, setTotalEmployees] = useState();
  const [availableBalance, setAvailableBalance] = useState();
  const [totalAssignedPoints, setTotalAssignedPoints] = useState();
  const [totalBalanceFinal, setTotalBalanceFinal] = useState();
  const [recommendedPoint, setRecommendedPoint] = useState(
    Math.floor(
      user.team.teamBudget /
        user.team.pointsConversionRate /
        user.team.totalMembers
    )
  );

  const [budgetDropDown, setBudgetDropDown] = useState(false);
  const [balance, setBalance] = useState(false);

  const [open, setOpen] = useState({
    open: false,
    message: " ",
    messageHeader: " ",
    type: "budget",
    id: "",
    params: "",
  });

  const [cardDetailsDialog, setCardDetailsDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });

  const getProfileData = async () => {
    try {
      const { data } = await apiServices.get(apiConstant.PROFILE);
      if (data) {
        setUser(data);
      }
    } catch {
      console.log("error");
    }
  };

  const openCardDetailsModal = () => {
    setCardDetailsDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };

  const closeCardDetailsDialog = (params) => {
    if (params) {
      getProfileData();
    }
    setCardDetailsDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };

  const handleClickOpen = (
    message,
    messageHeader,
    type,
    id = "",
    params = ""
  ) => {
    setOpen({
      open: true,
      message: message,
      messageHeader: messageHeader,
      type: type,
      id: id,
      params: params,
    });
  };

  const handleClose = () => {
    if (open.type === "limit") {
      getMembersList();
      setOpen({ open: false });
    } else if (open.type === "toAll") {
      setRecommendedPointToggle(false);
      setOpen({ open: false });
    }
    setOpen({ open: false });
  };
  const handleAgreeClose = () => {
    if (open.type === "budget") {
      setOpen({ open: false });
      setRecommendedPointToggle(true);
      setBudgetApiCalling({
        pointsConversion: pointWeightage,
        budget: monthlyBudget,
        defaultCurrency: currency,
        assignAll: true,
        isValidate: false,
      });
    } else if (open.type === "limit") {
      setSpendingLimitApiCalling(open.id, {
        spendingLimit: open.params.spendingLimit,
        isValidate: false,
      });
      setOpen({ open: false });
    } else if (open.type === "toAll") {
      setRecommendedPointToggle(true);
      setBudget();
      setOpen({ open: false });
    }
  };

  const getMembersList = async () => {
    setMembersLoading(true);
    try {
      const { data } = await apiServices.get(apiConstant.MEMBERS_ALL);
      if (data) {
        setFilterMembersList(data);
        setMembersList(data);
      }
      setMembersLoading(false);
    } catch {
      setMembersLoading(false);
    }
  };

  const setSpendingLimit = (id, inputPoints) => {
    setSpendingLimitApiCalling(id, {
      spendingLimit: inputPoints,
      isValidate: true,
    });
  };
  const setSpendingLimitApiCalling = async (id, params) => {
    setIsLimitLoading({ loading: true, id: id });
    try {
      const { data } = await apiServices.patch(
        `/members/setlimit/${id}`,
        params
      );
      if (data.status) {
        setAvailableBalance(data.team.availablePeerfivesBalance);
        setRecommendedPointToggle(data.team.isEqualPointsDistributed);
        setTotalAssignedPoints(data.team.totalPeerfivePoints);
        setTotalBalanceFinal(data.team.totalPeerfivePoints);
        setRecommendedPoint(Math.floor(totalBalanceFinal / totalEmployees));
        setMonthlyBudget(data.team.teamBudget);

        Toaster.openSnackbar(data.message);
      } else {
        if (data.code === 201) {
          handleClickOpen(
            data.message,
            "Are you sure you want to change Spending Limit?",
            "limit",
            id,
            params
          );
        } else {
          Toaster.openSnackbar(
            data?.message ? data.message : "No available PeerFives!",
            "error"
          );
        }
      }
      setIsLimitLoading({ loading: false });
    } catch {
      setIsLimitLoading({ loading: false });
    }
  };
  const setBudget = async () => {
    if (
      monthlyBudget &&
      pointWeightage &&
      pointWeightage > 0 &&
      monthlyBudget > 0
    ) {
      if (monthlyBudget > pointWeightage) {
        if (Number.isInteger(monthlyBudget)) {
          setBudgetApiCalling({
            pointsConversion: pointWeightage,
            budget: monthlyBudget,
            defaultCurrency: currency,
            assignAll: false,
            isValidate: true,
          });
        } else Toaster.openSnackbar(" Budget Value should be Integer", "error");
      } else
        Toaster.openSnackbar(
          " Budget Value should be greater than Peerfive Weightage",
          "error"
        );
    } else {
      Toaster.openSnackbar(" Please enter valid Input", "error");
    }
  };
  const setBudgetApiCalling = async (params) => {
    setIsBudgetLoading(true);
    try {
      const { data } = await apiServices.patch(apiConstant.TEAM_BUDGET, params);
      if (data.status) {
        setMonthlyBudget(data.team.budget);
        setTotalAssignedPoints(data.team.totalPeerfivePoints);
        setTotalBalanceFinal(data.team.totalPeerfivePoints);
        setAvailableBalance(data.team.availableBalance);
        setRecommendedPoint(
          Math.floor(data.team.totalPeerfivePoints / totalEmployees)
        );
        setRecommendedPointToggle(data.team.isEqualPointsDistributed);
        getMembersList();
        Toaster.openSnackbar(data.message);
        closeDialog();
        if (!user.team.pointsConversionRate) props.profileData();
      } else {
        if (data.code === 201) {
          handleClickOpen(
            data.message,
            "Are you sure you want to change budget?",
            "budget"
          );
          getMembersList();
        } else {
          Toaster.openSnackbar(
            data?.message ? data.message : "Something went wrong!",
            "error"
          );
        }
      }
      setIsBudgetLoading(false);
    } catch {
      setIsBudgetLoading(false);
    }
  };

  const handleBudgetDropDown = () => {
    setBudgetDropDown(!budgetDropDown);
    if (balance) {
      setBalance(false);
    }
  };

  const handleCompBalanceDropDown = () => {
    setBalance(!balance);
    if (budgetDropDown) {
      setBudgetDropDown(false);
    }
  };

  useEffect(() => {
    getMembersList();
  }, []);
  useEffect(() => {
    if (user) {
      setMonthlyBudget(user.team.teamBudget);
      setCurrency(user.team.defaultCurrency);
      setPointWeightage(user.team.pointsConversionRate);
      setAvailableBalance(user.team.availablePeerfivesBalance);
      setTotalEmployees(user.team.totalMembers);
      setTotalAssignedPoints(user.team.totalPeerfivePoints);
      // if (user.team.teamBudget === 0 || user.team.pointsConversionRate === 0) {
      //   setTotalAssignedPoints(0);
      //   setTotalBalanceFinal(0);
      //   setRecommendedPoint(0);
      // } else {
      //   setTotalAssignedPoints(
      //     Math.floor(user.team.teamBudget / user.team.pointsConversionRate) -
      //       user.team.availablePeerfivesBalance
      //   );
      //   setTotalBalanceFinal(
      //     Math.floor(user.team.teamBudget / user.team.pointsConversionRate)
      //   );
      //   setRecommendedPoint(
      //     Math.floor(
      //       user.team.teamBudget /
      //         user.team.pointsConversionRate /
      //         user.team.totalMembers
      //     )
      //   );
      // }
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setMonthlyBudget(totalAssignedPoints * pointWeightage);
  }, [pointWeightage, totalAssignedPoints]);
  const doFilter = (query) => {
    setFilterMembersList(
      membersList.filter((data) =>
        data?.profile?.real_name?.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  const closeDialog = () => {
    props.onClose(true);
    // props.budgetDialogProfile && props.onClose(true);
    // props.budgetBtn && props.setBudgetBtn(false);
    // props.budget === 0
    //   ? props.setDisplaySec("secSec")
    //   : props.setDisplaySec("thirdSec");
  };

  const handleRecommendedPointChange = (event) => {
    if (event.target.checked === true) {
      setRecommendedPointToggle(event.target.checked);
      handleClickOpen(
        "Agreeing this will assign recommended PeerFives",
        "Assign Recommended PeerFives to all?",
        "toAll"
      );
    } else {
      setRecommendedPointToggle(event.target.checked);
    }
  };

  const CustomTypography = ({
    fontSize,
    fontWeight,
    color,
    children,
    display,
    lineHeight = "normal",
  }) => {
    return (
      <Typography
        variant="body1"
        style={{ fontSize, fontWeight, color, display, lineHeight }}
      >
        {children}
      </Typography>
    );
  };
  return (
    <div className={styles.BudgetDialog}>
      <div className={styles.headerContainer}>
        <img className={styles.logoIcon} src={PeerfiveIcon} alt="PeerFives" />
        <div>
          <CustomTypography fontSize="24px" fontWeight="900" color="#000">
            PeerFives Value & Allocation
          </CustomTypography>
          <CustomTypography fontSize="16px" fontWeight="400" color="#5A5A5A">
            Adjust PeerFives point value and configure member points allocation.
          </CustomTypography>
        </div>
        <div onClick={closeDialog} onKeyDown={closeDialog} role="presentation">
          <img className={styles.closeIcon} src={iconClose} alt="close" />
        </div>
      </div>

      <div className={styles.divider} />

      <Grid container className={styles.peerFivesAndBudgetCont}>
        <Grid
          item
          md={6}
          sm={6}
          xs={12}
          className={`${styles.gridContainer} ${styles.borderRight}`}
        >
          <div className={styles.dropDownHeaderCon__BudgetDialog}>
            <CustomTypography fontSize="16px" fontWeight="900" color="#000">
              PeerFives Value
            </CustomTypography>
            <KeyboardArrowDown
              className={styles.downArrow__BudgetDialog}
              onClick={handleBudgetDropDown}
            />
          </div>

          <div
            className={
              budgetDropDown
                ? `${styles.displayDropDown} ${styles.dropDownBodyCont__BudgetDialog}`
                : `${styles.closeDropDown} ${styles.dropDownBodyCont__BudgetDialog}`
            }
          >
            <div className={styles.boxInputContainer}>
              <CustomTypography
                fontSize="16px"
                fontWeight="700"
                color="#000"
                display="inline-block"
              >
                Peerfives Value{" "}
              </CustomTypography>
              <CustomTypography
                fontSize="14px"
                fontWeight="500"
                color="#808080"
              >
                Set the monetary value of 1 PeerFives point
              </CustomTypography>
              <CustomizedSelects
                value={pointWeightage}
                onChange={(event) =>
                  setPointWeightage(parseInt(event.target.value))
                }
                currency={currency}
                handleChangeCurrency={(event) =>
                  setCurrency(event.target.value)
                }
                disabledCurrency={
                  user.team.pointsConversionRate !== 0 ? true : false
                }
                disabledInput={
                  user.team.pointsConversionRate !== 0 ? true : false
                }
              />

              <CustomTypography
                fontSize="14px"
                fontWeight="500"
                color="#969696"
                lineHeight="20px"
              >
                Enter the value above and notice the PeerFives Cost changing in
                the example below based on your input.
              </CustomTypography>
            </div>

            <div className={styles.exampleStoreDivWrapper}>
              <CustomTypography
                fontSize="18px"
                fontWeight="900"
                color="#000"
                lineHeight="normal"
              >
                Example of PeerFives Store
              </CustomTypography>
              <div className={styles.exampleStoreDiv}>
                <div className={styles.shirtDiv}>
                  <img
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      display: "block",
                    }}
                    src={storeShirt}
                  />
                </div>
                <div>
                  <div className={styles.shirtDescriptionDiv}>
                    <div>
                      <CustomTypography
                        fontSize="16px"
                        fontWeight="700"
                        color="#1E1E1E"
                        lineHeight="24px"
                      >
                        PeerFives Polo T-Shirt
                      </CustomTypography>
                      <CustomTypography
                        fontSize="16px"
                        fontWeight="400"
                        color="#777"
                        lineHeight="24px"
                      >
                        Customized Merchandise, Unisex Clothing
                      </CustomTypography>
                    </div>
                    <div>
                      <CustomTypography
                        fontSize="14px"
                        fontWeight="500"
                        color="#111"
                        lineHeight="28px"
                      >
                        MRP:{" "}
                        {currency === "USD"
                          ? "$ 500"
                          : currency === "EUR"
                          ? "€ 500"
                          : "₹ 500"}
                      </CustomTypography>
                    </div>
                  </div>
                  {user.team.pointsConversionRate !== 0 ? (
                    <div className={styles.peerFivesCostDivWithLogo}>
                      <img src={smallPeerFivesLogo} />
                      <div className={styles.peerFivesCostDiv}>
                        <CustomTypography
                          fontSize="16px"
                          fontWeight="400"
                          color="#777"
                          display="inline-block"
                          lineHeight="17px"
                        >
                          PeerFives Cost:{" "}
                          <CustomTypography
                            fontSize="16px"
                            fontWeight="500"
                            color="#111"
                            display="inline-block"
                            lineHeight="17px"
                          >
                            {Math.round(500 / user.team.pointsConversionRate)}
                          </CustomTypography>{" "}
                          <CustomTypography
                            fontSize="16px"
                            fontWeight="500"
                            color="#111"
                            display="inline-block"
                          >
                            Points
                          </CustomTypography>
                        </CustomTypography>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.peerFivesCostDivWithLogo}>
                      <img src={smallPeerFivesLogo} />
                      <div className={styles.peerFivesCostDiv}>
                        <CustomTypography
                          fontSize="16px"
                          fontWeight="400"
                          color="#777"
                          display="inline-block"
                          lineHeight="17px"
                        >
                          {pointWeightage ? "PeerFives Cost: " : ""}
                          <CustomTypography
                            fontSize="16px"
                            fontWeight="500"
                            color="#111"
                            display="inline-block"
                            lineHeight="17px"
                          >
                            {pointWeightage
                              ? parseInt(Math.round(500 / pointWeightage))
                              : "Set PeerFives Value"}
                          </CustomTypography>{" "}
                          <CustomTypography
                            fontSize="16px"
                            fontWeight="500"
                            color="#111"
                            display="inline-block"
                          >
                            {pointWeightage ? "Points" : ""}
                          </CustomTypography>
                        </CustomTypography>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.monthlySpendDivWrapper}>
              <CustomTypography fontSize="16px" fontWeight="700" color="#000">
                Your Monthly Spend
              </CustomTypography>
              <div className={styles.calculationDiv}>
                <div className={styles.eachCalculationDiv}>
                  <CustomTypography
                    fontSize="12px"
                    fontWeight="600"
                    color="#000"
                  >
                    Total Assigned Points
                  </CustomTypography>
                  <CustomTypography
                    fontSize="16px"
                    fontWeight="900"
                    color="#000"
                  >
                    {" "}
                    {totalAssignedPoints}
                  </CustomTypography>
                </div>
                <div
                  style={{ width: "45px" }}
                  className={styles.eachCalculationDiv}
                >
                  <CustomTypography
                    fontSize="16px"
                    fontWeight="900"
                    color="#000"
                  >
                    ×
                  </CustomTypography>
                </div>
                <div className={styles.eachCalculationDiv}>
                  <CustomTypography
                    fontSize="12px"
                    fontWeight="600"
                    color="#000"
                  >
                    Redemption Value
                  </CustomTypography>
                  <CustomTypography
                    fontSize="16px"
                    fontWeight="900"
                    color="#000"
                  >
                    {user?.team?.pointsConversionRate !== 0
                      ? user?.team?.pointsConversionRate
                      : pointWeightage === 0 || isNaN(pointWeightage)
                      ? "Not Set"
                      : pointWeightage}
                  </CustomTypography>
                </div>{" "}
                <div
                  style={{ width: "45px" }}
                  className={styles.eachCalculationDiv}
                >
                  <CustomTypography
                    fontSize="16px"
                    fontWeight="900"
                    color="#000"
                  >
                    =
                  </CustomTypography>
                </div>
                <div className={styles.eachCalculationDiv}>
                  <CustomTypography
                    fontSize="12px"
                    fontWeight="600"
                    color="#000"
                  >
                    Max. Monthly Spends
                  </CustomTypography>
                  <CustomTypography
                    fontSize="16px"
                    fontWeight="900"
                    color="#000"
                  >
                    {currency === "USD" ? "$" : currency === "EUR" ? "€" : "₹"}{" "}
                    {user?.team?.pointsConversionRate !== 0
                      ? parseInt(
                          totalAssignedPoints * user.team.pointsConversionRate
                        )
                      : isNaN(pointWeightage)
                      ? 0
                      : parseInt(totalAssignedPoints * pointWeightage)}
                  </CustomTypography>
                </div>
              </div>
            </div>

            <div className={styles.infoDiv}>
              <CustomTypography
                fontSize="12px"
                fontWeight="500"
                color="#454545"
              >
                Please add your credit card details to get access of market
                place.
                <a href="#" onClick={openCardDetailsModal}>
                  click here
                </a>
              </CustomTypography>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={
                  user?.team?.pointsConversionRate !== 0 || pointWeightage === 0
                }
                variant="contained"
                className={styles.budgetButton}
                onClick={() => {
                  setBudget();
                }}
              >
                {isBudgetLoading ? (
                  <div className="loader"></div>
                ) : (
                  "Save & Update"
                )}
              </Button>

              <DialogModel
                {...cardDetailsDialog.props}
                title="Card Details Dialog"
                disbleHeader
                maxWidth="md"
                onClose={closeCardDetailsDialog}
              >
                <CardDetailsDialog
                  onClose={closeCardDetailsDialog}
                  prefilledDetails={user?.team?.billingInfo}
                />
              </DialogModel>

              <Dialog
                open={open.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {open.messageHeader}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {open.message}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div className={styles.footer}>
                    <Button
                      color="primary"
                      className={styles.skipButton}
                      onClick={handleClose}
                    >
                      Disagree
                    </Button>
                    <Button
                      variant="contained"
                      className={styles.saveButton}
                      onClick={handleAgreeClose}
                    >
                      Agree
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </Grid>

        <Grid item md={6} sm={6} xs={12} className={`${styles.gridContainer}`}>
          <div className={styles.rightContainer}>
            <div className={styles.availableBalanceContainer}>
              <CustomTypography fontSize="16px" fontWeight="900" color="#000">
                Assigned Points
              </CustomTypography>
              <KeyboardArrowDown
                className={styles.downArrow__BudgetDialog}
                onClick={handleCompBalanceDropDown}
              />
            </div>
            <div
              className={
                balance
                  ? `${styles.displayDropDown} ${styles.dropDownBodyCont__BudgetDialog}`
                  : `${styles.closeDropDown} ${styles.dropDownBodyCont__BudgetDialog}`
              }
            >
              <div>
                <SearchBox
                  placeholder="Search Members"
                  onChange={(e) => doFilter(e.target.value)}
                />
              </div>
              {membersLoading
                ? fakemembersList.map((data) => <UserCardSkeleton key={data} />)
                : filterMembersList.map((data) => (
                    <UserCard
                      data={data}
                      key={data.id}
                      setSpendingLimit={setSpendingLimit}
                      isLimitLoading={isLimitLoading}
                    />
                  ))}
              {!membersLoading && filterMembersList.length < 1 && (
                <div className={styles.noDataDiv}>
                  <img src={NoUserFound} alt="No Member Found" />
                  <Typography className={styles.noData}>
                    No User Found
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

BudgetDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  budgetDialogProfile: PropTypes.bool.isRequired,
  setBudgetBtn: PropTypes.func.isRequired,
  budgetBtn: PropTypes.bool.isRequired,
  setDisplaySec: PropTypes.func.isRequired,
  budget: PropTypes.number.isRequired,
};

BudgetDialog.defaultProps = {};

export default BudgetDialog;
