import React from "react";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./Company.module.scss";

const Company = (props) => {
  const { profileData, plateformType } = props;

  return (
    <div className={styles.card}>
      <img src={profileData?.team?.image} className={styles.logo} alt="User" />
      <div>
        <Typography className={styles.name}>
          {profileData?.team?.name}
        </Typography>
        <Typography className={styles.description}>
          This name gets changed according to the {plateformType} workspace
          settings.
        </Typography>
      </div>
    </div>
  );
};

Company.propTypes = {
  profileData: PropTypes.node,
};

Company.defaultProps = {
  profileData: {},
};

export default Company;
