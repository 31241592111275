import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./BillingDetailsDialog.module.scss";
import iconClose from "../../../../assets/icon_close.svg";
import BillingDetails from "../../../../components/BillingDetails/BillingDetails";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const CardDetailsDialog = (props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const closeDialog = () => {
    props.onClose(true);
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        <div>
          <Typography className={styles.headerTitle}>Your Details</Typography>
        </div>
        <div onClick={closeDialog} onKeyDown={closeDialog} role="presentation">
          <img className={styles.closeIcon} src={iconClose} alt="close" />
        </div>
      </div>

      <div className={styles.bodyContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Elements stripe={stripePromise}>
              <div className={styles.StripeFormWrapper}>
                <BillingDetails
                  handleSkip={closeDialog}
                  prefilledDetails={props.prefilledDetails}
                />
              </div>
            </Elements>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

CardDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

CardDetailsDialog.defaultProps = {};

export default CardDetailsDialog;
