import { Button, Checkbox, Radio, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { ReactComponent as FilterChecked } from "../../assets/FilterChecked.svg";
import { ReactComponent as FilterSubtract } from "../../assets/FilterSubtract.svg";
import Close from "@material-ui/icons/Close";

import { Calendar } from "react-date-range";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useEffect } from "react";
import moment from "moment";
import { startOfQuarter, subQuarters } from "date-fns";

const checkedIcon = <FilterChecked />;
const icon = <FilterSubtract />;

const useStyles = makeStyles((theme) => ({
  FilterContMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      position: " absolute",
      left: "0px",
      bottom: "0px",
      width: "100vw",
      height: "100vh",
      zIndex: "3",
      background: "rgba(0,0,0,0.8)",
    },
  },
  popper: {
    border: "1px solid #ECECEC",
    boxShadow: "0px 4px 4px 0px #0000001A",
    borderRadius: "3",
    width: "172",
    zIndex: "1",
    fontSize: "13",
    color: "#586069",
    "& .MuiAutocomplete-listbox": {
      overflowX: "hidden",
    },
  },
  option: {
    paddingLeft: "10px",
    color: "#000000",
    fontSize: "16px",
    '&[aria-selected="true"]': {
      backgroundColor: "#FFFADF",
    },
    '&[data-focus="true"]': {
      backgroundColor: "#FFFADF",
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  mainContent__FilterContMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      left: "0px",
      bottom: "0px",
      width: "100vw",
      height: "75vh",
      zIndex: "4",
      background: "#FFFFFF",
    },
  },
  closeBtn__FilterContMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      position: "absolute",
      top: "18%",
      right: "3%",
      minWidth: "0px",
      borderRadius: "50%",
      height: "40px",
      width: "40px",
      border: "1px solid #fff",
      background: "#000",
      color: "#fff",
    },
  },
  headerMainCont__FilterContMobile: {
    display: "flex",
    width: "100vw",
    height: "fit-content",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
  },
  applyText__FilterContMobile: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#000",
  },
  filterByText__FilterContMobile: {
    color: "#B9B9B9",
    fontSize: "16px",
    fontWeight: "700",
  },
  filterContainer: {
    display: "flex",
  },
  leftFilterCont__FilterContMobile: {
    borderRight: "1px solid #ECECEC",
    borderTop: "1px solid #ECECEC",
    width: "40vw",
    height: "100vh",
  },
  RightFilterCont__FilterContMobile: {
    display: "flex",
    borderTop: "1px solid #ECECEC",
    width: "60vw",
    height: "100vh !important",
    padding: "1rem",
    overflowY: "scroll !important",
  },
  commanBtnStyle__FilterContMobile: {
    width: "100%",
    borderRadius: "0px",
    padding: "16px",
    justifyContent: "flex-start",
    fontSize: "16px",
    fontWeight: "400",
    textTransform: "none",
    backgroundColor: "#ffff",
    "&:active": {
      backgroundColor: "#FFFADF",
    },
  },
  setBgColor: {
    backgroundColor: "#FFFADF",
    width: "100%",
    borderRadius: "0px",
    padding: "16px",
    justifyContent: "flex-start",
    fontSize: "16px",
    fontWeight: "400",
    textTransform: "none",
  },
  paperFilter: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
  },
  RightFilterCont__FilterContMobile: {
    width: "60vw",
    padding: "10px",
    borderTop: "1px solid #ECECEC",
    flexDirection: "column",
    height: "66%",
    overflowY: "scroll",
  },
  checkBoxCont__FilterContMobile: {
    display: "flex",
    alignItems: "center",
  },
  radioDates: {
    colorPrimary: {
      "&$checked": {
        color: "#000",
      },
    },
    checked: {},
  },
  radioBtnTextCoreVal: {
    wordBreak: "break-all",
  },
  radio: {
    "&$checked": {
      color: "#000 ",
    },
  },
  checked: {},

  customDateTitleCont: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    border: "1px solid #D7D7D7",
    alignItems: "center",
    padding: "10px",
    margin: "10px 0px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  para_customDateRange: {
    marginBottom: "0px",
  },
  handleCalenderCont: {
    position: "absolute",
    top: "0px",
    right: "0px",
    borderRadius: "4px",
    border: "1px solid #ececec",
    zIndex: "1000",
  },
  dateMainContainer: {
    padding: "0px 14px",
  },
}));
let pendingmyVal = [];

function FilterContMobile({
  popUpVal,
  setPopUp,
  allCoreVal,
  handlePendingVal,
  setSelectedCoreValue,
  handleCoreValueClose,
  setDate,
}) {
  const classes = useStyles();
  const currDate = new Date();
  const [activeBg, setActiveBg] = React.useState({
    coreVal: true,
    date: false,
  });
  const radioDateText = [
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "Last Month",
    "This Month",
    "Custom Date",
  ];
  const [selectedValue, setSelectedValue] = React.useState("");
  const [openDateRangeInp, setOpenDateRangeInp] = useState(false);

  const [customDate, setCustomDate] = useState({
    startingDate: subQuarters(startOfQuarter(new Date()), 0),
    endingDate: new Date(),
  });

  const [arrowUp, setArrowUp] = useState(false);
  const [arrowDown, setArrowDown] = useState(false);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    let selectedRadioBtn = event.target.value;
    if (selectedRadioBtn === "Yesterday") {
      const yesterday = moment().subtract(1, "days");
      setCustomDate({ startingDate: yesterday._d, endingDate: currDate });
    } else if (selectedRadioBtn === "Last 7 Days") {
      const lastSeven = moment().subtract(7, "days");
      setCustomDate({ startingDate: lastSeven._d, endingDate: currDate });
    } else if (selectedRadioBtn === "Last 30 Days") {
      const lastThirty = moment().subtract(30, "days");
      setCustomDate({ startingDate: lastThirty._d, endingDate: currDate });
    } else if (selectedRadioBtn === "Last Month") {
      const lastMontOfLastDate = moment()
        .subtract(1, "months")
        .startOf("month");

      const lastMonthOfFirstDate = moment()
        .subtract(1, "months")
        .endOf("month");
      setCustomDate({
        startingDate: lastMontOfLastDate._d,
        endingDate: lastMonthOfFirstDate._d,
      });
    } else if (selectedRadioBtn === "This Month") {
      const thisMonthOfLastDate = moment()
        .subtract(0, "months")
        .startOf("month");

      setCustomDate({
        startingDate: thisMonthOfLastDate._d,
        endingDate: currDate,
      });
    }

    event.target.value === "Custom Date"
      ? setOpenDateRangeInp(true)
      : setOpenDateRangeInp(false);
  };

  const handleCheckBoxVal = (checked, value) => {
    if (checked === true) {
      if (pendingmyVal.length === 0) {
        pendingmyVal.push(value);
      } else {
        pendingmyVal.forEach((element) => {
          if (element !== value) {
            return pendingmyVal.push(value);
          } else {
            return pendingmyVal;
          }
        });
      }
    } else {
      if (pendingmyVal.length <= 1) {
        pendingmyVal = [];
      } else {
        let filteredArrIndex = pendingmyVal.filter((data) => data !== value);
        pendingmyVal = [...filteredArrIndex];
      }
    }
    setSelectedCoreValue([...pendingmyVal]);
    handlePendingVal([...pendingmyVal]);
  };

  const handleActiveCoreBg = () => {
    setActiveBg({ coreVal: true });
  };
  const handleActiveDateBg = () => {
    setActiveBg({ date: true });
  };

  const handleStartDate = () => {
    setArrowUp(!arrowUp);
    setArrowDown(false);
  };
  const handleEndDate = () => {
    setArrowUp(false);
    setArrowDown(!arrowDown);
  };

  useEffect(() => {
    window.innerWidth < 600 &&
      setDate([
        { startDate: customDate.startingDate, endDate: customDate.endingDate },
      ]);
  }, [customDate]);

  return (
    <div
      className={classes.FilterContMobile}
      style={popUpVal ? { display: "flex" } : { display: "none" }}
    >
      <Button
        className={classes.closeBtn__FilterContMobile}
        onClick={() => {
          setPopUp(false);
          handleCoreValueClose();
        }}
      >
        <Close />
      </Button>
      <div className={classes.mainContent__FilterContMobile}>
        <div className={classes.headerMainCont__FilterContMobile}>
          <Typography className={classes.filterByText__FilterContMobile}>
            Filter by
          </Typography>
          <Typography className={classes.applyText__FilterContMobile}>
            Apply
          </Typography>
        </div>
        <div className={classes.filterContainer}>
          <div className={classes.leftFilterCont__FilterContMobile}>
            <Button
              onClick={handleActiveCoreBg}
              style={
                activeBg.coreVal
                  ? { backgroundColor: "#FFFADF" }
                  : { backgroundColor: "#FFFFFF" }
              }
              className={classes.commanBtnStyle__FilterContMobile}
            >
              Core Values
            </Button>
            <Button
              onClick={handleActiveDateBg}
              style={
                activeBg.date
                  ? { backgroundColor: "#FFFADF" }
                  : { backgroundColor: "#FFFFFF" }
              }
              className={classes.commanBtnStyle__FilterContMobile}
            >
              Date
            </Button>
          </div>
          <div className={classes.RightFilterCont__FilterContMobile}>
            {activeBg.coreVal &&
              allCoreVal.map((coreVal, index) => (
                <div
                  key={index}
                  className={classes.checkBoxCont__FilterContMobile}
                >
                  <Checkbox
                    onChange={(e) =>
                      handleCheckBoxVal(e.target.checked, coreVal)
                    }
                    checkedIcon={checkedIcon}
                    icon={icon}
                    color="default"
                  />
                  <Typography className={classes.radioBtnTextCoreVal}>
                    {coreVal}
                  </Typography>
                </div>
              ))}
            {activeBg.date && (
              <>
                {radioDateText.map((data, index) => {
                  return (
                    <div key={index}>
                      <Radio
                        checked={selectedValue === data}
                        onChange={handleChange}
                        value={data}
                        name="radio-buttons"
                        inputProps={{ "aria-label": data.toUpperCase() }}
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                      <label>{data}</label>
                    </div>
                  );
                })}
                {openDateRangeInp && (
                  <div className={classes.dateMainContainer}>
                    <div>
                      <div
                        className={classes.customDateTitleCont}
                        onClick={handleStartDate}
                      >
                        <p className={classes.para_customDateRange}>
                          {customDate?.startingDate === null
                            ? "Start Date"
                            : customDate?.startingDate
                                ?.toString()
                                ?.slice(4, 15)}
                        </p>
                        {arrowUp ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                      </div>
                      {arrowUp && (
                        <Calendar
                          date={currDate}
                          onChange={(item) => {
                            setCustomDate({
                              ...customDate,
                              startingDate: item,
                            });
                            setArrowUp(false);
                          }}
                          className={classes.handleCalenderCont}
                        />
                      )}
                    </div>
                    <div>
                      <div
                        className={classes.customDateTitleCont}
                        onClick={handleEndDate}
                      >
                        <p className={classes.para_customDateRange}>
                          {customDate?.endingDate === null
                            ? "End Date"
                            : customDate?.endingDate?.toString()?.slice(4, 15)}
                        </p>
                        {arrowDown ? (
                          <KeyboardArrowDown />
                        ) : (
                          <KeyboardArrowUp />
                        )}
                      </div>
                      {arrowDown && (
                        <Calendar
                          date={currDate}
                          onChange={(item) => {
                            setCustomDate({ ...customDate, endingDate: item });
                            setArrowDown(false);
                          }}
                          className={classes.handleCalenderCont}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterContMobile;
