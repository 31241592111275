import React, { useState } from "react";
import {
  Box,
  Button,
  capitalize,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PartyImage from "../../../../assets/party.svg";
import TourVideo from "../TourVideo/TourVideo";

const useStyles = makeStyles((theme) => ({
  Demo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    flexDirection: "column",
    width: "100%",
    height: "75vh",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5rem",
      height: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "5rem",
      height: "60vh",
    },
  },
  nameText: {
    fontSize: "20px",
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  logo: {
    padding: "7px",
    borderRadius: "10px",
    width: "63px",
    height: "63px",
    marginBottom: "13px",
  },

  descriptionText: {
    textAlign: "center",
  },
  btn: {
    background: "#000",
    color: "#fff",
    marginTop: " 10px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#000",
      color: "#fff",
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

function Demo({ userName, filterSelected }) {
  const classes = useStyles();
  const [displaySec, setDisplaySec] = useState(
    filterSelected ? "firstSec" : "firstPageTour"
  );

  return (
    <Box className={classes.Demo}>
      {displaySec === "firstSec" && (
        <>
          <img src={PartyImage} alt="Party image" className={classes.logo} />
          <Typography className={classes.nameText}>
            Hi, {capitalize(userName)}
          </Typography>
          <Typography className={classes.nameText}>
            Welcome to the PeerFives Dashboard
          </Typography>
          <Typography className={classes.nameText}>
            You’re All Set! Start Rewarding.
          </Typography>
          <Button
            variant="outlined"
            className={classes.btn}
            onClick={() => {
              setDisplaySec("firstPageTour");
            }}
          >
            Take A Tour
          </Button>
        </>
      )}

      {displaySec === "firstPageTour" && <TourVideo />}
    </Box>
  );
}

export default Demo;
