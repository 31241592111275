import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Support from "../../../../assets/SupportIcon.svg";
import { Typography } from "@material-ui/core";
import styles from "./SupportCard.module.scss";

const SupportCard = () => {
  return (
    <>
      <div className={styles.card}>
        <ListItem style={{ padding: 0 }}>
          <ListItemIcon className={styles.imageCon__SupportCard}>
            <img alt="S" src={Support} />
          </ListItemIcon>
          <ListItemText
            primary={
              <div className={styles.container}>
                <div>
                  <Typography className={styles.description}>
                    <a
                      href="mailto:support@peerfives.com"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      support@peerfives.com
                    </a>
                  </Typography>
                </div>
              </div>
            }
          />
        </ListItem>
      </div>
    </>
  );
};

export default SupportCard;
