import axios from "axios";
import * as storageServices from "./storageServices";
import history from "../@history";

export const get = (route, params = {}) => {
  axios.defaults.headers.common["token"] = storageServices.get(
    storageServices.TOKEN
  );
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URI + route, { params })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Unauthorized Error");
            logout();
          }
        }
        reject(error);
      });
  });
};
export const post = (route, params = {}) => {
  axios.defaults.headers.common["token"] = storageServices.get(
    storageServices.TOKEN
  );
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URI + route, params)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            logout();
          }
        }
        reject(error);
      });
  });
};

export const patch = (route, params = {}) => {
  axios.defaults.headers.common["token"] = storageServices.get(
    storageServices.TOKEN
  );
  return new Promise((resolve, reject) => {
    axios
      .patch(process.env.REACT_APP_API_URI + route, params)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            logout();
          }
        }
        reject(error);
      });
  });
};
export const logout = () => {
  storageServices.clearAll();
  history.push({
    pathname: "/login",
  });
};
