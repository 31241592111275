import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import StarIcon from "../../../../assets/starIcon.svg";

const useStyles = makeStyles((theme) => ({
  white: {
    color: "black",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  yellow: {
    color: "black",
    backgroundColor: "#FFFADF",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  rankBox: {
    border: "1px solid #000000",
    color: "black",
    backgroundColor: "white",
  },
  black: {
    color: "white",
    backgroundColor: "black",
  },
  userAvatarCont: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      minWidth: "42px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "45px",
    },
  },
  starUser: {
    position: "absolute",
    top: "22px",
    right: "12px",
    width: "20px",
    height: "20px",
    backgroundColor: "white",

    [theme.breakpoints.down("md")]: {
      top: "18px",
      right: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      right: "3px",
      width: "18px",
      height: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      right: "6px",
      top: "20px",
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "12px",
    marginRight: "24px",
    [theme.breakpoints.down("md")]: {
      marginRight: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      zIndex: "1",
      left: "2px",
      top: "10px",
      width: "19px",
      height: "19px",
    },
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      top: "0px",
    },
  },
  profileIcon__LeaderUserCard: {
    [theme.breakpoints.down("md")]: {
      width: "35px",
      height: "35px",
    },
  },
  userDetails__LeaderUserCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {},
  },
  realName__LeaderUserCard: {
    fontWeigth: "900",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginLeft: "6px",
    },
  },
  earnPoints__LeaderUserCard: {
    fontWeight: "900",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {},
  },
  p5Text__LeaderUserCard: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const LeaderUserCard = (props) => {
  const { leaderData } = props;
  const classes = useStyles();
  return (
    <>
      <ListItem
        className={clsx(leaderData?.rank > 3 ? classes.white : classes.yellow)}
      >
        <Avatar
          className={clsx(
            classes.small,
            leaderData?.rank > 3 ? classes.rankBox : classes.black
          )}
        >
          <Box fontSize={12}> {leaderData?.rank}</Box>
        </Avatar>
        <ListItemIcon className={classes.userAvatarCont}>
          <Avatar
            src={leaderData?.profile?.image_48}
            className={classes.profileIcon__LeaderUserCard}
          />
          {leaderData?.rank < 4 && (
            <Avatar src={StarIcon} className={classes.starUser} />
          )}
        </ListItemIcon>
        <ListItemText
          className={classes.userDetails__LeaderUserCard}
          primary={
            <Typography className={classes.realName__LeaderUserCard}>
              {leaderData?.profile?.real_name}
            </Typography>
          }
          secondary={
            <Typography className={classes.earnPoints__LeaderUserCard}>
              {leaderData?.earnedPoints}
              {/* <span className={classes.p5Text__LeaderUserCard}>PeerFives</span> */}
            </Typography>
          }
        />
      </ListItem>
    </>
  );
};

LeaderUserCard.propTypes = {
  leaderData: PropTypes.shape(PropTypes.shape({})),
  index: PropTypes.number,
};

LeaderUserCard.defaultProps = {
  leaderData: {},
  index: 0,
};

export default LeaderUserCard;
