import { InputBase, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./UserCard.module.scss";
import iconEdit from "../../../../../../assets/icon_edit.svg";
import iconRight from "../../../../../../assets/icon_right.svg";

const UserCard = (props) => {
  const { data, isLimitLoading } = props;
  const [inputPoints, setInputPoints] = useState(data?.spendingLimit);
  const [isEditable, setEditable] = useState(false);
  const handleSpendingLimit = () => {
    props.setSpendingLimit(data?.id, inputPoints);
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.content}>
        <img
          className={styles.userImage}
          src={data?.profile?.image_72}
          alt="User"
        />
        <Typography className={styles.userName}>
          {data?.profile?.real_name}
        </Typography>
      </div>

      <div className={styles.content}>
        <InputBase
          id="standard-multiline-flexible"
          value={inputPoints}
          type="number"
          className={`${styles.inputBox} ${
            isEditable && styles.inputBorderColor
          }`}
          disabled={!isEditable}
          onChange={(event) => setInputPoints(event.target.value)}
        />
        <div
          className={`${styles.iconBox} ${isEditable && styles.iconBoxBlack}`}
          role="presentation"
          onClick={() => {
            if (isEditable && !isLimitLoading.loading) {
              setEditable(!isEditable);
              handleSpendingLimit();
            } else if (!isEditable && !isLimitLoading.loading)
              setEditable(!isEditable);
          }}
        >
          {isEditable ? (
            <>
              {isLimitLoading.loading && isLimitLoading.id === data.id ? (
                <div className="loader"></div>
              ) : (
                <img src={iconRight} alt="done" />
              )}
            </>
          ) : (
            <img src={iconEdit} alt="edit" />
          )}
        </div>
      </div>
    </div>
  );
};

UserCard.propTypes = {
  data: PropTypes.shape({
    redeemablePoints: PropTypes.number,
    profile: PropTypes.shape({
      image_72: PropTypes.string,
      real_name: PropTypes.string,
    }),
  }),
};

UserCard.defaultProps = {
  data: {},
};

export default UserCard;
