import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import PeerfiveIcon from "../assets/peerFivesLogo.svg";
import MenuComponent from "./Menu/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: "0px",
      width: "100%",
      zIndex: "1000",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  indicator: {
    backgroundColor: "black",
  },
  sectionDesktop: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  peerFivesLogo__Header: {
    width: "152px",
    height: "40px",
    marginLeft: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "116.13px",
      height: "30px",
      marginLeft: "-4% !important",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "8% ",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "7.5%",
      paddingLeft: "0px",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "7.5%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "2.4%",
    },
  },
  topHeaderCont__Header: {
    [theme.breakpoints.down("xs")]: {
      padding: " 0px 24px",
    },
  },
  topHeaderSubCon__Header: {
    [theme.breakpoints.up("xl")]: {
      maxWidth: "1400px",
      width: "100%",
      padding: "0px",
    },
    [theme.breakpoints.down("xl")]: {
      maxWidth: "1400px",
      width: "100%",
      padding: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
      paddingLeft: "0px",
    },

    [theme.breakpoints.down("xs")]: {
      paddingLeft: "12px",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  // const renderMenu = (

  //   // <Menu
  //   //   anchorEl={anchorEl}
  //   //   anchorOrigin={{ vertical: "bottom", horizontal: "bottom" }}
  //   //   id={menuId}
  //   //   keepMounted
  //   //   transformOrigin={{ vertical: "bottom", horizontal: "center" }}
  //   //   open={isMenuOpen}
  //   //   onClose={handleMenuClose}
  //   // >
  //   //   <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
  //   //   <MenuItem onClick={handleMenuClose}>My account</MenuItem>
  //   //   <MenuItem onClick={handleMenuClose}>Sign Out</MenuItem>
  //   // </Menu>
  // );

  return (
    <div className={classes.root}>
      <AppBar
        position="sticky"
        className={`header ${classes.topHeaderCont__Header}`}
      >
        <Toolbar variant="dense" className={classes.topHeaderSubCon__Header}>
          <Typography className={classes.title}>
            <Link to="/dashboard">
              <img
                style={{ cursor: "pointer" }}
                alt="Peerfive"
                src={PeerfiveIcon}
                className={classes.peerFivesLogo__Header}
              />
            </Link>
          </Typography>
          <div className={classes.sectionDesktop}>
            {/* <Tabs
              value={value}
              onChange={handleChange}
              classes={{
                indicator: classes.indicator
              }}
            >
              <Tab disableRipple label="Dashboard" value="dashboard" />
              <Tab disableRipple label="Challenges" value="challenges" />
              <Tab disableRipple label="Avail Rewards" value="availrewards" />
            </Tabs> */}
            <MenuComponent />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
