import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { StepButton, StepConnector } from "@material-ui/core";
import styles from "./CustomStepper.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiStepper-root": {
      padding: "24px 0",
      width: "60%",
      "@media only screen and (min-width: 700px) and (max-width: 900px)": {
        paddingLeft: "6.5%",
      },
      "@media (max-width: 700px)": {
        width: "80%",
      },
      "& .MuiStep-horizontal": {
        padding: "0",
      },
      "& .MuiStepLabel-label": {
        paddingRight: "8px",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        "@media (max-width: 700px)": {
          maxWidth: "7rem",
        },
      },
      "& .MuiStepButton-root": {
        "& .MuiStepLabel-iconContainer": {
          padding: "0",
        },
      },
    },
    "& .MuiStepIcon-root": {
      color: "#ECE6D4",
      width: "30px",
      height: "30px",
      "& .MuiStepIcon-text": { color: "black", fill: "black" },
    },
    "& .MuiStepIcon-active": {
      color: "#FFBF1C",
      "& .MuiStepIcon-text": {
        color: "white !important",
        fill: "white !important",
      },
    },
    "& .MuiStepIcon-completed": {
      color: "#00D339",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Connector = withStyles({
  // completed: {
  //   "& $line": {
  //     borderStyle: "solid",
  //   },
  // },
  line: {
    height: 1,
    border: 1,
    borderColor: "#FFC50D",
    borderRadius: 1,
    borderStyle: "dashed",
  },
})(StepConnector);

export default function CustomStepper({
  activeStep,
  stepTitle,
  totalStepCount,
}) {
  const classes = useStyles();

  const getStepIndexes = () => {
    const stepsIndexes = [];
    for (let i = 0; i < totalStepCount; i++) {
      stepsIndexes.push(i);
    }

    return stepsIndexes;
  };

  return (
    <div className={`${classes.root} ${styles.stepperWrapper}`}>
      <Stepper activeStep={activeStep} connector={<Connector />}>
        {getStepIndexes().map((index) => (
          <Step key={index + stepTitle}>
            {index === activeStep ? (
              <StepLabel>{stepTitle}</StepLabel>
            ) : (
              <StepButton />
            )}
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
