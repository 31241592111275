import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./MakeAdminDialog.module.scss";
import Administrator from "../../../../assets/Administrator.svg";
import iconClose from "../../../../assets/icon_close.svg";
import NoUserFound from "../../../../assets/NoUserFound.svg";
import UserCard from "./components/UserCard/UserCard";
import UserCardSkeleton from "./components/UserCard/UserCardSkeleton";
import SearchBox from "../../../../components/SearchBox/SearchBox";
import * as apiServices from "../../../../utils/services/apiServices";
import * as apiConstant from "../../../../utils/constant/apiConstant";
import { generateFakeArray } from "../../../../utils/services/utility";
import { useToaster } from "../../../../helper/ToasterContext";
import UserContext from "../../../../utils/context/UserContext";

const MakeAdminDialog = (props) => {
  const Toaster = useToaster();
  const [isLimitLoading, setIsLimitLoading] = useState({ loading: false });
  const [membersList, setMembersList] = useState([]);
  const [filterMembersList, setFilterMembersList] = useState([]);
  const [fakemembersList] = useState(generateFakeArray);
  const [membersLoading, setMembersLoading] = useState(false);
  const [profileId, setProfileId] = useState();
  const { user } = useContext(UserContext);
  const getMembersList = async () => {
    setMembersLoading(true);
    try {
      const { data } = await apiServices.get(apiConstant.MEMBERS_ALL);
      if (data) {
        setFilterMembersList(data);
        setMembersList(data);
      }
      setMembersLoading(false);
    } catch {
      setMembersLoading(false);
    }
  };

  const setAdmin = async (memberId, value) => {
    setIsLimitLoading({ loading: true, id: memberId });
    try {
      const { data } = await apiServices.patch(apiConstant.MAKEADMIN, {
        memberId: memberId,
        value: value,
      });
      if (data.status) {
        getMembersList();
      } else {
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
      setIsLimitLoading({ loading: false });
    } catch {
      setIsLimitLoading({ loading: false });
    }
  };
  const doFilter = (query) => {
    setFilterMembersList(
      membersList.filter((data) =>
        data?.profile?.real_name?.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  const closeDialog = () => {
    props.onClose(true);
  };
  useEffect(() => {
    getMembersList();
  }, []);
  useEffect(() => {
    if (user) {
      setProfileId(user.members.id);
    }
    // eslint-disable-next-line
  }, [user]);
  return (
    <div style={{ overflowY: "hidden" }}>
      <div className={styles.headerContainer}>
        <img className={styles.logoIcon} src={Administrator} alt="PeerFives" />
        <div className={styles.headerTitleContainer}>
          <Typography className={styles.headerTitle}>Administrators</Typography>
        </div>
        <div onClick={closeDialog} onKeyDown={closeDialog} role="presentation">
          <img className={styles.closeIcon} src={iconClose} alt="close" />
        </div>
      </div>

      <div className={styles.divider} />

      <Grid container>
        <Grid item xs={12} className={`${styles.gridContainer}`}>
          <div className={styles.rightContainer}>
            <div>
              <SearchBox
                placeholder="Search Members"
                onChange={(e) => doFilter(e.target.value)}
              />
            </div>
            {membersLoading
              ? fakemembersList.map((data) => <UserCardSkeleton key={data} />)
              : filterMembersList.map((data) => (
                  <UserCard
                    data={data}
                    key={data.id}
                    setAdmin={setAdmin}
                    isLimitLoading={isLimitLoading}
                    profileId={profileId}
                  />
                ))}
            {!membersLoading && filterMembersList.length < 1 && (
              <div className={styles.noDataDiv}>
                <img src={NoUserFound} alt="No Member Found" />
                <Typography className={styles.noData}>No User Found</Typography>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

MakeAdminDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

MakeAdminDialog.defaultProps = {};
export default MakeAdminDialog;
