import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Box,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { color } from "./AnalyticalHelper";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./AnalyticalDashboard.module.scss";
import { ReactComponent as DropDownIcon } from "../../assets/DropDownUpIcon.svg";
import NoUserFound from "../../assets/NoUserFound.svg";
import NoDataFound from "../../assets/NoDataFound.svg";
import iconClose from "../../assets/icon_close.svg";
import DialogModel from "../DialogModel/DialogModel";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import ActivityFeedCardSkeleton from "../../pages/Dashboard/components/ActivityFeedCard/ActivityFeedCardSkeleton";
import ActivityFeedCard from "../../pages/Dashboard/components/ActivityFeedCard/ActivityFeedCard";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  accordion: {
    padding: "0 24px",
    "& .MuiAccordion-rounded:first-child": {
      borderTop: "0px",
    },
  },
  listItem: {
    "& .MuiListItem-root": {
      padding: "7px 24px",
    },
    "& .MuiListItemAvatar-root": {
      minWidth: "36px",
    },
    "& .MuiListItemText-root": {
      color: "#a0a0a0",
      margin: "0",
    },
  },
  paginator: {
    "& .Mui-selected": {
      color: "#FFC50D",
      background: "#FFFADF !important",
      border: "1px solid #FFC50D !important",
    },
    "& .MuiButtonBase-root": {
      background: "#fff",
      border: "1px solid #ECECEC",
    },
    justifyContent: "center",
  },
  noData: {
    fontSize: "1rem",
    lineHeight: "19px",
    marginTop: "1.875rem",
  },

  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px 45px 12px",
    alignItems: "center",
    marginLeft: "10%",
    marginRight: "10%",
  },
  cvItemsList: {
    paddingLeft: "10px",
    paddingBottom: "0px",
    color: "black",
    "& .MuiListItem-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    "& .MuiListItemAvatar-root": {
      minWidth: "36px",
    },
    "& .MuiListItemText-root": {
      margin: "0",
    },
    "& .MuiList-padding": {
      paddingBottom: "0px",
    },
  },
}));

const Accordion = withStyles({
  root: {
    borderBottom: "1px solid #d7d7d7",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: "0px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
      alignSelf: "center",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const CoreValues = (props) => {
  const { coreValuesAnalysis, fromDate, toDate } = props;

  const history = useHistory();
  const classes = useStyles();

  const [cvAnalysis, setCvAnalysis] = useState([]);
  const [dataPoints, setDataPoints] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);
  const [expanded, setExpanded] = useState(0);
  const [activityFeedData, setActivityFeedData] = useState([]);
  const [feedLoading, setFeedLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [activityDialog, setActivityDialog] = useState({
    type: "new",
    props: {
      open: false,
    },
    data: null,
  });

  const selectedCoreValue = [];
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  // const dataPoints = [];
  const openActivityModal = (memberId) => {
    getUser(memberId);
    setActivityDialog({
      type: "new",
      props: {
        open: true,
      },
      data: null,
    });
  };
  const closeActivityDialog = () => {
    setActivityDialog({
      type: "new",
      props: {
        open: false,
      },
      data: null,
    });
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getUser = async (selectedMember) => {
    setFeedLoading(true);
    try {
      const requestJson = {
        skip: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
      };

      const { data } = await apiServices.get(apiConstant.GET_ALL_ACTIVITY, {
        skip: requestJson.skip,
        limit: requestJson.limit,
        coreValue: selectedCoreValue,
        memberId: selectedMember,
        toDate: toDate,
        fromDate: fromDate,
        activityType: "earned",
      });
      if (data) {
        setNoOfPages(Math.ceil(data[0]?.totalCount[0]?.count / itemsPerPage));
        setActivityFeedData(data[0].transaction);
      }
      setFeedLoading(false);
    } catch {
      setFeedLoading(false);
    }
  };
  useEffect(() => {
    coreValuesAnalysis.report.coreValuesAnalysis.map((coreValue, i) => {
      let cv = coreValuesAnalysis.report.destinationMemberAnalysis.find((c) => {
        return coreValue._id === c._id.coreValue;
      });
      setCvAnalysis((previousCV) => [
        ...previousCV,
        {
          id: coreValue._id,
          count: coreValue.count,
          member: cv.member,
          color: color[i],
        },
      ]);
      setDataPoints((dataPoints) => [...dataPoints, coreValue.count]);
      setDataLabels((dataPoints) => [...dataPoints, coreValue._id]);
    });
  }, []);
  const data = {
    labels: dataLabels,
    datasets: [
      {
        label: "# of Votes",
        data: dataPoints,
        backgroundColor: color,
      },
    ],
  };

  return (
    <Card {...props} className={styles.commanSummeryContainer}>
      <div className={styles.headerContainer}>
        <Typography className={styles.heading}>Core Values</Typography>
        <Typography
          onClick={(event) => {
            history.push({
              pathname: "/dashboard",
            });
          }}
          className={styles.seeAll}
        >
          See All
        </Typography>
      </div>
      {cvAnalysis.length <= 0 ? (
        <div className={styles.noDataDivCoreValue}>
          <img src={NoUserFound} alt="No Data Found" />
          <Typography className={styles.noData}>No User Found</Typography>
        </div>
      ) : (
        <>
          <div>
            <Doughnut
              data={data}
              height={200}
              width={200}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                cutout: "70%",
                showAllTooltips: true,

                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>

          <List disableGutters={false} className={classes.cvItemsList}>
            {cvAnalysis.map((coreValue, i) => (
              <div>
                <Accordion
                  expanded={expanded === i}
                  onChange={handleChange(i)}
                  className={classes.accordion}
                >
                  <AccordionSummary
                    expandIcon={<DropDownIcon />}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <span
                      style={{ background: coreValue.color }}
                      className={styles.cvSpan}
                    />
                    <div className={styles.cvItemTitle}>
                      <Typography className={styles.cvItemTitleText}>
                        #{coreValue.id}
                      </Typography>
                      <Typography
                        style={{ color: coreValue.color, fontWeight: "900" }}
                      >
                        {coreValue.count}%
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <div style={{ marginLeft: "10px" }}>
                    <Typography className={styles.cvHeading}>
                      Top Earners
                    </Typography>
                  </div>
                  <List disableGutters={false} className={classes.cvItemsList}>
                    {coreValue.member.slice(0, 3).map((member, i) => (
                      <ListItem key={member.id}>
                        <ListItemAvatar>
                          <Avatar
                            alt={member.name}
                            src={member.image}
                            className={classes.small}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={member.name}
                          style={{ cursor: "pointer" }}
                          onClick={(event) => {
                            setSelectedMember(member.name);
                            selectedCoreValue.push(coreValue.id);
                            openActivityModal(member.memberId);
                          }}
                        />
                        <Typography className={styles.points} edge="end">
                          {member.points}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Accordion>
                <Divider className={styles.divider} />
              </div>
            ))}
          </List>
        </>
      )}
      <DialogModel
        {...activityDialog.props}
        title="Activity Feed"
        maxWidth="md"
        onClose={closeActivityDialog}
        disbleHeader
      >
        <div>
          <div className={styles.headerContainer}>
            <div>
              <Typography className={styles.headerTitle}>
                Activity Feed of <b>{selectedMember}</b>
              </Typography>
            </div>
            <div
              onClick={closeActivityDialog}
              onKeyDown={closeActivityDialog}
              role="presentation"
            >
              <img className={styles.closeIcon} src={iconClose} alt="close" />
            </div>
          </div>
          {!feedLoading && activityFeedData.length < 1 ? (
            <div className={classes.noDataDiv}>
              <img src={NoDataFound} alt="No Data Found" />
              <Typography className={classes.noData}>
                No PeerFives feeds found.
              </Typography>
            </div>
          ) : (
            <List>
              {feedLoading
                ? Array.from({ length: itemsPerPage }, () => (
                    <ActivityFeedCardSkeleton></ActivityFeedCardSkeleton>
                  ))
                : activityFeedData.map((activity, index) => {
                    return (
                      <ActivityFeedCard
                        key={`activity-feed-data-${index}`}
                        activity={activity}
                        index={activity}
                      ></ActivityFeedCard>
                    );
                  })}
            </List>
          )}
          <div className={classes.footer}>
            <Box component="span">
              {noOfPages !== 1 && (
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={noOfPages}
                  page={page}
                  onChange={handlePageChange}
                  defaultPage={1}
                  // color="amber"
                  classes={{
                    ul: classes.paginator,
                    root: classes.paginationButton,
                  }}
                />
              )}
            </Box>
          </div>
        </div>
      </DialogModel>
    </Card>
  );
};

export default CoreValues;
