import React, { createContext, useContext, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const SnackbarContext = createContext();

export const SnackbarProvider = props => {
  const [state, setState] = useState({
    message: "Test Message",
    type: "info",
    open: false
  });

  const openSnackbar = (message, type) => {
    setState({
      message: message,
      type: type,
      open: true
    });
  };

  const closeSnackbar = () => {
    setState({
      message: state.message,
      type: state.type,
      open: false
    });
  };

  return (
    <SnackbarContext.Provider
      value={{
        openSnackbar,
        message: state.message,
        type: state.type,
        open: state.open,
        closeSnackbar
      }}
    >
      <SnackbarConsumer />
      {props.children}
    </SnackbarContext.Provider>
  );
};

const SnackbarConsumer = () => {
  return (
    <SnackbarContext.Consumer>
      {({ message, type, open, closeSnackbar }) => {
        return (
          <Snackbar open={open} autoHideDuration={3000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={type}>
              {message}
            </Alert>
          </Snackbar>
        );
      }}
    </SnackbarContext.Consumer>
  );
};

export const useToaster = () => useContext(SnackbarContext);
