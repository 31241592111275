import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const LeaderUserCardSkeleton = () => {
  return (
    <>
      <ListItem>
        <Skeleton
          animation="wave"
          variant="circle"
          width={25}
          height={25}
          className="mr-3"
        />
        <ListItemIcon>
          <Skeleton
            animation="wave"
            variant="circle"
            width={40}
            height={40}
          />
        </ListItemIcon>
        <ListItemText
          primary={<Skeleton animation="wave" />}
          secondary={<Skeleton width="60%" animation="wave" />}
        />
      </ListItem>
    </>
  );
};
export default LeaderUserCardSkeleton;
