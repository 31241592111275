import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Box,
  Grid,
  Checkbox,
  Popover,
  Button,
  Popper,
  InputBase,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import TopEarners from "../../components/AnalyticalDashboard/TopEarners";
import CoreValues from "../../components/AnalyticalDashboard/CoreValues";
import RewardedPeerfives from "../../components/AnalyticalDashboard/RewardedPeerfives";
import RedeemPeerfives from "../../components/AnalyticalDashboard/RedeemPeerfives";
import PeerfivesEngagement from "../../components/AnalyticalDashboard/PeerfivesEngagement";
import TopGivers from "../../components/AnalyticalDashboard/TopGivers";
import PeerFiveBuddy from "../../assets/icons/PeerFiveBuddy.svg";
import YellowBG from "../../assets/icons/YellowBG.svg";
import { ReactComponent as FilterChecked } from "../../assets/FilterChecked.svg";
import { ReactComponent as FilterSubtract } from "../../assets/FilterSubtract.svg";
import styles from "./AnalyticalDashboard.module.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import {
  addDays,
  isSameDay,
  getQuarter,
  getYear,
  startOfQuarter,
  lastDayOfQuarter,
  subQuarters,
} from "date-fns";
import DashboardNavigation from "components/DashboardNavigation/DashboardNavigation";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { TuneTwoTone } from "@material-ui/icons";
import FilterContMobile from "../../components/AnalyticalDashboard/FilterContMobile";

const icon = <FilterSubtract />;
const checkedIcon = <FilterChecked />;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
    backgroundColor: theme.palette.background.paper,
  },
  paperAutocomplete: {
    padding: theme.spacing(3),
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  select: {
    border: "1px solid #ECECEC",
    backgroundColor: "white",
    textTransform: "capitalize",
    fontSize: "16px",
    width: "245px",
    display: "flex",
    justifyContent: "space-between",
  },
  memberSelect: {
    border: "1px solid #ECECEC",
    backgroundColor: "white",
    textTransform: "capitalize",
    fontSize: "16px",
    width: "160px",
    display: "flex",
    justifyContent: "space-between",
  },
  selectDate: {
    border: "1px solid #ECECEC",
    backgroundColor: "white",
    textTransform: "capitalize",
    borderRadius: "4px",
    height: "42px",
    fontSize: "16px",
    display: "flex",
    justifyContent: "space-between",
    '&[aria-selected="true"]': {
      backgroundColor: "#fffff",
    },
    '&[data-focus="true"]': {
      backgroundColor: "#ffffff",
      border: "none",
    },
    padding: "0px 4px 0px 12px",
    "& .MuiSvgIcon-root": {
      color: "#000000",
      top: "calc(50% - 10px)",
      fontSize: "larger",
      right: "4px",
    },
    "& .MuiSelect-select:focus": {
      background: "white",
    },
  },
  popper: {
    border: "1px solid #ECECEC",
    boxShadow: "0px 4px 4px 0px #0000001A",
    borderRadius: 3,
    width: 172,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    "& .MuiAutocomplete-listbox": {
      overflowX: "hidden",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  inputBase: {
    borderRadius: 4,
    border: "1px solid #000000",
    backgroundColor: "white",
    paddingLeft: "14.5px",
    //padding: 10,
    width: "100%",
    "& input": {
      padding: "12px 8px",
      fontSize: "16px",
    },
  },
  paperFilter: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  option: {
    paddingLeft: "10px",
    color: "#000000",
    fontSize: "16px",
    '&[aria-selected="true"]': {
      backgroundColor: "#FFFADF",
    },
    '&[data-focus="true"]': {
      backgroundColor: "#FFFADF",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  popperDisablePortal: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: "103px",
    maxWidth: "103px",
    padding: "0 5px",
  },
  filterCheckIcon: {
    "& .MuiIconButton-root:hover": {
      background: "none",
    },
  },
  noDataDiv: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  date: {
    fontSize: "12px",
    color: "#A0A0A0",
    paddingTop: "14px",
  },
  filterCount: {
    color: "#FFC50D",
    marginBottom: "0px",
    marginLeft: "7px",
  },
  paginator: {
    "& .Mui-selected": {
      color: "#FFC50D",
      background: "#FFFADF !important",
      border: "1px solid #FFC50D !important",
    },
    "& .MuiButtonBase-root": {
      background: "#fff",
      border: "1px solid #ECECEC",
    },
    justifyContent: "center",
  },
  noData: {
    fontSize: "1rem",
    lineHeight: "19px",
    marginTop: "1.875rem",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    fontWeight: "900",
    fontSize: "16px",
    fontFamily: "lato",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "81px",
      margin: "0px 0px 0px 0px",
      padding: "15px",
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  overviewCard__AnalyticalDashboard: {
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      width: "100%",
    },
  },
  commanSubGid__AnalyticalDash: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "16px",
      marginLeft: "16px",
      maxWidth: "47%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      marginLeft: "0px",
      marginBottom: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "16px",
      marginLeft: "0px",
    },
  },
  gridCont__AnalyticalDash: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiAccordionDetails-root": {
      padding: "0px 0px 0px ",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
    },
  },
  coommanAccSumm__AnalyticsDashboard: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  subGridWebUi__gridContainer: {
    display: "flex",
    border: "1px solid #D7D7D7",
    borderRadius: "4px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  accordionContainer: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      borderRadius: "4px",
      "&.MuiAccordion-root:before": {
        backgroundColor: "white",
        display: "none",
      },
    },
  },
  commanAccSummury: {
    width: "100%",
  },
  noUserFound: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
      height: "20vh !important",
    },
    [theme.breakpoints.up("xs")]: {
      height: "20vh !important",
    },
  },
  title__commanAccSummury: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      fontWeight: "900",
    },
  },
  hrMenuCont__AnalyticalDash: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      margin: "23px -25px 20px -25px",
    },
  },
  selectedCoreValCont: {
    display: "flex",
    width: "fit-content",
    alignItems: "center",
    justifyContent: "space-between",
  },
  minHeight :{
    // minHeight :'1440px',
  }
}));

export default () => {
  const classes = useStyles();
  const [loadingTopMembers, setLoadingTopMembers] = useState(true);
  const [loadingBudget, setLoadingBudget] = useState(true);
  const [loadingEnagagement, setLoadingEngagement] = useState(true);
  const [loadingCoreValue, setLoadingCoreValue] = useState(true);
  const [selectedCoreValue, setSelectedCoreValue] = useState([]);
  const [topMembers, setTopMembers] = useState([]);
  const [engagementReport, setEngagementReport] = useState([]);
  const [budgetReport, setBudgetReport] = useState([]);
  const [coreValuesAnalysis, setCoreValuesAnalysis] = useState([]);
  const [teamOverview, setTeamOverview] = useState();
  const [anchorElCV, setAnchorElCV] = useState(null);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [coreValueOptions, setCoreValueOptions] = useState([]);
  const [pendingCoreValue, setPendingCoreValue] = useState([]);
  const [expanded, setExpanded] = React.useState("panel1");
  const [handlePopUp, setHandlePopUp] = React.useState(false);
  const [date, setDate] = useState([
    {
      startDate: subQuarters(startOfQuarter(new Date()), 1),
      // endDate: new Date(),
      endDate: subQuarters(lastDayOfQuarter(new Date()), 1), 
      key: "selection",
    },
  ]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const fetchQuarterLabel = (quarter) => {
    if (quarter === 1) {
      return "Jan - Mar";
    } else if (quarter === 2) {
      return "Apr - June";
    } else if (quarter === 3) {
      return "Jul - Sep";
    } else {
      return "Oct - Dec";
    }
  };

  const fetchQuaterData = () => {
    const quarterData = [];
    for (let i = 0; i <= 4; i++) {
      quarterData.push({
        label:
          "Q" +
          getQuarter(subQuarters(startOfQuarter(new Date()), i)) +
          " (" +
          fetchQuarterLabel(
            getQuarter(subQuarters(startOfQuarter(new Date()), i))
          ) +
          ", " +
          getYear(subQuarters(startOfQuarter(new Date()), i)) +
          ")" +
          (i === 0 ? " (Current)" : "") + 
          (i === 1 ? " (Previous)" : "") ,
        range: () => ({
          startDate: subQuarters(startOfQuarter(new Date()), i),
          endDate:
            i === 0 ? new Date() : subQuarters(lastDayOfQuarter(new Date()), i),
        }),
      });
    }
    // quarterData.push({
    //   label: "Custom Range",
    //   range: () => ({
    //     startDate: subQuarters(startOfQuarter(new Date()), 0),
    //     endDate: new Date(),
    //   }),
    // });
    return quarterData;
  };

  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };

  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }

  const defaultStaticRanges = createStaticRanges(fetchQuaterData());

  /* helper function for getting to Date and from date from filter*/
  const getDateRange = (date) => {
    let fromDate = moment(date[0].startDate).format("YYYY-MM-DD");
    let toDate = moment(date[0].endDate).format("YYYY-MM-DD");

    return { fromDate, toDate };
  };
  /* core value filter handler*/
  const handleCoreValueClick = (event) => {
    setAnchorElCV(event.currentTarget);
  };

  const handleCoreValueClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    if (anchorElCV) {
      anchorElCV.focus();
    }
    setAnchorElCV(null);
  };
  const handleClickDate = (event) => {
    setAnchorElDate(event.currentTarget);
  };

  const handleCloseDate = () => {
    setAnchorElDate(null);
  };
  const open = Boolean(anchorElDate);
  const id = open ? "simple-popover" : undefined;
  const openCoreValueFilter = Boolean(anchorElCV);

  /* API Calls */

  const getTopMembers = async (fromDate, toDate) => {
    setLoadingTopMembers(true);
    try {
      const { data } = await apiServices.get(apiConstant.TOP_EARNERS, {
        coreValues: selectedCoreValue,
        fromDate: fromDate,
        toDate: toDate,
      });
      if (data) {
        setTopMembers(data.topCharts);
      }
    } catch {
      console.log("error");
    }
    setLoadingTopMembers(false);
  };
  const getBudgetReport = async (fromDate, toDate) => {
    setLoadingBudget(true);
    try {
      const { data } = await apiServices.get(apiConstant.BUDGET_REPORT, {
        fromDate: fromDate,
        toDate: toDate,
      });
      if (data) {
        setBudgetReport(data);
      }
      setLoadingBudget(false);
    } catch {
      console.log("error");
      setLoadingBudget(false);
    }
  };

  const getEngagementReport = async (fromDate, toDate) => {
    setLoadingEngagement(true);
    try {
      const { data } = await apiServices.get(apiConstant.ENGAGEMENT_REPORT, {
        fromDate: fromDate,
        toDate: toDate,
      });
      if (data) {
        setEngagementReport(data);
      }
      setLoadingEngagement(false);
    } catch {
      console.log("error");
      setLoadingEngagement(false);
    }
  };
  const getCoreValuesAnalysis = async (fromDate, toDate) => {
    setLoadingCoreValue(true);
    try {
      const { data } = await apiServices.get(apiConstant.CORE_VALUES_ANALYSIS, {
        fromDate: fromDate,
        toDate: toDate,
        coreValues: selectedCoreValue,
      });
      if (data) {
        setCoreValuesAnalysis(data);
      }
      setLoadingCoreValue(false);
    } catch {
      console.log("error");
      setLoadingCoreValue(false);
    }
  };
  const getCoreValues = async () => {
    try {
      const { data } = await apiServices.get(apiConstant.CORE_VALUES);
      if (data) {
        data.coreValues.sort((a, b) => (a.name > b.name ? 1 : -1));
        // eslint-disable-next-line
        data.coreValues.map((item) => {
          setCoreValueOptions((previousChipData) => [
            ...previousChipData,
            item.name,
          ]);
        });
      }
    } catch {
      console.log("error");
    }
  };
  const getTeamOverview = async () => {
    try {
      const { data } = await apiServices.get(apiConstant.TEAM_OVERVIEW);
      if (data) {
        setTeamOverview(data);
      }
    } catch {
      console.log("error");
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handlePendingVal = (value) => {
    // console.log(value);
    setPendingCoreValue(value);
  };

  const HandlePopUp = () => {
    setHandlePopUp(!handlePopUp);
  };

  useEffect(() => {
    getCoreValues();
    getTeamOverview();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let { fromDate, toDate } = getDateRange(date);
    setTimeout(() => {
      getTopMembers(fromDate, toDate);
      getBudgetReport(fromDate, toDate);
      getEngagementReport(fromDate, toDate);
      getCoreValuesAnalysis(fromDate, toDate);
    }, 3000);
    // eslint-disable-next-line
  }, [date, selectedCoreValue]);

  return (
    <div className={styles.mainContainer}>
      <DashboardNavigation />
      {!loadingTopMembers &&
      !loadingBudget &&
      !loadingEnagagement &&
      !loadingCoreValue ? (
      <>
        <div className={styles.overview}>
          <Grid container xs={(12, { spacing: 0 })} sm={12} md={12} spacing={3} className={classes.minHeight}>
            <Grid
              item
              xs={6}
              sm={(6, { padding: "0px 12px 12px 0px" })}
              md={3}
              className={classes.overviewCard__AnalyticalDashboard}
            >
              <Paper className={classes.paper}>
                <Typography className={styles.overviewText} >
                  Total Rewards
                </Typography>
                <Typography className={styles.overviewText1}>
                  {teamOverview?.totalRewards}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              xs={6}
              sm={(6, { padding: "0px 12px 12px 0px" })}
              md={3}
              className={`${classes.overviewCard__AnalyticalDashboard} ${classes.overviewCard}`}
            >
              <Paper className={classes.paper}>
                <Typography className={styles.overviewText}>
                  Rewarded PeerFives
                </Typography>
                <Typography className={styles.overviewText2}>
                  {teamOverview?.rewardedPeerfives}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              xs={6}
              sm={(6, { padding: "0px 12px 12px 0px" })}
              md={3}
              className={classes.overviewCard__AnalyticalDashboard}
            >
              <Paper className={classes.paper}>
                <Typography className={styles.overviewText}>
                  Redeemed PeerFives
                </Typography>
                <Typography className={styles.overviewText3}>
                  {teamOverview?.redeemedRewards}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              xs={6}
              sm={(6, { padding: "0px 12px 12px 0px" })}
              md={3}
              className={`${classes.overviewCard__AnalyticalDashboard} ${classes.overviewCard}`}
            >
              <Paper className={classes.paper}>
                <Typography className={styles.overviewText}>
                  Remaining PeerFives
                </Typography>
                <Typography className={styles.overviewText4}>
                  {teamOverview?.remainingPeerfives}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <div className={styles.menuContainer}>
          <Typography className={styles.heading}>Activity Insights</Typography>

          <Button
            className={styles.settingsBtn__AnalyticDashboard}
            onClick={HandlePopUp}
          >
            <TuneTwoTone />
          </Button>
          <FilterContMobile
            handlePendingVal={handlePendingVal}
            setSelectedCoreValue={setSelectedCoreValue}
            allCoreVal={coreValueOptions}
            popUpVal={handlePopUp}
            setPopUp={setHandlePopUp}
            handleCoreValueClose={handleCloseDate}
            setDate={setDate}
          />
          <div className={styles.filterContainer}>
            <div className={styles.coreValueFilter}>
              <Button
                className={classes.select}
                endIcon={<ExpandMoreIcon />}
                onClick={handleCoreValueClick}
              >
                <div className={classes.selectedCoreValCont}>
                  Core Values
                  {selectedCoreValue.length > 0 ? (
                    <p className={classes.filterCount}>
                      ({selectedCoreValue.length})
                    </p>
                  ) : null}
                </div>
              </Button>
              <Popper
                open={openCoreValueFilter}
                anchorEl={anchorElCV}
                placement="bottom-start"
                className={classes.popper}
              >
                <Autocomplete
                  open
                  onClose={handleCoreValueClose}
                  multiple
                  classes={{
                    paperAutocomplete: classes.paperFilter,
                    option: classes.option,
                    popperDisablePortal: classes.popperDisablePortal,
                  }}
                  value={pendingCoreValue}
                  onChange={(event, newCoreValue) => {
                    setPendingCoreValue(newCoreValue);
                    setSelectedCoreValue(newCoreValue);
                  }}
                  disableCloseOnSelect
                  disablePortal
                  renderTags={() => null}
                  noOptionsText="Corevalue not found"
                  renderOption={(option, { selected }) => (
                    <>
                      <div className={classes.filterCheckIcon}>
                        <Checkbox
                          icon={icon}
                          color="default"
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                      </div>
                      <div className={classes.text}>{option}</div>
                    </>
                  )}
                  options={coreValueOptions}
                  renderInput={(params) => (
                    <InputBase
                      startAdornment={<SearchIcon />}
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      autoFocus
                      className={classes.inputBase}
                    />
                  )}
                />
              </Popper>
            </div>
            <div>
              <div>
                <Button
                  className={classes.select}
                  endIcon={<ExpandMoreIcon />}
                  onClick={handleClickDate}
                >
                  {moment(date[0].startDate).format("MMM DD, YY")} -
                  {moment(date[0].endDate).format("MMM DD, YY")}
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorElDate}
                  onClose={handleCloseDate}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <DateRangePicker
                    onChange={(item) => {
                      setDate([item.selection]);
                    }}
                    showSelectionPreview={true}
                    months={1}
                    minDate={addDays(new Date(), -365)}
                    maxDate={addDays(new Date(), 0)}
                    direction="vertical"
                    scroll={{ enabled: true }}
                    ranges={date}
                    staticRanges={defaultStaticRanges}
                  />
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <hr className={classes.hrMenuCont__AnalyticalDash} />
        
        <Box className={styles.gridContainer}>
          <Grid container className={classes.gridCont__AnalyticalDash}>
            <Grid container spacing={0} lg={4} md={4} sm={6} xl={4} xs={12}>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <div className={classes.subGridWebUi__gridContainer}>
                  <CoreValues
                    coreValuesAnalysis={coreValuesAnalysis}
                    fromDate={moment(date[0].startDate).format("YYYY-MM-DD")}
                    toDate={moment(date[0].endDate).format("YYYY-MM-DD")}
                  />
                </div>

                <Accordion
                  className={classes.accordionContainer}
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.commanAccSummury}
                  >
                    <Typography className={classes.title__commanAccSummury}>
                      Core Values
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CoreValues
                      coreValuesAnalysis={coreValuesAnalysis}
                      fromDate={moment(date[0].startDate).format("YYYY-MM-DD")}
                      toDate={moment(date[0].endDate).format("YYYY-MM-DD")}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Grid
              container
              lg={8}
              md={8}
              sm={5}
              xl={8}
              xs={12}
              spacing={isSmall ? 0 : 3}
              className={styles.subGrid}
            >
              <Grid
                item
                md={6}
                lg={6}
                sm={12}
                xl={6}
                xs={12}
                className={classes.commanSubGid__AnalyticalDash}
              >
                <div
                  className={classes.subGridWebUi__gridContainer}
                  style={{ minHeight: "100%" }}
                >
                  <RewardedPeerfives budgetReport={budgetReport} />
                </div>

                <Accordion
                  className={classes.accordionContainer}
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.commanAccSummury}
                  >
                    <Typography className={classes.title__commanAccSummury}>
                      Rewarded PeerFives
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RewardedPeerfives budgetReport={budgetReport} />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                sm={12}
                xl={6}
                xs={12}
                className={classes.commanSubGid__AnalyticalDash}
              >
                <div
                  className={classes.subGridWebUi__gridContainer}
                  style={{ minHeight: "100%" }}
                >
                  <RedeemPeerfives budgetReport={budgetReport} />
                </div>

                <Accordion
                  className={classes.accordionContainer}
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.commanAccSummury}
                  >
                    <Typography className={classes.title__commanAccSummury}>
                      Redeem Peerfives
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RedeemPeerfives budgetReport={budgetReport} />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                sm={12}
                xl={6}
                xs={12}
                className={classes.commanSubGid__AnalyticalDash}
              >
                <div
                  className={classes.subGridWebUi__gridContainer}
                  style={{ minHeight: "100%" }}
                >
                  <TopEarners
                    topMembers={topMembers}
                    fromDate={moment(date[0].startDate).format("YYYY-MM-DD")}
                    toDate={moment(date[0].endDate).format("YYYY-MM-DD")}
                  />
                </div>

                <Accordion
                  className={classes.accordionContainer}
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.commanAccSummury}
                  >
                    <Typography className={classes.title__commanAccSummury}>
                      Top Earners
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TopEarners
                      topMembers={topMembers}
                      fromDate={moment(date[0].startDate).format("YYYY-MM-DD")}
                      toDate={moment(date[0].endDate).format("YYYY-MM-DD")}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                sm={12}
                xl={6}
                xs={12}
                className={classes.commanSubGid__AnalyticalDash}
              >
                <div
                  className={classes.subGridWebUi__gridContainer}
                  style={{ minHeight: "100%" }}
                >
                  <TopGivers
                    topMembers={topMembers}
                    fromDate={moment(date[0].startDate).format("YYYY-MM-DD")}
                    toDate={moment(date[0].endDate).format("YYYY-MM-DD")}
                  />
                </div>

                <Accordion
                  className={classes.accordionContainer}
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.commanAccSummury}
                  >
                    <Typography className={classes.title__commanAccSummury}>
                      Top Givers
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TopGivers
                      topMembers={topMembers}
                      fromDate={moment(date[0].startDate).format("YYYY-MM-DD")}
                      toDate={moment(date[0].endDate).format("YYYY-MM-DD")}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xl={6}
                xs={12}
                className={classes.commanSubGid__AnalyticalDash}
              >
                <div
                  className={classes.subGridWebUi__gridContainer}
                  style={{ minHeight: "100%" }}
                >
                  <PeerfivesEngagement engagementReport={engagementReport} />
                </div>

                <Accordion
                  className={classes.accordionContainer}
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.commanAccSummury}
                  >
                    <Typography className={classes.title__commanAccSummury}>
                      Peerfives Engagement
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PeerfivesEngagement engagementReport={engagementReport} />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
        ) : (
        <div
          className={`flash-loader ${classes.noUserFound}`}
          style={{ margin: "30vh auto" }}
        >
          <img
            src={YellowBG}
            className="logoSquare spinLoader"
            alt="PeerFives"
          />
          <img
            src={PeerFiveBuddy}
            style={{ position: "absolute" }}
            alt="PeerFives"
          />
        </div>
      )}
    </div>
  );
};
