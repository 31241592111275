import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Dialog, Slide } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./DialogModel.module.scss";

const screenSize = window.innerWidth < 600;
const Transition = React.forwardRef((props, ref) => (
  <Slide direction={screenSize ? "up" : "down"} {...props} ref={ref} />
));
class DialogModel extends React.Component {
  // eslint-disable-line
  render() {
    const {
      open,
      onClose,
      children,
      maxWidth,
      title,
      disbleHeader = false,
      ...props
    } = this.props;

    return (
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <Dialog
          fullWidth
          maxWidth={maxWidth}
          PaperProps={{ classes: { root: styles.dialogPaper } }}
          open={open}
          TransitionComponent={Transition}
          onClose={onClose}
          //disableBackdropClick
          aria-labelledby="scroll-dialog-title"
          component="form"
          {...props}
        >
          {!disbleHeader && (
            <AppBar position="static">
              <Toolbar className="flex w-full">
                <Typography className={styles.dialogHeader}>
                  {title}
                  <CloseIcon
                    className={`${styles.handPointer}`}
                    onClick={onClose}
                  />
                </Typography>
              </Toolbar>
            </AppBar>
          )}
          {children}
        </Dialog>
      </div>
    );
  }
}

DialogModel.propTypes = {
  open: PropTypes.bool,
  disbleHeader: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  title: PropTypes.string,
};

DialogModel.defaultProps = {
  title: "Add New Item",
  maxWidth: "lg",
  disbleHeader: false,
  open: false,
};

const FloatingPanelResponsive = withWidth()(DialogModel);
export default withStyles(styles)(FloatingPanelResponsive);
