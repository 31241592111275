import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  DashboardNavigation: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      width: "100%",
      background: "#FFFFFF",
      boxShadow: "0px 4px 10px rgb(0 0 0 / 4%)",
      borderRadius: "3px",
      margin: "3rem 0rem 1.5rem 0rem",
    },
  },
  commanNavTextCon__DashboardNavigation: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "50px",
      cursor: "pointer",
      "&:hover": {
        background: "#EEEEEE",
      },
    },
  },
  commanNavText__Dashboard: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
    },
  },
  commanNavText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "19px",
      letterSpacing: "0em",
    },
  },
  active: {
    background: "#fffadf",
  },
}));

export default function DashboardNavigation() {
  const location = useLocation();
  const locationURL = location.pathname;
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.DashboardNavigation}>
      <Grid
        item
        xs={6}
        onClick={() => {
          history.push({
            pathname: "/dashboard",
          });
        }}
        className={
          locationURL === "/dashboard"
            ? `${classes.active} ${classes.commanNavTextCon__DashboardNavigation}`
            : classes.commanNavTextCon__DashboardNavigation
        }
      >
        <div>
          <Typography
            color="inherit"
            noWrap
            className={classes.commanNavText__Dashboard}
          >
            <Box
              component="span"
              fontWeight="fontWeightBold"
              fontSize={18}
              className={classes.commanNavText}
            >
              Activity Feeds
            </Box>
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        xs={6}
        onClick={() => {
          history.push({
            pathname: "/insights",
          });
        }}
        className={
          locationURL === "/insights"
            ? `${classes.active} ${classes.commanNavTextCon__DashboardNavigation}`
            : classes.commanNavTextCon__DashboardNavigation
        }
      >
        <div
          className={`${classes.paper} ${classes.commanNavTextCon__Dashboard}`}
        >
          <Typography
            color="inherit"
            noWrap
            className={classes.commanNavText__Dashboard}
          >
            <Box
              component="span"
              fontWeight="fontWeightBold"
              fontSize={18}
              className={classes.commanNavText}
            >
              Culture insights
            </Box>
          </Typography>
        </div>
      </Grid>
    </div>
  );
}
