import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Avatar, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./ProfileCard.module.scss";

const ProfileCard = (props) => {
  const { profileData } = props;

  return (
    <div className={styles.card}>
      <ListItem className={styles.detailsCon__ProfileCard}>
        <ListItemIcon>
          <Avatar
            src={profileData?.members?.profile?.image_72}
            className={styles.icon}
          />
        </ListItemIcon>
        <ListItemText
          className={styles.details__ProfileCard}
          primary={
            <>
              <div className={styles.container}>
                <div>
                  <Typography className={styles.name}>Name</Typography>
                  <Typography className={styles.description}>
                    {profileData?.members?.profile?.real_name}
                  </Typography>
                </div>

                <div>
                  <Typography className={styles.name}>Email</Typography>
                  <Typography className={styles.description}>
                    {profileData?.members?.profile?.email}
                  </Typography>
                </div>
              </div>

              {profileData?.members?.designation && (
                <div>
                  <div className={styles.designation}>
                    <Typography className={styles.name}>Designation</Typography>
                    <Typography className={styles.description}>
                      {profileData?.members?.designation}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          }
        />
      </ListItem>
    </div>
  );
};

ProfileCard.propTypes = {
  profileData: PropTypes.node,
};

ProfileCard.defaultProps = {
  profileData: {},
};

export default ProfileCard;
