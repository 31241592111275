import React from "react";
import styles from "./CircularCheck.module.scss";

const CircularCheck = ({ checked }) => {
  return checked ? (
    <div className={styles.circle + " " + styles.circleChecked}>
      <div className={styles.checkmark}></div>
    </div>
  ) : (
    <div className={styles.circle}></div>
  );
};

export default CircularCheck;
