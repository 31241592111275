import React, { useContext, useEffect, useState } from "react";
import { Button, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import StripeCountryCodes from "./StripeCountryCodes";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";
import { useToaster } from "helper/ToasterContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./BillingDetails.module.scss";
import UserContext from "utils/context/UserContext";

// import MuiAccordion from "@material-ui/core/Accordion";
// import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
// import { ReactComponent as OnBoardingCheckboxIcon } from "../../assets/onBoardingCheckbox.svg";
// import { ReactComponent as OnBoardingCheckboxCheckedIcon } from "../../assets/onBoardingCheckboxChecked.svg";
// import { ReactComponent as DropDownIcon } from "../../assets/dropDownIcon.svg";

// const Accordion = withStyles({
//   root: {
//     borderTop: "1px solid #d7d7d7",
//     boxShadow: "none",
//     "&:not(:last-child)": {
//       borderBottom: 0,
//     },
//     "&:before": {
//       display: "none",
//     },
//     "&$expanded": {
//       margin: "auto",
//     },
//   },
//   expanded: {},
// })(MuiAccordion);

// const AccordionSummary = withStyles({
//   root: {
//     padding: "0px",
//     marginBottom: -1,
//     minHeight: 56,
//     "&$expanded": {
//       minHeight: 56,
//     },
//   },
//   content: {
//     "&$expanded": {
//       margin: "12px 0",
//       alignSelf: "center",
//     },
//   },
//   expanded: {},
// })(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: "none",
    "& .MuiAccordion-rounded:first-child": {
      borderRadius: "0px",
    },
  },
  selectCountry: {
    "&.Mui-focused": {
      border: "1px solid green",
    },
    "&:focus": {
      backgroundColor: "white",
    },
  },
  formControl: {
    display: "contents",
    marginRight: "32px",
    "& .MuiOutlinedInput-root:hover": {
      border: "none",
    },
  },
}));

const BillingDetails = (props) => {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const {
    from,
    setOnboardingStatus,
    handleActivateScreen,
    handleSkip,
    prefilledDetails,
  } = props;
  // const [panelTwo, setPanelTwo] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const Toaster = useToaster();
  const [billingComplete, setBillingComplete] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    city: prefilledDetails?.billingDetails?.address?.city,
    country: prefilledDetails?.billingDetails?.address?.country?.toUpperCase(),
    line1: prefilledDetails?.billingDetails?.address?.line1,
    postal_code: prefilledDetails?.billingDetails?.address?.postal_code,
    state: prefilledDetails?.billingDetails?.address?.state,
    email: prefilledDetails?.billingDetails?.email,
    name: prefilledDetails?.billingDetails?.name,
    gst: prefilledDetails?.gstNumber[0],
    phone: prefilledDetails?.billingDetails?.phone,
  });

  const createSubscription = async (params) => {
    setIsLoading(true);
    try {
      const { data } = await apiServices.post(
        apiConstant.CREATE_SUBSCRIPTION,
        params
      );
      if (data.status) {
        console.log("subscription created");
        // setOnboardingStatus({ step: params ? "save" : "" }, undefined);
      } else {
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Toaster.openSnackbar(error.response.data.message, "error");
    }
  };
  const updateBillingInfo = async (params) => {
    setIsLoading(true);
    try {
      const { data } = await apiServices.patch(
        apiConstant.UPDATE_BILLING_DETAILS,
        params
      );
      if (data.status) {
        Toaster.openSnackbar(data.message);

        if (from === "onboarding") setOnboardingStatus();
        else if (from === "activateDialog") handleActivateScreen();
        else handleSkip();
      } else {
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
      setIsLoading(false);
    } catch (error) {
      Toaster.openSnackbar(error.response.data.message, "error");
      setIsLoading(false);
    }
  };

  const customHandler = async () => {
    if (from == "onboarding") {
      let requestJson = {
        billing_details: {
          email: user.members.profile.email,
          name: user.members.profile.real_name,
        },
      };
      createSubscription();
      updateBillingInfo(requestJson);
    } else {
      let requestJson = {
        billing_details: {
          address: {
            city: billingDetails.city,
            country: billingDetails.country,
            line1: billingDetails.line1,

            postal_code: billingDetails.postal_code,
            state: billingDetails.state,
          },
          email: billingDetails.email,
          name: billingDetails.name,
          phone: billingDetails.phone,
        },
      };
      if (billingDetails.gst) {
        requestJson["customerGST"] = billingDetails.gst;
      }
      updateBillingInfo(requestJson);
    }
  };

  // const handlePanelTwo = () => {
  //   setPanelTwo(!panelTwo);
  // };

  const HandleError = (e) => {
    if (
      !e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setError("Invalid email");
    } else {
      setError("");
    }
  };

  const ErrorMessage = ({ children }) => (
    <div className={styles.errorMessage} role="alert">
      <svg width="16" height="16" viewBox="0 0 17 17">
        <path
          fill="#ffffff"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        />
        <path
          fill="#A0A0A0"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        />
      </svg>
      {children}
    </div>
  );

  useEffect(() => {
    if (
      billingDetails.city &&
      billingDetails.country &&
      billingDetails.line1 &&
      billingDetails.postal_code &&
      billingDetails.state &&
      billingDetails.email &&
      billingDetails.name &&
      billingDetails.phone &&
      !error
    )
      setBillingComplete(true);
    else setBillingComplete(false);
  }, [billingDetails]);
  return (
    <div>
      {/* <Accordion
        expanded={panelTwo}
        onChange={handlePanelTwo}
        className={classes.accordion}
      >
        <AccordionSummary
          expandIcon={<DropDownIcon />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          {billingComplete ? (
            <div className={styles.logoChecked}>
              <OnBoardingCheckboxCheckedIcon className={styles.Logo} />{" "}
            </div>
          ) : (
            <OnBoardingCheckboxIcon className={styles.Logo} />
          )}
         
        </AccordionSummary> */}
      <Divider />
      <Typography className={styles.CardDetailsTitle}>Your Details</Typography>
      <form className={styles.BillingForm}>
        <div className={styles.StripeFormFields}>
          <label style={{ marginRight: "24px" }}>
            Full Name
            <input
              name="name"
              type="text"
              placeholder="John Doe"
              value={billingDetails.name}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  name: e.target.value,
                });
              }}
            />
            {/* <TextField
                id="outlined-basic"
                name="name"
                placeholder="John Doe"
                variant="outlined"
                value={billingDetails.name}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    name: e.target.value,
                  });
                }}
                classes={{ select: classes.selectCountry }}
              /> */}
          </label>

          <label>
            Email
            <input
              name="email"
              type="text"
              placeholder="john@abc.com"
              value={billingDetails.email}
              onChange={(e) => {
                HandleError(e);
                setBillingDetails({
                  ...billingDetails,
                  email: e.target.value,
                });
              }}
            />
          </label>
        </div>
        <div className={styles.InlineLabel}>
          <label>
            Address Line
            <input
              name="address"
              type="text"
              placeholder="Ex: Street Name"
              required
              value={billingDetails.line1}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  line1: e.target.value,
                });
              }}
            />
          </label>
        </div>
        <div className={styles.StripeFormFields}>
          <label style={{ marginRight: "24px" }}>
            City
            <input
              name="city"
              type="text"
              placeholder="City"
              value={billingDetails.city}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  city: e.target.value,
                });
              }}
            />
          </label>
          <label>
            State
            <input
              name="state"
              type="text"
              placeholder="State"
              value={billingDetails.state}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  state: e.target.value,
                });
              }}
            />
          </label>
        </div>
        <div className={styles.StripeFormFields}>
          <label style={{ marginRight: "24px" }}>
            Country
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={billingDetails.country}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    country: e.target.value,
                  });
                }}
                className={styles.selectCountry}
                classes={{ select: classes.selectCountry }}
              >
                {StripeCountryCodes.country &&
                  StripeCountryCodes.country.map((e, key) => {
                    return <MenuItem value={e.code}>{e.country}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </label>
          <label>
            Pin Code
            <input
              name="pincode"
              type="text"
              placeholder="Postal Code"
              value={billingDetails.postal_code}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  postal_code: e.target.value,
                });
              }}
            />
          </label>
        </div>
        <div className={styles.StripeFormFields}>
          <label style={{ marginRight: "24px" }}>
            {/* GST Number (optional) */}
            VAT/GST Number (optional)
            <input
              name="gst"
              type="text"
              placeholder="VAT/GST Number (optional)"
              value={billingDetails.gst}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  gst: e.target.value,
                });
              }}
            />
          </label>
          <label>
            Contact Number
            {/* <input
              name="phone"
              type="number"
              placeholder="Contact Number"
              value={billingDetails.phone}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  phone: e.target.value,
                });
              }}
            /> */}
            <PhoneInput
              inputStyle={{ width: "100%", height: "42px" }}
              // containerStyle={{ margin: "4px" }}
              country={"us"}
              value={billingDetails?.phone}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  phone: e,
                })
              }
            />
          </label>
        </div>
      </form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {/* {error ? error : ""} */}

      {/* </Accordion> */}

      <div className={styles.footer}>
        {from === "onboarding" ? (
          <Button
            type="submit"
            disabled={!billingComplete}
            variant="contained"
            className={styles.saveButton}
            onClick={customHandler}
          >
            {isLoading ? <div className="loader" /> : "Save & Next"}
          </Button>
        ) : from === "activateDialog" ? (
          <>
            <Button
              color="primary"
              className={styles.skipButton}
              onClick={handleActivateScreen}
            >
              Go Back
            </Button>
            <Button
              type="submit"
              disabled={!billingComplete}
              variant="contained"
              className={styles.saveButton}
              onClick={customHandler}
            >
              {isLoading ? <div className="loader" /> : "Save"}
            </Button>
          </>
        ) : (
          <Button
            type="submit"
            disabled={!billingComplete}
            variant="contained"
            className={styles.saveButton}
            onClick={customHandler}
          >
            {isLoading ? <div className="loader" /> : "Save "}
          </Button>
        )}
      </div>
    </div>
  );
};

export default BillingDetails;
