import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "69%",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    maxWidth: "1400px",
    margin: "1rem 0rem 0rem 0rem",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0px 8px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "99%",
    },
  },
  cardRoot: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const ActivityFeedCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, "mb-3")}>
      <CardContent>
        <div className={classes.cardRoot}>
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
          <Skeleton animation="wave" width="70%" />
        </div>
        <Skeleton animation="wave" variant="rect" width="100%">
          <div
            style={{
              paddingTop: "10%",
            }}
          />
        </Skeleton>
      </CardContent>
    </Card>
  );
};

export default ActivityFeedCardSkeleton;
