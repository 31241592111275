export const StripeCountryCodes = {
    country : [
        {country: 'Australia', code: 'AU'},
        {country: 'Austria', code: 'AT'},
        {country: 'Belgium', code: 'BE'},
        {country: 'Bulgaria', code: 'BG'},
        {country: 'Brazil ', code: 'BR'},
        {country: 'Canada', code: 'CA'},
        {country: 'Cyprus', code: 'CY'},
        {country: 'Czech Republic', code: 'CZ'},
        {country: 'Denmark', code: 'DK'},
        {country: 'Estonia', code: 'EE'},
        {country: 'Finland', code: 'FI'},
        {country: 'France', code: 'FR'},
        {country: 'Germany', code: 'DE'},
        {country: 'Greece', code: 'GR'},
        {country: 'Hong Kong', code: 'HK'},
        {country: 'Hungary', code: 'HU'},
        {country: 'India', code: 'IN'},
        {country: 'Ireland', code: 'IE'},
        {country: 'Italy', code: 'IT'},
        {country: 'Japan', code: 'JP'},
        {country: 'Latvia', code: 'LV'},
        {country: 'Lithuania', code: 'LT'},
        {country: 'Luxembourg', code: 'LU'},
        {country: 'Malaysia', code: 'MY'},
        {country: 'Malta', code: 'MT'},
        {country: 'Mexico ', code: 'MX'},
        {country: 'Netherlands', code: 'NL'},
        {country: 'New Zealand', code: 'NZ'},
        {country: 'Norway', code: 'NO'},
        {country: 'Poland', code: 'PL'},
        {country: 'Portugal', code: 'PT'},
        {country: 'Romania', code: 'RO'},
        {country: 'Singapore', code: 'SG'},
        {country: 'Slovakia', code: 'SK'},
        {country: 'Slovenia', code: 'SI'},
        {country: 'Spain', code: 'ES'},
        {country: 'Sweden', code: 'SE'},
        {country: 'Switzerland', code: 'CH'},
        {country: 'United Kingdom', code: 'GB'},
        {country: 'United States', code: 'US'}
]
}

export default StripeCountryCodes;