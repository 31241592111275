/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, { useState, useEffect } from "react";
import { Typography, Divider, Tooltip } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import { currencyFormatter } from "utils/services/utility";
import styles from "./Settings.module.scss";
import EditIcon from "../../../../assets/editButton.svg";
import PeerfiveIcon from "../../../../assets/PeerFiveAdminScreen.svg";
import MarketPlace from "../../../../assets/market_place.svg";
import StarModalIcon from "../../../../assets/StarCoreValuesModal.svg";
import ChannelIcon from "../../../../assets/Channel.svg";
import Administrator from "../../../../assets/Administrator.svg";

const ToggleSwitch = withStyles({
  switchBase: {
    padding: "9px !important",
    color: "#EDE9D1 !important",
    "&$checked": {
      color: "#FFC50D",
    },
    "&$checked + $track": {
      backgroundColor: "#EDE9D1",
    },
  },

  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    position: "absolute",
    top: "-80px",
    right: "-47px",
    width: "158px",
    height: "58px",
    backgroundColor: "#E9E6DC",
    color: "black",
    fontSize: "14px",
    textAlign: "center",
    padding: "12px 10px 12px 11px",

    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      top: "-90px",
      right: "-50px",
    },
  },

  arrow: {
    color: "#E9E6DC",
    left: "100px !important",
  },
}));

const Settings = (props) => {
  const {
    tags = [],
    membersData,
    handleCoreValue,
    handelBudgetModel,
    handleAdminModel,
    budgetData,
    setMarketPlace,
    isLoaderMarketPlace,
    handleChannelModal,
    plateformType,
    pendingItems,
    tooltipStatus,
    subscriptionData,
  } = props;
  const [marketPlaceToggle, setMarketPlaceToggle] = useState(
    budgetData?.enableMarketplace
  );
  const classes = useStyles();

  useEffect(() => {
    setMarketPlaceToggle(budgetData?.enableMarketplace);
  }, [budgetData]);

  const handleMarketPlaceChange = () => {
    if (
      subscriptionData?.planInfo === "" ||
      subscriptionData?.subscriptionList[0]?.status === "canceled"
    ) {
      return;
    } else {
      setMarketPlace(!marketPlaceToggle);
      setMarketPlaceToggle(!marketPlaceToggle);
    }
  };

  return (
    <>
      <div>
        <div
          className={`${styles.creditCardContent} ${styles.administratorCont__Settings}`}
        >
          <div className={styles.viewDetail}>
            <img
              src={Administrator}
              className={styles.creditCardImage}
              alt="Peer Fives"
            />
            <div>
              <Typography className={styles.name}>Administrators</Typography>
              {membersData?.length > 0 ? (
                <div className={styles.chipRoot}>
                  {membersData?.map((data) => {
                    if (data?.peerfiveAdmin)
                      return (
                        <div key={data._id} className={styles.chip}>
                          {data?.profile?.real_name}
                        </div>
                      );
                  })}
                </div>
              ) : (
                <Typography className={styles.notSetYet}>
                  Not Set yet
                </Typography>
              )}
            </div>
          </div>
          <img
            src={EditIcon}
            alt="edit"
            role="presentation"
            className={styles.cursorPointer}
            onClick={handleAdminModel}
          />
        </div>
        <Divider className={styles.firstDivider__Settings} />
        <div
          className={`${styles.creditCardContent} ${styles.configureCoreCon__Settings}`}
        >
          <div className={styles.viewDetail}>
            <img
              src={StarModalIcon}
              className={styles.creditCardImage}
              alt="Peer Fives"
            />
            <div>
              <Typography className={styles.name}>
                Configure Core Values
              </Typography>
              <Typography className={styles.description}>
                {tags.length > 0 ? (
                  <div className={styles.chipRoot}>
                    {tags.map((data) => {
                      return (
                        <div key={data._id} className={styles.chip}>
                          {data}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Typography className={styles.notSetYet}>
                    Not Set yet
                  </Typography>
                )}
              </Typography>
            </div>
          </div>
          <img
            src={EditIcon}
            alt="edit"
            role="presentation"
            className={styles.cursorPointer}
            onClick={handleCoreValue}
          />
        </div>
        <Typography component="span" className={styles.mobileDescription}>
          {tags.length > 0 ? (
            <div className={styles.chipRoot}>
              {tags.map((data) => {
                return (
                  <div key={data._id} className={styles.chip}>
                    {data}
                  </div>
                );
              })}
            </div>
          ) : (
            <Typography className={styles.notSetYet}>Not Set yet</Typography>
          )}
        </Typography>
        <Divider className={styles.secondDivider__Settings} />
        <div
          className={`${styles.creditCardContent} ${styles.configureChennalCon__Settings}`}
        >
          <div className={styles.viewDetail}>
            <img
              src={ChannelIcon}
              className={styles.creditCardImage}
              alt="Peer Fives"
            />
            <div>
              <Typography className={styles.name}>Configure Channel</Typography>
              <Typography className={styles.description}>
                #
                {plateformType === "slack" &&
                  budgetData?.notificationChannel?.name
                    ?.charAt(0)
                    .toUpperCase() +
                    budgetData?.notificationChannel?.name?.slice(1)}
                {plateformType === "teams" &&
                  budgetData?.notificationChannel?.displayName
                    ?.charAt(0)
                    .toUpperCase() +
                    budgetData?.notificationChannel?.displayName?.slice(1)}
                 {plateformType === "discord" &&
                  budgetData?.notificationChannel?.displayName
                    ?.charAt(0)
                    .toUpperCase() +
                    budgetData?.notificationChannel?.displayName?.slice(1)}
              </Typography>
            </div>
          </div>
          <img
            src={EditIcon}
            alt="edit"
            role="presentation"
            className={styles.cursorPointer}
            onClick={handleChannelModal}
          />
        </div>
        <Divider className={styles.thirdDivider__Settings} />
        <div
          className={`${styles.creditCardContent} ${styles.p5BudgetCont__Settings}`}
        >
          <div className={styles.viewDetail}>
            <img
              src={PeerfiveIcon}
              className={styles.creditCardImage}
              alt="Peer Fives"
            />
            <div>
              <Typography className={styles.name}>
                PeerFives & Budget
              </Typography>
              <Typography className={styles.description}>
                Budget:{" "}
                {pendingItems.isPendingItems && pendingItems.teamBudget ? (
                  <div style={{ display: "inline-block" }}>
                    <Typography className={styles.lightDescription}>
                      Not Set{" "}
                      <div className="notification_dot">
                        <span />
                        <span />
                        <span />
                      </div>
                    </Typography>
                  </div>
                ) : (
                  currencyFormatter(
                    budgetData?.totalPeerfivePoints *
                      budgetData?.pointsConversionRate,
                    budgetData?.defaultCurrency
                  )
                )}{" "}
                | Employee: {budgetData?.totalMembers} | 1 PeerFive ={" "}
                {pendingItems.isPendingItems &&
                pendingItems.pointsConversionRate ? (
                  <div style={{ display: "inline-block" }}>
                    <Typography className={styles.lightDescription}>
                      Not Set{" "}
                      <div className="notification_dot">
                        <span />
                        <span />
                        <span />
                      </div>
                    </Typography>
                  </div>
                ) : (
                  currencyFormatter(
                    budgetData?.pointsConversionRate,
                    budgetData?.defaultCurrency
                  )
                )}
              </Typography>
            </div>
          </div>
          <Tooltip
            id="settingsTooltip"
            classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}
            title={
              pendingItems.isPendingItems &&
              pendingItems.pointsConversionRate &&
              !tooltipStatus
                ? "Please Set the Value of 1 PeerFives Point"
                : ""
            }
            placement="top"
            arrow
            open
          >
            <img
              src={EditIcon}
              alt="edit"
              className={styles.cursorPointer}
              role="presentation"
              onClick={handelBudgetModel}
            />
          </Tooltip>
        </div>
        <Typography component="span" className={styles.mobileDescription}>
          Budget:
          {currencyFormatter(
            budgetData?.teamBudget,
            budgetData?.defaultCurrency
          )}
          <br />
          Employee: {budgetData?.totalMembers} <br />1 PeerFive =
          {currencyFormatter(
            budgetData?.pointsConversionRate,
            budgetData?.defaultCurrency
          )}
        </Typography>
        <Divider className={styles.forthDivider__Settings} />
        <div
          className={`${styles.creditCardContent} ${styles.marketPlaceCont__Settings}`}
        >
          <div className={styles.viewDetail}>
            <img
              src={MarketPlace}
              className={styles.creditCardImage}
              alt="Peer Fives"
            />
            <div>
              <Typography className={styles.name}>Market Place</Typography>
              <Typography className={styles.description}>
                Toggle switch on/off to enable/disable employees to use the
                market place.
              </Typography>
            </div>
          </div>
          {/* <img
          src={EditIcon}
          alt="edit"
          className={styles.cursorPointer}
          role="presentation"
          onClick={handelBudgetModel}
        /> */}
          {isLoaderMarketPlace ? (
            <div className={styles.loaderDiv}>
              <div className="loader" />
            </div>
          ) : (
            <div className={styles.switchDiv}>
              <Typography className={styles.enabledDescription}>
                {marketPlaceToggle ? "Enabled" : "disabled"}
              </Typography>
              <ToggleSwitch
                disabled={
                  subscriptionData?.planInfo === "" ||
                  subscriptionData?.subscriptionList[0]?.status === "canceled"
                }
                checked={marketPlaceToggle}
                onChange={handleMarketPlaceChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
          )}
        </div>
        <Typography component="span" className={styles.mobileDescription}>
          Toggle switch on/off to enable/disable employees to use the market
          place.
        </Typography>
      </div>
    </>
  );
};

Settings.propTypes = {
  tags: PropTypes.shape([]),
  handleCoreValue: PropTypes.func.isRequired,
  handelBudgetModel: PropTypes.func.isRequired,
  handleAdminModel: PropTypes.func.isRequired,
};

Settings.defaultProps = {
  tags: [],
};

export default Settings;
