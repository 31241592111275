import React, { useState } from "react";
import {
  Button,
  Typography,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputBase,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import styles from "./ChannelDialog.module.scss";
import ChannelIcon from "../../../../assets/Channel.svg";
import iconClose from "../../../../assets/icon_close.svg";
import * as apiServices from "../../../../utils/services/apiServices";
import * as apiConstant from "../../../../utils/constant/apiConstant";
import { useToaster } from "../../../../helper/ToasterContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "contents",
    marginRight: "32px",
    "& .MuiOutlinedInput-root:hover": {
      border: "none",
    },
  },
  selectChannel: {
    width: "100%",
    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
      borderRaduis: "4px",
      borderColor: "#d7d7d7",
    },
  },
}));
const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRaduis: "4px solid #d7d7d7",
    position: "relative",
    border: "1px solid #d7d7d7",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#d7d7d7",
    },
  },
}))(InputBase);
const ChannelDialog = (props) => {
  const Toaster = useToaster();
  const classes = useStyles();
  const { channelList, selectChannel, onClose, plateformType } = props;
  const closeDialog = () => {
    onClose(true);
  };

  const channelName =
    plateformType === "slack"
      ? selectChannel?.name
      : selectChannel?.displayName;

  const [updateChannel, setUpdateChannel] = useState({
    name: channelName,
    id: plateformType === "slack"
      ? selectChannel?.id
      : selectChannel?.groupId
  });
  const [loading, setLoading] = useState();
  const channelHandler = async () => {
    setLoading(true);
    try {
      const { data } = await apiServices.patch(apiConstant.CHANNEL_UPDATE, {
        channel: updateChannel,
      });
      if (data.status) {
        Toaster.openSnackbar("Channel Updated successfully.");
      }
      setLoading(false);
      closeDialog();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        <img className={styles.logoIcon} src={ChannelIcon} alt="PeerFives" />
        <div className={styles.headerTitleContainer}>
          <Typography className={styles.headerTitle}>
            Configure Channel
          </Typography>
        </div>
        <div onClick={closeDialog} onKeyDown={closeDialog} role="presentation">
          <img className={styles.closeIcon} src={iconClose} alt="close" />
        </div>
      </div>

      <div className={styles.divider} />
      <div className={styles.bodyContainer}>
        <Divider />
        <Typography className={styles.CardDetailsTitle}>
          Please Select Slack/Teams Channel
        </Typography>
        <Typography className={styles.suggestionText}>Channels</Typography>
        <form className={styles.BillingForm}>
          <div className={styles.InlineLabel}>
            <label>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={updateChannel.id}
                  input={<BootstrapInput />}
                  onChange={(e) => {
                    const clickedOption = channelList?.find(
                      (item) => item?.id === e.target.value
                    );

                    setUpdateChannel({
                      name: clickedOption.name,
                      id: clickedOption.id,
                    });
                  }}
                  className={classes.selectChannel}
                >
                  {channelList &&
                    channelList?.map((e, key) => {
                      return (
                        <MenuItem key={e.id} value={e.id}>
                          #
                          {e?.name?.charAt(0).toUpperCase() + e?.name?.slice(1)}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </label>
          </div>
        </form>
        <Typography className={styles.consentText}>
          We propose choosing a shared channel where all team members are
          present, so that PeerFives Recognition messages can be seen by
          everyone.
        </Typography>
        {/* </Accordion> */}

        <div className={styles.footer}>
          <Button
            type="submit"
            variant="contained"
            className={styles.saveButton}
            onClick={channelHandler}
          >
            {loading ? <div className="loader" /> : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

ChannelDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ChannelDialog.defaultProps = {};

export default ChannelDialog;
