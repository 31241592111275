import { Typography, Button, CircularProgress, Box } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import slack from "assets/slack.svg";
import logo from "assets/icons/logo.svg";
import Apicall from "utils/services/apiCall";
import * as storageServices from "utils/services/storageServices";
import { useToaster } from "helper/ToasterContext";
import styles from "./Login.module.scss";
import MSTeamIcon from "../../assets/MSTeamsIcon.svg";
import UserContext from "../../utils/context/UserContext";
import * as apiServices from "../../utils/services/apiServices";
import * as apiConstant from "../../utils/constant/apiConstant";

const Login = (props) => {
  const [loader, setLoading] = useState(false);
  const Toaster = useToaster();
  const { setUser } = useContext(UserContext);
  const currentYear = moment().year();
  useEffect(() => {
    async function fetchData() {
      const searchParams = new URLSearchParams(props.location.search);
      const code = searchParams.get("code");
      const token = searchParams.get("token");
      try {
        if (code) {
          setLoading(true);
          const { data } = await Apicall.login(code);
          if (data.user && data.token) {
            storageServices.add(storageServices.TOKEN, data.token);
            const userData = await apiServices.get(apiConstant.PROFILE);
            setUser(userData.data);
            Toaster.openSnackbar("Logged in Successfully", "success");
            setLoading(false);
            props.history.push("/dashboard");
          } else {
            Toaster.openSnackbar(
              "Sorry no user found with this credentials",
              "error"
            );
            setLoading(false);
          }
        } else if (token) {
          if (localStorage.getItem("token")) localStorage.removeItem("token");
          else storageServices.add(storageServices.TOKEN, token);
          const userData = await apiServices.get(apiConstant.PROFILE);
          if (userData?.data) {
            setUser(userData?.data);
            Toaster.openSnackbar("Logged in Successfully", "success");
            setLoading(false);
            props.history.push("/dashboard");
          }
        }
      } catch {
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={styles.mainContainer}>
      <img className={styles.peerFivesLogo} alt="PeerFives" src={logo} />
      <div className={styles.cardContainer}>
        {loader ? (
          <Box className={styles.slackLoading}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography className={styles.title}>Welcome!</Typography>

            <Typography className={styles.description}>
              Please sign in with your Slack account to access your team's
              PeerFives dashboard.
            </Typography>

            <Button
              variant="contained"
              className={styles.slackButton}
              href={process.env.REACT_APP_LOGIN_HREF_URL}
            >
              <img
                alt="Sign in with Slack"
                src={slack}
                className={styles.slackImage}
              />
              Login with <span className={styles.slackBold}>Slack</span>
            </Button>
            <Button
              variant="contained"
              className={`${styles.slackButton} ${styles.msteamsBtn}`}
              href={process.env.REACT_APP_LOGIN_WITH_MSTEAMS}
            >
              <img
                alt="Sign in with MS Teams"
                src={MSTeamIcon}
                className={styles.slackImage}
              />
              Login with <span className={styles.slackBold}>MS-Teams</span>
            </Button>

            <Button
              variant="contained"
              className={`${styles.slackButton} ${styles.msteamsBtn}`}
              href={process.env.REACT_APP_LOGIN_WITH_DISCORD}
            >
              {/* <img
                alt="Sign in with Discord"
                src={DiscordIcon}
                className={styles.discordImage}
              /> */}
              Login with <span className={styles.slackBold}>Discord</span>
            </Button>

            <Typography className={styles.footerTitle}>
              Having problems signing in?
              <a href={process.env.REACT_APP_CONTACT_US}>
                <span className={styles.contactus}> Contact us.</span>
              </a>
            </Typography>
          </>
        )}
      </div>
      <Typography className={styles.description}>
        © {currentYear} PeerFives, LLC.
      </Typography>
    </div>
  );
};

export default withRouter(Login);
