import React from "react";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@material-ui/core/styles";
import { FiberManualRecord } from "@material-ui/icons";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  indicatorContainer: {
    textAlign: "center",
  },
  indicator: {
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
    opacity: 0.5,
    marginRight: theme.spacing(1),
    transition: "opacity 0.3s ease",
  },
  activeIndicator: {
    opacity: 1,
  },
  carouselDiv: {
    height: "100%",

    "& div:nth-child(1)": {
      height: "100%",
      "& div:nth-child(1)": {
        height: "100%",
      },
    },
  },
  imageAndNote: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  noteDiv: {
    backgroundColor: "#EFEEFF",
    borderRadius: "5px",
    padding: "4px 10px",
    textAlign: "center",
  },
  noteFont: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#7773FF",
  },
  noteBoldFont: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#7773FF",
  },
}));

const ImageCarousel = ({ images }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={classes.carouselDiv}>
        <Carousel
          autoPlay={false}
          animation="slide"
          indicators={false}
          index={activeStep}
          onChange={handleStepChange}
        >
          {images.map((image, index) => (
            <div className={classes.imageAndNote}>
              <img key={index} src={image.src} alt={`Image ${index + 1}`} />
              <div className={classes.noteDiv}>{image.element}</div>
            </div>
          ))}
        </Carousel>
      </div>

      <div className={classes.indicatorContainer}>
        {images.map((_, index) => (
          <HorizontalRuleIcon
            key={index}
            className={`${classes.indicator} ${
              index === activeStep ? classes.activeIndicator : ""
            }`}
            onClick={() => handleStepChange(index)}
          >
            <FiberManualRecord />
          </HorizontalRuleIcon>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
