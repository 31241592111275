import { Grid, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import UserContext from "../../../../utils/context/UserContext";

const useStyles = makeStyles((theme) => ({
  MobileNavSetting: {
    display: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      overflowX: "scroll",
      position: "fixed",
      backgroundColor: "#f8f8f8",
      top: "3rem",
      zIndex: "10",
      width: "100vw",
    },
  },
  Tabs__MobileNavSetting: {
    borderBottom: "1px solid  #E9E9E9",
    width: "100vw",
  },
  Tabs__MobileNavAltSetting: {
    borderBottom: "1px solid  #E9E9E9",
    width: "100vw",
    "& > .MuiTabs-scroller": {
      display: "flex",
      justifyContent: "center",
    },
    "& > .MuiTabs-scroller > .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
      width: "96%",
    },
  },
  activeLink: {
    color: "black",
    fontSize: "16px",
    fontWeight: "900",
    borderBottom: "3px solid #FFC50D",
    textDecoration: "none",
    whiteSpace: "nowrap",
  },
  nonActiveLink: {
    color: "black",
    fontSize: "14px",
    fontWeight: "400",
    whiteSpace: "nowrap",
  },
  cardCont: {
    textDecoration: "none",
    margin: "0px 10px",
  },
}));

function MobileNavSetting({ getPath }) {
  const { user, setUser } = useContext(UserContext);
  const classes = useStyles();
  const location = useLocation();
  const currPath = location.pathname;
  const hashOfPath = location.hash;
  const [value, setValue] = React.useState("three");
  const [isAdmin, setIsAdmin] = React.useState();
  const initialPath = `${currPath}${hashOfPath}`;
  const [selectedUrl, setSelectedUrl] = useState(
    hashOfPath ? initialPath : `/settings/#leaderboard`
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectedLink = () => {
    let inputEl = document.querySelectorAll(".cardCont");
    for (let i = 0; i < inputEl.length; i++) {
      inputEl[i].classList.remove("activeLink");
      inputEl[i].addEventListener("click", () => {
        setSelectedUrl(inputEl[i].getAttribute("href"));
      });
    }
    getPath(selectedUrl);
  };
  useEffect(() => {
    handleSelectedLink();
  }, [selectedUrl]);

  useEffect(() => {
    user && setIsAdmin(user?.members?.peerfiveAdmin);
  }, [user]);

  return (
    <Grid className={classes.MobileNavSetting}>
      <Tabs
        value={value}
        onChange={handleChange}
        className={
          isAdmin
            ? classes.Tabs__MobileNavSetting
            : classes.Tabs__MobileNavAltSetting
        }
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            borderBottom: "3px solid #FFC50D",
          },
        }}
      >
        {/* leaderboard */}
        <a
          href="/settings/#leaderboard"
          className="cardCont"
          style={{ textDecoration: "none", margin: "0px 10px" }}
        >
          <Tab
            value="one"
            label="Leaderboard"
            className={
              selectedUrl === "/settings/#leaderboard"
                ? `${classes.activeLink}`
                : `${classes.nonActiveLink}`
            }
            style={{ height: "40px" }}
          />
        </a>
        {/* my profile */}
        <a
          href="/settings/#profile"
          className="cardCont"
          style={{ textDecoration: "none", margin: "0px 10px" }}
        >
          <Tab
            value="two"
            label="My Profile"
            className={
              selectedUrl === "/settings/#profile"
                ? `${classes.activeLink}`
                : `${classes.nonActiveLink}`
            }
            style={{ height: "40px" }}
          />
        </a>
        {/* settings */}
        {isAdmin && (
          <a
            href="/settings/#settings"
            className="cardCont"
            style={{ textDecoration: "none", margin: "0px 10px" }}
          >
            <Tab
              value="three"
              label="Settings"
              className={
                selectedUrl === "/settings/#settings"
                  ? `${classes.activeLink}`
                  : `${classes.nonActiveLink}`
              }
              style={{ height: "40px" }}
            />
          </a>
        )}
        {/* subscriptions */}
        {isAdmin && (
          <a
            href="/settings/#subscription"
            className="cardCont"
            style={{ textDecoration: "none", margin: "0px 10px" }}
          >
            <Tab
              value="four"
              label="Subscription"
              className={
                selectedUrl === "/settings/#subscription"
                  ? `${classes.activeLink}`
                  : `${classes.nonActiveLink}`
              }
              style={{ height: "40px" }}
            />
          </a>
        )}
        {/* need help */}
        <a
          href="/settings/#help"
          className="cardCont"
          style={{ textDecoration: "none", margin: "0px 10px" }}
        >
          <Tab
            label="Need Help?"
            value="five"
            className={
              selectedUrl === "/settings/#help"
                ? `${classes.activeLink}`
                : `${classes.nonActiveLink}`
            }
            style={{ height: "40px" }}
          />
        </a>
      </Tabs>
    </Grid>
  );
}

export default MobileNavSetting;
