import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "../../Leaderboard.module.scss";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      width: "40px",
      height: "40px",
    },
  },
  pointTitle: {
    fontSize: "1rem",
    lineHeight: "19px",
    color: "#A0A0A0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: "400",
    },
  },
  pointValue: {
    fontSize: "1rem",
    lineHeight: "19px",
    color: "#000000",
    fontWeight: "900",
    marginTop: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: "900",
    },
  },
  availButton: {
    marginTop: "1rem",
    textTransform: "capitalize",
    backgrounfColor: "black",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: "0px",
      paddingRight: " 0px",
      span: {
        fontSize: "14px",
      },
    },
  },
  userInfoCont__LeaderUserInfo: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  RedeemableP5__LeaderUserInfo: {
    padding: "8px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
    },
  },
}));

const LeaderUserInfo = (props) => {
  const classes = useStyles();
  const { profileData } = props;

  return (
    <Grid className={styles.headerContainer}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        className={styles.userDetailsCont__LeaderUserInfo}
      >
        <Grid item className={styles.userProfileCont__LeaderUserInfo}>
          <Avatar
            src={profileData?.members?.profile?.image_72}
            className={classes.large}
          />
        </Grid>
        <Grid item className={styles.userNameCont__LeaderUserInfo}>
          <Typography className={styles.userNameText__LeaderUserInfo}>
            {profileData?.members?.profile?.real_name} (You)
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mt-3" alignItems="center">
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          xs={5}
          sm={12}
          style={{ paddingRight: "0px" }}
        >
          <Typography className={classes.pointTitle}>
            Earned PeerFives
          </Typography>
          <Typography className={classes.pointValue}>
            {Math.ceil(profileData?.members?.earnedPoints)}
          </Typography>
        </Grid>
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          xs={7}
          sm={12}
          style={{ padding: "8px" }}
        >
          <Typography className={classes.pointTitle}>
            Redeemable PeerFives
          </Typography>
          <Typography className={classes.pointValue}>
            {Math.ceil(profileData?.members?.redeemablePoints)}
          </Typography>
        </Grid>
        <Grid item sm={12} md={7}>
          <Typography className={classes.pointTitle}>
            Balance PeerFives
          </Typography>
          <Typography className={classes.pointValue}>
            {profileData?.balancePoints} / {profileData?.members?.spendingLimit}
          </Typography>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        className={`redeem-btn ${classes.availButton}`}
        disableRipple
        href={process.env.REACT_APP_REDEEM_PEERFIVES}
        disabled={!profileData?.team?.enableMarketplace}
      >
        Redeem PeerFives
      </Button>
    </Grid>
  );
};

LeaderUserInfo.propTypes = {
  profileData: PropTypes.object,
};

LeaderUserInfo.defaultProps = {
  profileData: {},
};

export default LeaderUserInfo;
