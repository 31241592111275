import React from "react";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Avatar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import EmojiConvertor from "emoji-js";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "12px",
    [theme.breakpoints.down("xs")]: {
      width: "30px",
      height: "30px",
    },
  },
  replyThread: {
    backgroundColor: "#F7F7F7",
    fontStyle: "italic",
    textAlign: "justify",
    maxWidth: "fit-content",
  },
  ListItemContainer__ReplyThread: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  profileName__ReplyThread: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: "900",
    },
  },
  message__ReplyThread: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      fontWeight: "400",
      fontStyle: "italic",
    },
  },
}));

const ReplyThread = ({ activity, thread, childIndex }) => {
  const classes = useStyles();
  const emoji = new EmojiConvertor();

  return (
    <ListItem
      alignItems="flex-start"
      className={`py-0 ${classes.ListItemContainer__ReplyThread}`}
    >
      <ListItemAvatar
        style={{
          minWidth: "0px",
        }}
      >
        <Avatar
          className={classes.small}
          src={activity?.messageSender[childIndex]?.profile?.image_32}
          display="inline"
          style={{
            marginRight: "10px",
            marginTop: "10px",
          }}
        />
      </ListItemAvatar>
      <ListItemText
        className={clsx(classes.replyThread, "p-2")}
        primary={
          <Box
            fontWeight="fontWeightBold"
            className={classes.profileName__ReplyThread}
          >
            {activity?.messageSender[childIndex]?.profile?.real_name}
          </Box>
        }
        secondary={
          <Typography
            component="span"
            fontStyle="italic"
            color="textPrimary"
            className={classes.message__ReplyThread}
          >
            {emoji.replace_colons(thread.message)}
          </Typography>
        }
      />
    </ListItem>
  );
};

ReplyThread.propTypes = {
  thread: PropTypes.object,
  childIndex: PropTypes.number,
};

ReplyThread.defaultProps = {
  thread: {},
  childIndex: 0,
};

export default ReplyThread;
