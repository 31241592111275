import { Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import clsx from "clsx";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { onBoardingStages } from "utils/constant/onBoardingStages";
import logo from "../../assets/icons/logo.svg";
import PeerFiveBuddy from "../../assets/icons/PeerFiveBuddy.svg";
import YellowBG from "../../assets/icons/YellowBG.svg";
import history from "../../utils/@history";
import * as apiConstant from "../../utils/constant/apiConstant";
import UserContext from "../../utils/context/UserContext";
import * as apiServices from "../../utils/services/apiServices";
import CardDetails from "./components/CardDetails/OnBoardingOne";
import CoreValue from "./components/CoreValue/CoreValue";
import CustomStepper from "./components/CustomStepper/CustomStepper";
import PointAllocationForm from "./components/PointAllocationForm/PointAllocationForm";
import styles from "./Onboarding.module.scss";

const CoreValuesScreen = "CoreValues";

const Onboarding = () => {
  var currentYear = moment().year();
  const [loader, setLoader] = useState(false);
  const [parentLoader, setParentLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [subscriptionPlan, setSubscriptionPlan] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    getSubscriptionPlan();
    setOnBoardingFlow(user?.team?.onBoardingStage, { step: "" });
  }, []);

  const handleNextStep = (prev, stepData) => {
    if (prev) {
      setActiveStep((preVal) => {
        const newVal = preVal - 1;
        setCurrentScreen(steps[newVal]?.screenName);
        return newVal;
      });
    } else {
      setActiveStep((preVal) => {
        setOnboardingStatus(null, null, steps[preVal].screenName, stepData);
        const newVal = preVal + 1;
        if (steps[newVal]?.screenName) {
          setCurrentScreen(steps[newVal].screenName);
          return newVal;
        }
        return preVal;
      });
    }
  };

  const getSubscriptionPlan = async () => {
    setLoader(true);
    try {
      const { data } = await apiServices.get(apiConstant.SUBSCRIPTION_PLAN);
      if (data) {
        setSubscriptionPlan(data.data);
        setLoader(false);
      }
      setLoader(false);
    } catch {
      setLoader(false);
      console.log("error");
    }
  };

  const setOnboardingStatus = async (params, params2, screenName, reqData) => {
    setParentLoading(true);
    try {
      const { data } = await apiServices.patch(
        apiConstant.SET_ONBOARDING_STATUS,
        {
          ...reqData,
          onBoardingStage: screenName,
          onBoardingStatus: currentScreen === CoreValuesScreen ? true : false,
        }
      );

      if (data) {
        setParentLoading(false);
        setOnBoardingFlow(currentScreen);
      }
    } catch {
      console.log("Error");
    } finally {
      setParentLoading(false);
    }
  };

  const setOnBoardingFlow = (screenName) => {
    if (!screenName) {
      setCurrentScreen(onBoardingStages.ChannelConsentScreen);
      setActiveStep(0);
    } else if (screenName === onBoardingStages.ChannelConsentScreen) {
      setCurrentScreen(onBoardingStages.MonthlyPointAllocationScreen);
      setActiveStep(1);
    } else if (screenName === onBoardingStages.MonthlyPointAllocationScreen) {
      setCurrentScreen(CoreValuesScreen);
      setActiveStep(2);
    } else if (screenName === CoreValuesScreen) {
      getProfileData();
      history.push({ pathname: "/settings" });
    }
  };

  const getProfileData = async () => {
    setParentLoading(true);
    try {
      let { data } = await apiServices.get(apiConstant.PROFILE);
      if (data) {
        setUser(data);
      }
      setParentLoading(false);
    } catch {
      setParentLoading(false);
    }
  };

  const steps = [
    {
      label: `Select ${
        user?.members?.platform === "teams" ? "Teams" : (user?.members?.platform === "discord" ? "Discord" : "Slack")
      } Channel`,
      screenName: onBoardingStages.ChannelConsentScreen,
      content: (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
          <div
            className={styles.cardDetailsContainer__Onboarding}
            style={{ display: "flex", alignItems: "center" }}
          >
            <CardDetails
              subscriptionPlans={subscriptionPlan}
              handleNextStep={handleNextStep}
            />
          </div>
        </Slide>
      ),
    },
    {
      label: "Point Allocation",
      screenName: onBoardingStages.MonthlyPointAllocationScreen,
      content: (
        <Slide
          direction="left"
          in={currentScreen === onBoardingStages.MonthlyPointAllocationScreen}
          mountOnEnter
          unmountOnExit
        >
          <div
            className={styles.cardDetailsContainer__Onboarding}
            style={{ display: "flex", alignItems: "center" }}
          >
            <PointAllocationForm
              subscriptionPlans={subscriptionPlan}
              handleNextStep={handleNextStep}
            />
          </div>
        </Slide>
      ),
    },
    {
      label: "Configure Core Values",
      screenName: onBoardingStages.CoreValuesScreen,
      content: (
        <Slide
          direction="left"
          in={currentScreen === CoreValuesScreen}
          mountOnEnter
          unmountOnExit
        >
          <div className={styles.coreValueContainer__Onboarding}>
            {currentScreen === CoreValuesScreen && (
              <CoreValue
                parentLoader={parentLoader}
                handleNextStep={handleNextStep}
              />
            )}
          </div>
        </Slide>
      ),
    },
  ];

  if (loader) {
    return (
      <div className="flash-loader">
        <img src={YellowBG} className="logoSquare spinLoader" alt="PeerFives" />
        <img
          src={PeerFiveBuddy}
          style={{ position: "absolute" }}
          alt="PeerFives"
        />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        styles.mainContainer,
        currentScreen === CoreValuesScreen ? styles.CoreValues : ""
      )}
    >
      <img alt="Peerfives" className={styles.peerFivesLogo} src={logo} />
      <div className={styles.cardContainer}>
        <CustomStepper
          activeStep={activeStep}
          stepTitle={steps[activeStep].label}
          totalStepCount={steps.length}
        />
        {steps[activeStep]?.content}
      </div>
      <Typography className={styles.description}>
        © {currentYear} PeerFives, LLC.
      </Typography>
    </div>
  );
};

export default withRouter(Onboarding);
