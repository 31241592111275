import moment from "moment";

export const getDateRange = filter => {
  let fromDate = new Date();
  let toDate;

  if (filter === "Today") {
    toDate = new Date(moment().startOf("day"));
  }
  if (filter === "This Month") {
    toDate = new Date(
      moment(new Date())
        .startOf("month")
        .format()
    );
  }
  if (filter === "This Week") {
    toDate = new Date(new Date().setDate(new Date().getDate() - 7));
  }
  if (filter === "Last Month") {
    fromDate = new Date(
      moment(new Date())
        .subtract(1, "months")
        .endOf("month")
        .format()
    );
    toDate = new Date(
      moment(new Date())
        .subtract(1, "months")
        .startOf("month")
        .format()
    );
  }
  return { fromDate, toDate };
};

export const generateKey = () => {
  return moment().valueOf();
}

export const generateFakeArray = (length = 10) => {
  return Array.from(Array(length).keys())
}

export const currencyFormatter = (data, currency = "INR", minimumFractionDigits = 0) => {
  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minimumFractionDigits
  });
  return formatter.format(data)
}