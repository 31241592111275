import { createApolloFetch } from "apollo-fetch";
const axios = require("axios");
require("../../configAxios");

const uri = process.env.REACT_APP_API_URI;

const fetch = createApolloFetch({
  uri,
});

fetch.use(({ request, options }, next) => {
  if (!options.headers) {
    options.headers = {};
  }

  options.headers["Authorization"] = localStorage.getItem("authorization");
  next();
});

const login = (code) => {
  return axios.post("/auth/login", {
    code,
  });
};
const getLeaderBoard = () => {
  return axios.get("/members/leaderboard", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};

const getAllMembers = () => {
  return axios.get("/members/all", {
    headers: {
      token: localStorage.getItem("authorization"),
    },
  });
};

const getEarnedPointAcitivity = (memberId) => {
  return axios.get("/members/useractivity", {
    params: { memberId: memberId },
    headers: {
      token: localStorage.getItem("authorization"),
    },
  });
};
const getAllAcitivity = (params) => {
  return axios.get("/members/activity", {
    params: params,
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
const searchMember = (searchMemberQuery) => {
  return axios.get("/members/search", {
    params: { name: searchMemberQuery },
    headers: {
      token: localStorage.getItem("authorization"),
    },
  });
};
const setSpendingLimit = (id, spendingLimit) => {
  return axios.patch(
    `/members/setlimit/${id}`,
    {
      spendingLimit: spendingLimit,
    },
    {
      headers: {
        token: localStorage.getItem("authorization"),
      },
    }
  );
};

const getUser = (id) => {
  return axios.get("/members", {
    params: { id: id },
    headers: {
      token: localStorage.getItem("authorization"),
    },
  });
  // return fetch({
  //   query: `{ Member(userId: "${id}"){ _id name profile { display_name real_name image_512 } } }`,
  // });
};
const getCoreValues = () => {
  return axios.get("/teams/coreValues", {
    headers: {
      token: localStorage.getItem("token"),
    },
  });
};
const setCoreValues = (coreValueData) => {
  return axios.patch(
    `/teams/coreValues`,
    {
      coreValues: coreValueData,
    },
    {
      headers: {
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IlUwMjMzNDlVR1NXIiwidGVhbV9pZCI6IlRCRTU0TFc5MCIsImlzQWRtaW4iOnRydWUsImlhdCI6MTYyNjY3Njg0NywiZXhwIjoxNjMxODYwODQ3fQ.Xm7IZBocPn1HzRbwYTPhxRWfCflN8gQ6d7i7gGd6PFo",
      },
    }
  );
};
/*
 const getLeaderboard = (fromDate, toDate) => {
   return fetch({
     query: `{GetLeaderboard(fromDate:"${fromDate}",toDate:"${toDate}"){ _id balance member{ name id profile{team real_name display_name image_512}} } }`
   });
 };

const getEarnedPointAcitivity = (userId, fromDate, toDate) => {
  return fetch({
    query: `{ EarnedPointsActivity(userId:"${userId}",fromDate:"${fromDate}",toDate:"${toDate}"){ _id value forWhat source{ name profile{ real_name display_name image_512 } } } }`,
  });
};*/
export default {
  getLeaderBoard,
  getEarnedPointAcitivity,
  getAllAcitivity,
  login,
  setSpendingLimit,
  searchMember,
  getUser,
  getAllMembers,
  getCoreValues,
  setCoreValues,
};

// U5WE8AP7T T260V2K3P
