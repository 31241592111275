import React from "react";
import InputBase from "@material-ui/core/InputBase";
import PropTypes from 'prop-types';
import styles from './SearchBox.module.scss';
import iconSearch from "../../assets/icon_search.svg";

function SearchBox(props) {
  const { onChange, placeholder } = props;
  return (
    <div className={styles.searchBox}>
      <img src={iconSearch} alt="search" className={styles.searchIcon} />
      <InputBase
        placeholder={placeholder}
        onChange={onChange}
        inputProps={{ "aria-label": placeholder }}
        className={styles.inputWidth}
      />
    </div>
  );
}

SearchBox.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

SearchBox.defaultProps = {
  placeholder: 'Search',
  onChange: {},
};


export default SearchBox
