import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./CancelSubscriptionDialog.module.scss";
import iconClose from "../../../../assets/icon_close.svg";
import * as apiServices from "../../../../utils/services/apiServices";
import * as apiConstant from "../../../../utils/constant/apiConstant";
import { useToaster } from "../../../../helper/ToasterContext";
import { Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import moment from "moment";

const CancelSubscriptionDialog = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const Toaster = useToaster();
  const closeDialog = (params) => {
    props.onClose(params);
  };

  const cancelSubscription = async (params) => {
    setIsLoading(true);
    try {
      const { data } = await apiServices.post(apiConstant.CANCEL_SUBSCRIPTION);

      if (data.status) {
        Toaster.openSnackbar(
          data?.message ? data.message : "Subscription Cancelled"
        );
      } else {
        Toaster.openSnackbar(
          data?.message ? data.message : "Something went wrong!",
          "error"
        );
      }
      setIsLoading(false);
      closeDialog(true);
    } catch {
      setIsLoading(false);
    }
  };
  const handleAgreeClose = () => {
    cancelSubscription();
  };
  return (
    <div>
      <div className={styles.headerContainer}>
        <div>
          <Typography className={styles.headerTitle}>
            Are you sure want to cancel the subscription?
          </Typography>
        </div>
        <div onClick={closeDialog} onKeyDown={closeDialog} role="presentation">
          <img className={styles.closeIcon} src={iconClose} alt="close" />
        </div>
      </div>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          On confirming the cancellation of your subsription, your slack
          functions will be disabled from{" "}
          {moment
            .unix(
              props.currentSubscriptionData?.subscriptionList[0]
                ?.current_period_end
            )
            .utc()
            .format("Do")}{" "}
          of next month
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className={styles.footer}>
          <Button
            color="primary"
            className={styles.skipButton}
            onClick={closeDialog}
          >
            No, I changed my mind
          </Button>
          <Button
            variant="contained"
            className={styles.saveButton}
            onClick={handleAgreeClose}
          >
            {isLoading ? (
              <div className="loader" />
            ) : (
              "Yes, Cancel my Subscription"
            )}
          </Button>
        </div>
      </DialogActions>
    </div>
  );
};

CancelSubscriptionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

CancelSubscriptionDialog.defaultProps = {};

export default CancelSubscriptionDialog;
