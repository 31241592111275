import React, { useEffect, useState } from "react";
import Routes from "./routes/authRoutes";
import { StylesProvider } from "@material-ui/core/styles";
import { BrowserRouter, Router } from "react-router-dom";
import { SnackbarProvider } from "helper/ToasterContext";
import history from "utils/@history";
import UserContext from "./utils/context/UserContext";
import * as storageServices from "./utils/services/storageServices";
import * as apiServices from "./utils/services/apiServices";
import * as apiConstant from "./utils/constant/apiConstant";
import PeerFiveBuddy from "./assets/icons/PeerFiveBuddy.svg";
import YellowBG from "./assets/icons/YellowBG.svg";
import MobileNavSetting from "pages/Profile/components/MobileNavSetting/MobileNavSetting";

const App = () => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const value = { user, setUser };

  useEffect(() => {
    if (storageServices.get(storageServices.TOKEN)) {
      getProfileData();
    } else {
      setIsLoading(false);
    }
  }, []);

  const getProfileData = async () => {
    try {
      setIsLoading(true);
      let { data } = await apiServices.get(apiConstant.PROFILE);
      if (data) {
        setUser(data);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <BrowserRouter>
      {!isLoading ? (
        <SnackbarProvider>
          <StylesProvider injectFirst>
            <Router history={history}>
              <UserContext.Provider value={value}>
                <div className="App">
                  <Routes />
                </div>
              </UserContext.Provider>
            </Router>
          </StylesProvider>
        </SnackbarProvider>
      ) : (
        <div className="flash-loader">
          <img
            src={YellowBG}
            className="logoSquare spinLoader"
            alt="PeerFives"
          />
          <img
            src={PeerFiveBuddy}
            style={{ position: "absolute" }}
            alt="PeerFives"
          />
        </div>
      )}
    </BrowserRouter>
  );
};

export default App;
