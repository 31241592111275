const getPendingItemsObject = (user) => {
  const teamBudget = !!(user.team.teamBudget && user.team.teamBudget !== 0);
  const paymentDetails = !!(
    user.team.paymentInfo &&
    user.team.paymentInfo !== null &&
    user.team.paymentInfo.paymentType === "card" &&
    user.team.paymentInfo.cardType !== null
  );
  const pointsConversionRate = !!(
    user.team.pointsConversionRate && user.team.pointsConversionRate !== 0
  );

  if (teamBudget && paymentDetails && pointsConversionRate) {
    const pendingItemsObject = {
      isPendingItems: false,
    };
    return pendingItemsObject;
  }

  const pendingItemsObject = {
    isPendingItems: true,
    teamBudget: !teamBudget,
    paymentDetails: !paymentDetails,
    pointsConversionRate: !pointsConversionRate,
  };
  return pendingItemsObject;
};
export default getPendingItemsObject;
