import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRaduisLeft: "4px solid",
    position: "relative",
    border: "1px solid #ececec",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderColor: "#ececec",
    },
  },
}))(InputBase);

export default function CustomizedSelects(props) {
  return (
    <div style={{ display: "flex", margin: "6px 0" }}>
      <FormControl style={{ minWidth: " min-content" }}>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={props.currency}
          onChange={props.handleChangeCurrency}
          input={<BootstrapInput />}
          disabled={props.disabledCurrency}
        >
          <MenuItem value={"INR"}>INR</MenuItem>
          <MenuItem value={"USD"}>USD</MenuItem>
          <MenuItem value={"EUR"}>EUR</MenuItem>
        </Select>
      </FormControl>
      <FormControl style={{ width: "-webkit-fill-available" }}>
        <BootstrapInput
          type="number"
          id="demo-customized-textbox"
          onChange={props.onChange}
          value={props.value}
          inputProps={{ min: 1 }}
          disabled={props.disabledInput}
        />
      </FormControl>
    </div>
  );
}
